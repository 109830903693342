<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title>Edit flight</v-toolbar-title>
    </v-toolbar>

    <v-form>
      <v-card-text>
        <AirportSearch
          label="Departure airport"
          :airport="model.departure"
          :error-message="validationErrors.departure_airport_id"
          @clear-error="clearValidation('departure_airport_id')"
          @on-submit="setDeparture"
        />
        <AirportSearch
          label="Arrival airport"
          :airport="model.arrival"
          :error-message="validationErrors.arrival_airport_id"
          @clear-error="clearValidation('arrival_airport_id')"
          @on-submit="setArrival"
        />
        <v-row justify="space-around">
          <v-col>
            <v-checkbox
              v-model="model.is_regular"
              :disabled="loading"
              label="Is regular"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="model.is_ferry"
              :disabled="loading"
              label="Is ferry"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="model.status"
              :disabled="loading"
              label="Active"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <div class="loader-container">
        <v-progress-linear
          v-if="loading"
          class="loader"
          :indeterminate="true"
        />
      </div>

      <v-card-actions>
        <v-btn
          type="button"
          color="primary"
          :disabled="loading"
          @click="applyChanges"
        >
          Save
        </v-btn>
        <v-btn
          type="button"
          color="primary"
          text
          :disabled="loading"
          @click="$emit('on-close')"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import { fieldValidation } from '../../mixins/fieldValidation';
  import AirportSearch from '../../components/AirportSearch';

  export default {
    components: { AirportSearch },

    mixins: [fieldValidation],

    props: {
      flight: {
        type: Object,
        required: true
      },
    },

    data () {
      return {
        loading: false,
        model: {}
      };
    },

    watch: {
      flight: {
        handler: 'setModel',
        immediate: true
      }
    },

    methods: {
      async applyChanges () {
        this.loading = true;
        try {
          const res = await HTTPService.updateFlight(this.flight.id, this.model);
          const data = {
            ...res.data,
            departure: this.model.departure,
            arrival: this.model.arrival
          };
          this.$emit('on-submit', data);
        } catch ({ response }) {
          this.setValidation(ErrorService.handleError(response));
        } finally {
          this.loading = false;
        }
      },

      setModel () {
        if (this.flight) {
          this.model = {
            is_regular: this.flight.is_regular,
            is_ferry: this.flight.is_ferry,
            status: this.flight.status,
            departure: this.flight.departure,
            arrival: this.flight.arrival,
            departure_airport_id: this.flight.departure.id,
            arrival_airport_id: this.flight.arrival.id,
          };
        }
      },

      setDeparture (airport) {
        this.model.departure = airport;
        this.model.departure_airport_id = airport ? airport.id : null;
      },

      setArrival (airport) {
        this.model.arrival = airport;
        this.model.arrival_airport_id = airport ? airport.id : null;
      },
    }
  };
</script>
