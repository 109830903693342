<template>
  <LineChartComponent
    v-if="chartData"
    :data="chartData"
    :options="chartOptions"
    style="position: relative; height: 180px; width: 100%;"
  />
</template>

<script>
  import { DateTime } from 'luxon';
  import { Line as LineChartComponent } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler
  } from 'chart.js';

  ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Filler);

  export default {
    name: 'LineChart',
    components: { LineChartComponent },

    props: {
      chartData: {
        type: Object,
        default: null
      },
      customLegend: {
        type: Object,
        default: null
      },
      compareYears: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      chartOptions () {
        const options = {
          maintainAspectRatio: true,
          responsive: true,
          plugins: {
            legend: this.customLegend ? this.customLegend : { display: false },
            tooltip: {
              mode: 'index',
              intersect: false,
              position: 'nearest',
              callbacks: {
                title: () => { return null; },
                labelColor: (ctx) => {
                  return {
                    borderColor: ctx.element.options.borderColor,
                    backgroundColor: ctx.element.options.backgroundColor
                  };
                },
                label: (ctx) => {
                  const format = 'MMM d, yyyy';
                  if (this.compareYears && ctx.datasetIndex > 0) {
                    let newLabel = ctx.label;
                    if (ctx.chart.config._config.data.datasets[0].data.length > 0) {
                      newLabel = DateTime.fromFormat(ctx.label, format)
                        .minus({ years: 1 })
                        .toFormat(format);
                    }
                    return `${newLabel}: ${ctx.formattedValue}`;
                  } else {
                    return `${ctx.label}: ${ctx.formattedValue}`;
                  }
                }
              }
            },
          },
          elements: {
            point: {
              radius: 1,
              hoverRadius: 4
            }
          },
          hover: {
            mode: 'index',
            intersect: false
          },
          scales: {
            x: {
              ticks: {
                display: false
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              }
            },
            y: {
              ticks: {
                display: true
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              }
            }
          }
        };

        if (this.customLegend) {
          options.plugins.legend = this.customLegend;
        }

        return options;
      }
    }
  };
</script>
