<template>
  <v-container
    fluid
    px-0
    py-0
  >
    <v-row class="px-4">
      <v-col>
        <v-row no-gutters>
          <div class="pr-3 align-self-end">
            <v-icon
              medium
              :color="iconColor"
              @click="toggleFilters()"
            >
              mdi-filter
            </v-icon>
            <v-icon
              left
              dark
              color="success"
              @click="excel"
            >
              mdi-file-excel
            </v-icon>
          </div>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          />
        </v-row>
        <v-row>
          <LegsFilters
            v-if="isFiltersOpened"
            :on-filter="toggleFilters"
            :apply-filters="filterFlights"
            :prev-filters="prevFilters"
            :reset-filters="resetListFilters"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="flights"
        :custom-sort="sortLegs"
        :sort-by="['blockoff']"
        :sort-desc="true"
        no-data-text="No flights yet"
        :items-per-page="50"
        :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
      >
        <template #[`item.registration`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <a
                :href="`${portalFlightLink}${item.flight_id}`"
                target="_blank"
                class="portal-link"
                v-on="on"
              >
                {{ item.registration }}
              </a>
            </template>
            <span>Edit flight</span>
          </v-tooltip>
        </template>

        <template #[`item.departure_airport_icao`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <span v-on="on">
                {{ item.departure_airport_icao.toUpperCase() }}
              </span>
            </template>
            <span>{{ item.departure_airport_name }}</span>
          </v-tooltip>
        </template>

        <template #[`item.arrival_airport_icao`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <span v-on="on">
                {{ item.arrival_airport_icao.toUpperCase() }}
              </span>
            </template>
            <span>{{ item.arrival_airport_name }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
  import LegsFilters from './LegsFilters.vue';
  import { DateTime, Duration } from 'luxon';

  export default {
    components: {
      LegsFilters
    },

    props: {
      flights: {
        type: Array
      },
      prevFilters: {
        type: Object
      },
      excel: {
        type: Function
      },
      filterFlights: {
        type: Function
      },
      resetFilters: {
        type: Function
      }
    },

    data: () => ({
      search: '',
      isFiltersOpened: false
    }),

    computed: {
      headers () {
        return [
          { text: 'Registration', value: 'registration', sortable: true },
          { text: 'Date', value: 'date', sortable: true },
          { text: 'Flight No.', value: 'prefix', sortable: true },
          { text: 'ADEP', value: 'departure_airport_icao', sortable: true },
          { text: 'ADES', value: 'arrival_airport_icao', sortable: true },
          { text: 'OFF', value: 'blockoff', sortable: true },
          { text: 'T/O', value: 'takeoff', sortable: true },
          { text: 'LDG', value: 'takeon', sortable: true },
          { text: 'ON', value: 'blockon', sortable: true },
          { text: 'FLT', value: 'flight_hours', sortable: true },
          { text: 'BLK', value: 'block_hours', sortable: true },
          { text: 'I.FUEL', value: 'fuel_initial', sortable: true },
          { text: 'R.FUEL', value: 'fuel_remaining', sortable: true },
          { text: 'USED', value: 'fuel_used', sortable: true },
          { text: 'UPLIFT', value: 'fuel_uplift', sortable: true },
          { text: 'DISTANCE', value: 'distance', sortable: true },
          { text: 'CARGO', value: 'cargo_weight', sortable: true }
        ];
      },

      portalFlightLink () {
        return `${process.env.VUE_APP_PORTAL_URL}/main/flight/`;
      },

      iconColor () {
        return this.isFiltersOpened ? 'primary' : 'secondary';
      }
    },

    methods: {
      toggleFilters () {
        this.isFiltersOpened = !this.isFiltersOpened;
      },

      resetListFilters () {
        this.search = '';
        return this.resetFilters();
      },

      sortLegs (legs, columnName, sortOrder) {
        const items = [ ...legs ];
        const sortBy = columnName[0] ?? 'blockoff';
        const sortDesc = sortOrder[0] ?? true;
        const dateFormat = 'yyyy-MM-dd HH:mm';
        const compare = (a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        };

        items.sort((a, b) => {
          let valueA, valueB;
          switch (sortBy) {
          case 'registration':
          case 'prefix':
          case 'departure_airport_icao':
          case 'arrival_airport_icao':
            valueA = a[sortBy].toUpperCase();
            valueB = b[sortBy].toUpperCase();
            break;
          case 'date':
            valueA = DateTime.fromISO(a[sortBy]);
            valueB = DateTime.fromISO(b[sortBy]);
            break;
          case 'blockoff':
          case 'takeoff':
          case 'takeon':
          case 'blockon':
            valueA = DateTime.fromFormat(a[sortBy], dateFormat);
            valueB = DateTime.fromFormat(b[sortBy], dateFormat);
            if (!valueA.isValid || !valueB.isValid) {
              const scheduleKey = sortBy === 'blockoff' || sortBy === 'takeoff' ? 'departure' : 'arrival';
              valueA = valueA.isValid ? valueA : DateTime.fromISO(a[scheduleKey]);
              valueB = valueB.isValid ? valueB : DateTime.fromISO(b[scheduleKey]);
            }
            break;
          case 'flight_hours':
          case 'block_hours':
            valueA = a[sortBy].length === 5 ? Duration.fromISOTime(a[sortBy]) : a[sortBy];
            valueB = b[sortBy].length === 5 ? Duration.fromISOTime(b[sortBy]) : a[sortBy];
            break;
          default:
            valueA = +a[sortBy];
            valueB = +b[sortBy];
            break;
          }

          if (sortDesc) {
            return compare(valueB, valueA);
          }
          return compare(valueA, valueB);
        });

        return items;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .portal-link {
    text-decoration: none;

    &:hover {
      color: #ee7001;
    }
  }
</style>
