<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider="-"
        />

        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            {{ airline.name }} persons
          </h1>

          <v-btn
            v-if="!loading"
            type="button"
            color="primary"
            @click="openAddModal"
          >
            Add persons
          </v-btn>
        </v-row>

        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              class
              single-line
              hide-details
            />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="persons"
            :search="search"
            no-data-text="No persons here"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
            sort-by="name"
            :sort-desc="true"
          >
            <template #header.is_cargo="{}">
              <v-icon>
                mdi-truck-trailer
              </v-icon>
            </template>

            <template #header.is_pax="{}">
              <v-icon>
                mdi-seat
              </v-icon>
            </template>

            <template #item.name="{ item }">
              <span>{{ item.name }}</span>
            </template>

            <template #item.is_cargo="{ item }">
              <v-icon v-if="item.is_cargo">
                mdi-check
              </v-icon>
            </template>

            <template #item.is_pax="{ item }">
              <v-icon v-if="item.is_pax">
                mdi-check
              </v-icon>
            </template>
            
            <template #item.emails="{ item }">
              <v-tooltip top>
                <template #activator="{ on }">
                  <div
                    class="text-truncate"
                    v-on="on"
                  >
                    {{ spliceEmails(item.emails) }}
                  </div>
                </template>
                <label>{{ spliceEmails(item.emails) }}</label>
              </v-tooltip>
            </template>

            <template #item.phones="{ item }">
              <v-tooltip top>
                <template #activator="{ on }">
                  <div
                    class="text-truncate"
                    v-on="on"
                  >
                    {{ splicePhones(item.phones) }}
                  </div>
                </template>
                <label>{{ splicePhones(item.phones) }}</label>
              </v-tooltip>
            </template>

            <template #item.editId="{ item }">
              <v-icon
                color="primary"
                @click="openEditModal(item)"
              >
                mdi-pencil
              </v-icon>
            </template>

            <template #item.deleteId="{ item }">
              <v-icon
                color="error"
                @click="openDeleteModal(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <v-dialog
          v-model="isModalOpened"
          max-width="600px"
        >
          <PersonsEdit
            :key="addDialogKey"
            :on-close="closeAddModal"
            :on-submit="addPerson"
            :airline-id="airlineId"
          />
        </v-dialog>

        <v-dialog
          v-model="isEditModalOpened"
          max-width="600px"
        >
          <PersonsEdit
            :on-close="closeEditModal"
            :on-submit="editPerson"
            :person="personToEdit"
            :airline-id="airlineId"
          />
        </v-dialog>

        <DeleteDialog
          v-model="isDeleteModalOpened"
          title="person"
          :name="personToDelete.name"
          :loading="deleting"
          :on-submit="submitDeletePerson"
        />
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      absolute
    />
  </v-container>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import DeleteDialog from '../../components/DeleteDialog';
  import PersonsEdit from './PersonsEdit';
  export default {
    components: { DeleteDialog, PersonsEdit },
    
    data: () => ({
      search: '',
      loading: true,
      deleting: false,
      airline: {},
      persons: [],
      isEditModalOpened: false,
      isModalOpened: false,
      isDeleteModalOpened: false,
      personToEdit: {},
      personToDelete: {},
      addDialogKey: 0,
      airlineId: null
    }),

    computed: {
      breadcrumbs: function () {
        return [
          {
            text: 'Home',
            append: true,
            exact: true,
            to: '/'
          },
          {
            text: 'Airlines',
            append: true,
            exact: true,
            to: '/airlines'
          },
          {
            text: `${this.airline.name}`,
            append: true,
            exact: true,
            to: '/airlines/' + this.airline.id
          },
          {
            text: 'Persons',
            disabled: true
          }
        ];
      },
      headers: function () {
        return [
          { text: 'Name', value: 'name', sortable: true },
          { text: '', value: 'is_cargo', sortable: true },
          { text: '', value: 'is_pax', sortable: true },
          { text: 'Emails', value: 'emails', sortable: true },
          { text: 'Phones', value: 'phones', sortable: true },
          { text: '', value: 'editId', sortable: false },
          { text: '', value: 'deleteId', sortable: false }
        ];
      },
    },
    created () {
      this.airlineId = this.$route.params.id;
      let airline =  HTTPService.getAirline(this.airlineId);
      let persons = HTTPService.getPersons({ airline_id: this.airlineId });
      Promise.all([airline, persons])
        .then(res => {
          this.airline = res[0].data;
          this.persons = res[1].data;
          this.loading = false;
        })
        .catch(({ response }) => {
          ErrorService.handleError(response);
        });
    },
    methods: {
      openDeleteModal (person) {
        this.personToDelete = person;
        this.isDeleteModalOpened = true;
      },

      submitDeletePerson () {
        this.deleting = true;
        HTTPService.deletePerson(this.personToDelete.id)
          .then(res => {
            this.persons = this.persons.filter(
              item => item.id !== this.personToDelete.id
            );
            this.isDeleteModalOpened = false;
          })
          .catch(({ response }) => {
            ErrorService.handleError(response);
          })
          .finally(() => {
            this.deleting = false;
          });
      },

      openAddModal () {
        this.addDialogKey++;
        this.isModalOpened = true;
      },

      closeAddModal () {
        this.isModalOpened = false;
      },

      openEditModal (person) {
        HTTPService.getPerson(person.id)
          .then(res => {
            this.personToEdit = { ...res.data };
          })
          .catch(({ response }) => {
            ErrorService.handleError(response);
          });
        this.isEditModalOpened = true;
      },

      closeEditModal () {
        this.isEditModalOpened = false;
      },

      addPerson (person) {
        this.isModalOpened = false;
        this.persons = [
          ...this.persons,
          {
            id: person.id,
            airline_id: person.airline_id,
            name: person.name,
            is_cargo: person.is_cargo,
            is_pax: person.is_pax,
            emails: person.emails,
            phones: person.phones,
          }
        ];
      },

      editPerson (person) {
        this.isEditModalOpened = false;
        let persons = [...this.persons];
        let result = persons.map(item => {
          if (item.id === person.id) {
            item = {
              ...item,
              id: person.id,
              airline_id: person.airline_id,
              name: person.name,
              is_cargo: person.is_cargo,
              is_pax: person.is_pax,
              emails: person.emails,
              phones: person.phones,
            };
            return item;
          }
          return item;
        });
        this.persons = [...result];
      },

      spliceEmails (arr) {
        return arr ? arr.map(e => e.email).join(', ') : '-';
      },

      splicePhones (arr) {
        return arr ? arr.map(e => e.phone).join(', '): '-';
      }
    }
  };
</script>

<style>
    .text-truncate {
        max-width: 20vw;
        text-overflow: ellipsis;
    }
</style>
