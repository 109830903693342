<template>
  <v-container fluid>
    <v-row
      v-if="!loading"
      class="mb-4"
      no-gutters
    >
      <v-col>
        <v-card class="ma-0 pa-0">
          <header class="px-4 pt-4">
            <h1 class="card-heading">
              Flights
            </h1>
          </header>
          <v-row no-gutters>
            <LegsList
              :flights="flights"
              :prev-filters="prevFilters"
              :excel="getExcel"
              :filter-flights="filterFlights"
              :reset-filters="resetFilters"
            />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <LegsSummary :stats="stats" />
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      absolute
    />
  </v-container>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import StatsService from '../../services/StatsService';
  import LegsList from './LegsList';
  import LegsSummary from './LegsSummary';

  export default {
    components: {
      LegsList,
      LegsSummary
    },

    props: {
      date: {
        type: Object,
        require: true
      }
    },

    data: () => ({
      loading: true,
      flights: [],
      stats: null,
      prevFilters: {
        type: null
      }
    }),

    watch: {
      date: {
        handler: async function (newDate, prevDate) {
          if (newDate.start && newDate.end) {
            const params = {
              fromDate: this.date.start,
              toDate: this.date.end
            };

            this.filterFlights(params);
          }
        }
      }
    },

    created () {
      this.prevFilters = {
        ...this.prevFilters,
        ...this.parseQueryParams(this.$route.query)
      };
      this.filterFlights(this.prevFilters);
    },

    methods: {
      async getExcel () {
        try {
          const requestParams = {
            DepartureUtcFrom: this.prevFilters.fromDate + 'T00:00',
            DepartureUtcTo: this.prevFilters.toDate + 'T23:59',
            typeId: this.prevFilters.type,
            OperatorIdList: this.prevFilters.airline_ids ? this.prevFilters.airline_ids.join(',') : null,
            AircraftTypeList: this.prevFilters.aircraft_type_ids ? this.prevFilters.aircraft_type_ids.join(',') : null,
            RegistrationList: this.prevFilters.registrations ? this.prevFilters.registrations.join(',') : null,
          };

          const res = await HTTPService.getStatsExcel(requestParams);
          const contentDisposition = res.headers['content-disposition'];
          const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          const blob = new Blob([res.data], { type: res.data.type });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          URL.revokeObjectURL(url);
        } catch (e) {
          ErrorService.handleException(e);
        }
      },

      async getFlights (params) {
        this.loading = true;

        try {
          const { data } = await HTTPService.getFlightsStats(params);
          const { flights, totals } = StatsService.parseFlightsStats(data);
          this.flights = flights;
          this.stats = totals;
          this.loading = false;
        } catch (e) {
          ErrorService.handleException(e);
        }
      },

      filterFlights (params = {}) {
        const query = {};
        const requestParams = { ...params };
        if (this.date.start && this.date.end) {
          requestParams.fromDate = this.date.start;
          requestParams.toDate = this.date.end;
        }
        this.prevFilters = requestParams;
        this.getFlights(requestParams);

        Object.keys(requestParams).forEach(key => {
          if (requestParams[key] !== null) {
            query[key] = requestParams[key];
          }
        });

        this.$router.replace({ name: 'stats', query }, () => {});
      },

      resetFilters () {
        this.prevFilters = {
          type: null,
          fromDate: null,
          toDate: null,
          airline_ids: [],
          aircraft_type_ids: [],
          registrations: []
        };

        this.$emit('resetDate');
        return this.prevFilters;
      },

      parseQueryParams (query) {
        const params = {};

        Object.keys(query).forEach(key => {
          const value = query[key];
          switch (key) {
            case 'airline_ids':
            case 'aircraft_type_ids':
            case 'registrations':
              params[key] = [];
              const arr = Array.isArray(value) ? value : [value];
              for (const item of arr) {
                params[key].push(key === 'registrations' ? item : parseInt(item));
              }
              break;
            case 'type':
              params[key] = parseInt(value);
              break;
            case 'start':
              params.fromDate = value;
              delete query[key];
              break;
            case 'end':
              params.toDate = value;
              delete query[key];
              break;
            default:
              params[key] = value;
          }
        });

        return params;
      }
    }
  };
</script>

<style scoped>
  .card-heading {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    text-align: left;
  }
</style>
