import { DateTime } from 'luxon';

class QuotesService {
  sortQuotes (arr, params) {
    const { sortBy } = params;

    if (sortBy === 'price') {
      return this.sortByPrice(arr);
    }
    if (sortBy === 'arrival') {
      return this.sortByCargoArrival(arr);
    }

    return arr;
  }

  sortByPrice (arr) {
    return [ ...arr ]
      .filter(item => item.availability && item.availability.price)
      .sort((a, b) => {
        const aPrice = a.availability.price;
        const bPrice = b.availability.price;

        return aPrice - bPrice;
      });
  }

  sortByCargoArrival (arr) {
    const getArrivalTime = (route) => {
      const cargo = route.toReversed().find(item => !item.is_ferry);
      return DateTime.fromFormat(cargo.arrival_time, 'yyyy-MM-dd HH:mm').toMillis();
    };

    return [ ...arr ]
      .filter(item => item.availability && item.availability.flightTime)
      .sort((a, b) => {
        const aCargoArrival = getArrivalTime(a.availability.route);
        const bCargoArrival = getArrivalTime(b.availability.route);
        return aCargoArrival - bCargoArrival;
      });
  }
}

export default new QuotesService();
