<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <AircraftsList
          v-if="!loading"
          :aircrafts="filteredAircrafts"
          :refreshing-id="refreshId"
          :prev-filters="prevFilters"
          :reset-filters="resetFilters"
          :loading="requestLoading"
          :validation-errors="validationErrors"
          show-payload-filters
          show-airport-filters
          @on-refresh="refreshAircraft"
          @on-filter="applyFilters"
          @reset-filters="resetFilters"
          @sort-quotes="sortQuotes"
          @add-leg="addLeg"
          @edit-leg="editLeg"
          @save-leg="saveLeg"
          @delete-leg="deleteLeg"
          @dismiss-leg="dismissLeg"
          @clear-validation="clearValidation"
        />
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      absolute
    />
  </v-container>
</template>

<script>
  import { fieldValidation } from '../../mixins/fieldValidation';
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import AircraftsService from '../../services/AircraftsService';
  import QuotesService from '../../services/QuotesService';
  import AircraftsList from './AircraftsList';

  export default {
    components: { AircraftsList },

    mixins: [fieldValidation],

    data: () => ({
      loading: true,
      requestLoading: false,
      aircrafts: [],
      filteredAircrafts: [],
      quotes: null,
      refreshId: '',
      prevFilters: {
        is_regular: null,
      },
      quoteParams: {}
    }),

    async created () {
      await this.applyFilters(this.parseQueryParams(this.$route.query));
      this.loading = false;
    },

    methods: {
      async refreshAircraft (registration) {
        this.refreshId = registration;

        try {
          const { data } = await HTTPService.refreshAircraft(registration);
          if (data) {
            this.aircrafts = AircraftsService.update(this.aircrafts, data);
            this.filteredAircrafts = AircraftsService.getAircraftsList(this.aircrafts);
          }
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.refreshId = '';
        }
      },

      async getAircrafts (params) {
        try {
          const { data } = await HTTPService.getAircrafts(params);
          return data;
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
      },

      async getQuotePrices (params) {
        try {
          const { data } = await HTTPService.getQuotePrices(params);
          return data;
        } catch ({ response }) {
          this.setValidation(ErrorService.handleError(response));
        }
      },

      sortQuotes (sortBy) {
        if (!sortBy || sortBy === 'default') {
          this.filteredAircrafts = AircraftsService.getAircraftsList(this.aircrafts, this.quotes);
          return;
        }

        this.filteredAircrafts = QuotesService.sortQuotes(this.filteredAircrafts, { sortBy });
      },

      findAircraft (registration) {
        const aircraft = this.filteredAircrafts.find(aircraft => aircraft.registration === registration);

        if (!aircraft || !aircraft.availability.route) {
          ErrorService.handleError();
          return;
        }

        return aircraft;
      },

      prepareLegParams (route) {
        let {
          departure_airport_id: quote_departure_airport_id,
          arrival_airport_id: quote_arrival_airport_id
        } = this.quoteParams;
        const airports = [];

        route.forEach((leg, index) => {
          if (!leg.is_ferry) {
            quote_arrival_airport_id = leg.arrival.id;
          }
          airports.push({
            id: leg.departure.id,
            is_ferry: leg.is_ferry,
          });
          if (index === route.length - 1) {
            airports.push({
              id: leg.arrival.id,
              is_ferry: false,
            });
          }
        });

        return {
          quote_departure_airport_id,
          quote_arrival_airport_id,
          airports,
        };
      },

      addLeg (registration) {
        const aircraft = this.findAircraft(registration);
        const route = [ ...aircraft.availability.route ];
        const prevLeg = route[route.length - 1];
        const leg = {
          departure: prevLeg.arrival ?? {},
          arrival: {},
          from: prevLeg.to ?? null,
          is_ferry: true,
          to: null,
          isEdit: true
        };

        prevLeg.isEdit = false;
        route.push(leg);
        aircraft.availability.route = route;
      },

      async editLeg (registration, index) {
        const aircraft = this.findAircraft(registration);
        const route = [ ...aircraft.availability.route ];
        const prevEditedIndex = route.findIndex(leg => leg.isEdit);

        if (prevEditedIndex > -1 && !route[prevEditedIndex].to) {
          route.splice(prevEditedIndex, 1);
        }
        route.forEach((leg, i) => leg.isEdit = i === index);
        aircraft.availability.route = route;
      },

      async saveLeg (registration, index, leg) {
        const aircraft = this.findAircraft(registration);
        const route = [ ...aircraft.availability.route ];

        route[index] = leg;
        route[index].isEdit = false;
        if (route[index+1]) {
          route[index+1].departure = leg.arrival;
        }
        await this.updateQuoteRoute(registration, route);
      },

      async deleteLeg (registration, index) {
        const aircraft = this.findAircraft(registration);
        const route = [ ...aircraft.availability.route ];
        const deletedLeg = route.splice(index, 1);

        if (deletedLeg[0].arrival_time) {
          await this.updateQuoteRoute(registration, route);
        } else {
          aircraft.availability.route = route;
        }
      },

      async dismissLeg (registration, index = -1) {
        const aircraft = this.findAircraft(registration);
        const route = [ ...aircraft.availability.route ];

        if (!route[index].arrival_time) {
          await this.deleteLeg(registration, index);
          return;
        }

        if (index > -1) {
          route[index].isEdit = false;
          aircraft.availability.route = route;
        }
      },

      async updateQuoteRoute (registration, route) {
        const params = this.prepareLegParams(route);
        const { data } = await HTTPService.updateQuoteRoute(registration, params);
        this.updateAircraft(registration, data);
      },

      async updateAircraft (registration, data) {
        this.quotes[registration] = data;
        await this.filterAircrafts(AircraftsService.getAircraftsList(this.aircrafts, this.quotes));
      },

      async applyFilters (rawParams) {
        const params = { ...rawParams };
        const promises = [this.getAircrafts({ is_tracked: 1 })];
        this.requestLoading = true;

        if (params.departure_airport_id && params.arrival_airport_id) {
          this.quoteParams.departure_airport_id = params.departure_airport_id;
          this.quoteParams.arrival_airport_id = params.arrival_airport_id;

          promises.push(this.getQuotePrices({
            departure_airport_id: params.departure_airport_id,
            arrival_airport_id: params.arrival_airport_id
          }));
        }
        delete params.departure_airport_id;
        delete params.arrival_airport_id;
        delete params.arrivalAirport;
        delete params.departureAirport;

        const [aircrafts, quotes] = await Promise.all(promises);
        this.aircrafts = aircrafts ?? [];
        this.quotes = quotes ?? null;
        const query = await this.filterAircrafts(params);
        this.$router.replace({ name: 'dashboard', query }, () => {});
        this.requestLoading = false;
      },

      async filterAircrafts (params) {
        if ((!params || Object.values(params).every(value => value === null || value === undefined)) && !this.quotes) {
          this.filteredAircrafts = AircraftsService.getAircraftsList(this.aircrafts);
          return;
        }

        let aircrafts = AircraftsService.getAircraftsList(this.aircrafts, this.quotes);

        const filters = [
          AircraftsService.filterByType,
          AircraftsService.filterByAvailability,
          AircraftsService.filterByPayload
        ];
        const filteredData = filters.reduce((aircrafts, filterFn) => aircrafts.filter((el) => filterFn(el, params)), aircrafts);
        this.filteredAircrafts = [...filteredData];
        this.prevFilters = { ...params };

        const query = {};
        Object.keys(params).forEach(key => {
          if (Number.isInteger(params[key]) || params[key]) {
            query[key] = params[key];
          }
        });
        return query;
      },

      resetFilters () {
        this.prevFilters = {
          is_regular: null
        };

        this.$router.replace({ name: 'dashboard' }, () => {});

        return this.prevFilters;
      },

      parseQueryParams (query) {
        const params = {};

        Object.keys(query).forEach(key => {
          switch (key) {
          case 'is_regular':
          case 'payloadFrom':
          case 'payloadTo':
            if (query[key] !== null) {
              params[key] = parseInt(query[key]);
            }
            break;
          default:
            if (query[key] === 'true') {
              params[key] = true;
            }
          }
        });
        return params;
      }
    }
  };
</script>
