<template>
  <div>
    <div
      ref="googleMap"
      class="google-map"
    />
    <template v-if="google && map">
      <slot
        :google="google"
        :map="map"
      />
    </template>
  </div>
</template>

<script>
  import ErrorService from '../services/ErrorService';
  import { Loader } from '@googlemaps/js-api-loader';

  export default {
    props: {
      mapConfig: Object,
      apiKey: String
    },

    data () {
      return {
        google: null,
        map: null
      };
    },

    async mounted () {
      const googleMapApi = new Loader({
        apiKey: this.apiKey,
        version: 'weekly',
        libraries: ['places', 'drawing']
      });

      this.google = googleMapApi;
      this.initializeMap();
    },

    methods: {
      initializeMap: function () {
        const mapContainer = this.$refs.googleMap;
        this.google.load().then(google => {
          this.map = new google.maps.Map(mapContainer, this.mapConfig);
        }).catch(e => {
          ErrorService.handleException(e);
        });
      }
    }
  };
</script>

<style lang="scss">
  .google-map {
    width: 100%;
    min-height: 450px;
  }
</style>
