<template>
  <v-card-text>
    <v-row justify="space-around">
      <v-col
        sm="6"
        cols="12"
      >
        <v-select
          v-model="model.engine"
          :items="enginesList"
          item-text="name"
          item-value="value"
          name="engine"
          menu-props="auto"
          label="Engine"
          :error-messages="validationErrors.engine"
          single-line
          @input="clearValidation('engine')"
        />
      </v-col>
      <v-col
        sm="2"
        cols="4"
      >
        <v-checkbox
          v-model="model.is_cargo"
          :true-value="1"
          :false-value="null"
          label="Cargo"
          @change="handleCheckbox($event, 'is_cargo')"
        />
      </v-col>
      <v-col
        sm="2"
        cols="4"
      >
        <v-checkbox
          v-model="model.is_pax"
          :true-value="1"
          :false-value="null"
          label="PAX"
          @change="handleCheckbox($event, 'is_pax')"
        />
      </v-col>
      <v-col
        sm="2"
        cols="4"
      >
        <v-checkbox
          v-model="model.is_business"
          :true-value="1"
          :false-value="null"
          label="Business"
          @change="handleCheckbox($event, 'is_business')"
        />
      </v-col>
    </v-row>
    <v-row v-if="model.is_cargo">
      <v-col cols="3">
        <v-text-field
          v-if="model.is_cargo"
          v-model="model.payload_from"
          name="payload"
          label="Payload - from"
          type="number"
          :error-messages="validationErrors.payload_from"
          @input="clearValidation('payload_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_cargo"
          v-model="model.payload_to"
          name="payload"
          label="Payload - to"
          type="number"
          :error-messages="validationErrors.payload_to"
          @input="clearValidation('payload_to')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_cargo"
          v-model="model.volume_from"
          name="volume"
          label="Volume - from"
          type="number"
          :error-messages="validationErrors.volume_from"
          @input="clearValidation('volume_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_cargo"
          v-model="model.volume_to"
          name="volume"
          label="Volume - to"
          type="number"
          :error-messages="validationErrors.volume_to"
          @input="clearValidation('volume_to')"
        />
      </v-col>
    </v-row>
    <v-row v-if="model.is_pax || model.is_business">
      <v-col cols="6">
        <v-text-field
          v-if="model.is_pax || model.is_business"
          v-model="model.seat_from"
          name="seat"
          label="Seats capacity - from"
          type="number"
          :error-messages="validationErrors.seat_from"
          @input="clearValidation('seat_from')"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-if="model.is_pax || model.is_business"
          v-model="model.seat_to"
          name="seat"
          label="Seats capacity - to"
          type="number"
          :error-messages="validationErrors.seat_to"
          @input="clearValidation('seat_to')"
        />
      </v-col>
    </v-row>
    <v-row v-if="model.is_pax">
      <v-col cols="3">
        <v-text-field
          v-if="model.is_pax"
          v-model="model.luggage_payload_from"
          name="luggage_payload"
          label="Luggage Payload - from"
          type="number"
          :error-messages="validationErrors.luggage_payload_from"
          @input="clearValidation('luggage_payload_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_pax"
          v-model="model.luggage_payload_to"
          name="luggage_payload"
          label="Luggage Payload - to"
          type="number"
          :error-messages="validationErrors.luggage_payload_to"
          @input="clearValidation('luggage_payload_to')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_pax"
          v-model="model.luggage_volume_from"
          name="luggage_volume"
          label="Luggage Volume - from"
          type="number"
          :error-messages="validationErrors.luggage_volume_from"
          @input="clearValidation('luggage_volume_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_pax"
          v-model="model.luggage_volume_to"
          name="luggage_volume"
          label="Luggage Volume - to"
          type="number"
          :error-messages="validationErrors.luggage_volume_to"
          @input="clearValidation('luggage_volume_to')"
        />
      </v-col>
    </v-row>

    <v-row v-if="model.is_cargo">
      <v-col cols="3">
        <v-text-field
          v-model="model.door_height_from"
          name="door_height"
          label="Door height - from"
          :error-messages="validationErrors.door_height"
          @input="clearValidation('door_height_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="model.door_height_to"
          name="door_height"
          label="Door height - to"
          :error-messages="validationErrors.door_height"
          @input="clearValidation('door_height_to')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="model.door_width_from"
          name="door_width"
          label="Door width - from"
          :error-messages="validationErrors.door_width"
          @input="clearValidation('door_width_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="model.door_width_to"
          name="door_width"
          label="Door width - to"
          :error-messages="validationErrors.door_width"
          @input="clearValidation('door_width_to')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="model.runway_from"
          name="runway"
          label="Runway - from"
          type="number"
          :error-messages="validationErrors.runway_from"
          @input="clearValidation('runway_from')"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="model.runway_to"
          name="runway"
          label="Runway - to"
          type="number"
          :error-messages="validationErrors.runway_to"
          @input="clearValidation('runway_to')"
        />
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn
        type="button"
        color="primary"
        @click="applyFilters"
      >
        Apply
      </v-btn>
      <v-btn
        type="button"
        color="primary"
        text
        @click="onClose"
      >
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
  import { fieldValidation } from '../mixins/fieldValidation';
  import { engine } from '../constants';

  export default {
    mixins: [fieldValidation],

    props: {
      onFilter: {
        type: Function
      },
      submit: {
        type: Function
      },
      prevFilters: {
        type: Object
      }
    },

    data: () => ({
      model: {}
    }),

    computed: {
      enginesList: function () {
        let entries = Object.keys(engine).map((key) => {
          return { name: key, value: engine[key] };
        });
        entries = [{ name: 'ANY', value: null }, ...entries];
        return entries;
      }
    },

    created: function () {
      this.model = { ...this.prevFilters };
    },

    methods: {
      handleCheckbox (value, field) {
        if (!value) {
          if (field === 'is_cargo') {
            this.model.payload_from = null;
            this.model.payload_to = null;
            this.model.volume_from = null;
            this.model.volume_to = null;
            this.model.door_height_from = null;
            this.model.door_height_to = null;
            this.model.door_width_from = null;
            this.model.door_width_to = null;
          }
          if (field === 'is_pax' && !this.model.is_business ||
            field === 'is_business' && !this.model.is_pax) {
            this.model.seat_from = null;
            this.model.seat_to = null;
          }
          if (field === 'is_pax') {
            this.model.luggage_payload_from = null;
            this.model.luggage_payload_to = null;
            this.model.luggage_volume_from = null;
            this.model.luggage_volume_to = null;
          }
        }
      },

      onClose () {
        this.model = {};
        this.applyFilters();
        this.onFilter();
      },

      applyFilters () {
        this.submit(this.model);
      }
    }
  };
</script>
