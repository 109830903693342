<template>
  <v-container
    column
    px-1
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-row class="header">
          <h1 class="headerHeading">
            {{ aircraft.registration }} stats
          </h1>
          <v-spacer />

          <VueCtkDateTimePicker
            v-model="attributes"
            format="YYYY-MM-DD"
            formatted="YYYY-MM-DD"
            :range="true"
            :no-clear-button="true"
            :only-date="true"
          />
        </v-row>
        <v-card class="card totalsCard">
          <header class="cardHeader">
            <h1 class="cardHeading totalsCardHeading">
              Totals
            </h1>
          </header>
          <v-row class="totalsCardRow">
            <v-card class="totalsInnerCard">
              <header class="totalsInnerCardHeader">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="38px"
                    height="38px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      />
                      <path
                        d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
                        fill="#5d78ff"
                      />
                      <rect
                        fill="#5d78ff"
                        opacity="0.3"
                        transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) "
                        x="16.3255682"
                        y="2.94551858"
                        width="3"
                        height="18"
                        rx="1"
                      />
                    </g></svg></span>
                <h1 class="cardHeading totalsInnerCardHeading">
                  Quoted
                </h1>
              </header>
              <span class="cardText"> {{ totals.quotes.amount }} New Quotes </span>
            </v-card>
            <v-card class="totalsInnerCard">
              <header class="totalsInnerCardHeader">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="38px"
                    height="38px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                        fill="#0abb87"
                        fill-rule="nonzero"
                      />
                      <path
                        d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                        fill="#0abb87"
                        opacity="0.3"
                      />
                    </g></svg></span>
                <h1 class="cardHeading totalsInnerCardHeading">
                  Ordered
                </h1>
              </header>
              <span class="cardText"> {{ totals.flights.amount }} Commercial Flights </span>
            </v-card>
          </v-row>
          <v-row class="totalsCardRow">
            <v-card class="totalsInnerCard">
              <header class="totalsInnerCardHeader">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="38px"
                    height="38px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      />
                      <path
                        d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                        fill="#fd397a"
                        opacity="0.3"
                      />
                      <path
                        d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                        fill="#fd397a"
                      />
                    </g></svg></span>
                <h1 class="cardHeading totalsInnerCardHeading">
                  ACMI
                </h1>
              </header>
              <span class="cardText"> €{{ totals.quotes.acmi }} </span>
            </v-card>
            <v-card class="totalsInnerCard">
              <header class="totalsInnerCardHeader">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="38px"
                    height="38px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      />
                      <path
                        d="M12.7037037,14 L15.6666667,10 L13.4444444,10 L13.4444444,6 L9,12 L11.2222222,12 L11.2222222,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L12.7037037,14 Z"
                        fill="#ffb822"
                        opacity="0.3"
                      />
                      <path
                        d="M9.80428954,10.9142091 L9,12 L11.2222222,12 L11.2222222,16 L15.6666667,10 L15.4615385,10 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 L9.80428954,10.9142091 Z"
                        fill="#ffb822"
                      />
                    </g></svg></span>
                <h1 class="cardHeading totalsInnerCardHeading">
                  Average per flight
                </h1>
              </header>
              <span class="cardText">€{{ totals.flights.price }}</span>
            </v-card>
          </v-row>
        </v-card>
        <v-card class="card chartCard">
          <header class="cardHeader">
            <h1 class="cardHeading chartCardHeading">
              Revenue
            </h1>
            <span class="chartCardSubHeading revenueAmount">
              {{ overallRevenue }}
            </span>
            <span class="cardText">Total for period</span>
          </header>
          <WeeklyChart
            v-if="!loading"
            :current-chart-data="weeklyStats.revenueChartData"
            :prev-chart-data="prevWeeklyStats.revenueChartData"
          />
        </v-card>
        <v-card class="card chartCard">
          <header class="cardHeader">
            <h1 class="cardHeading chartCardHeading">
              Flight time
            </h1>
            <span class="chartCardSubHeading flightTimeAmount">
              {{ weeklyStats.flightTimeChartData.totalFlightTime }}+ hours
            </span>
            <span class="cardText">
              In air
            </span>
          </header>
          <WeeklyChart
            v-if="!loading"
            :current-chart-data="weeklyStats.flightTimeChartData"
            :prev-chart-data="prevWeeklyStats.flightTimeChartData"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      absolute
    />
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import StatsService from '../../services/StatsService';

  import WeeklyChart from './WeeklyChart';

  export default {
    components: {
      VueCtkDateTimePicker,
      WeeklyChart
    },

    props: {
      id: {
        required: true
      }
    },

    data: () => ({
      attributes: {
        start: DateTime.now()
          .minus({ years: 1 })
          .toFormat('yyyy-MM-dd'),
        end: DateTime.now().toFormat('yyyy-MM-dd')
      },
      loading: true,
      aircraft: {},
      totals: null,
      weeklyStats: null,
      prevWeeklyStats: null,
    }),

    computed: {
      overallRevenue: function () {
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'EUR'
        });

        if (!this.weeklyStats) {
          return;
        }
        return formatter.format(this.weeklyStats.revenueChartData.totalRevenue);
      }
    },

    watch: {
      attributes: {
        handler: async function (newAttributes, prevAttributes) {
          if (newAttributes.start && newAttributes.end) {
            this.loading = true;

            let requestParams = {
              fromDate: this.attributes.start,
              toDate: this.attributes.end,
              aircraft_id: this.id
            };
            let historyRequestParams = {
              fromDate: DateTime.fromISO(requestParams.fromDate.toString())
                .minus({ years: 1 })
                .toFormat('yyyy-MM-dd'),
              toDate: DateTime.fromISO(requestParams.toDate.toString())
                .minus({ years: 1 })
                .toFormat('yyyy-MM-dd'),
              aircraft_id: this.id
            };
            let totalStats = HTTPService.getStats(requestParams);
            let weeklyStats = HTTPService.getWeeklyStats(requestParams);
            let prevWeeklyStats = HTTPService.getWeeklyStats(historyRequestParams);

            Promise.all([totalStats, weeklyStats, prevWeeklyStats])
              .then(res => {
                let totalsData = StatsService.getStatsByAircraft(res[0].data);
                let currentPeriodData = StatsService.getWeeklyStats(res[1].data);
                let previousPeriodData = StatsService.getWeeklyStats(res[2].data);

                this.aircraft = { ...totalsData.aircraft };
                this.totals = { ...totalsData.totals };
                this.weeklyStats = currentPeriodData;
                this.prevWeeklyStats = previousPeriodData;
                this.loading = false;
              })
              .catch(e => {
                ErrorService.handleException(e);
              });
          }
        },
        immediate: true,
      },
    },
  };
</script>

<style scoped>
  .header {
    margin: 0;
    padding: 0.5em 1em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
  }

  .headerHeading {
    display: inline;
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    flex: 1;
  }

  .date-time-picker {
    max-width: 28.5em;
  }

  .card {
    margin: 1em;
    padding: 0.5em 1em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .cardHeader {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-flow: column nowrap;
    align-self: flex-start;
    text-align: left;
  }

  .cardHeading {
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
  }

  .cardText {
    font-size: 0.9em;
    font-weight: 400;
    color: #74788d;
  }

  .totalsCardHeading {
    color: #48465b;
  }

  .totalsCardRow {
    width: 90%;
    justify-content: center;
  }

  .totalsInnerCard {
    margin: 0.3em;
    padding: 1.5em;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.06);
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.06);
  }

  .chartCard {
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
  }

  .chartCardHeading {
    color: #48465b;
  }

  .chartCardSubHeading {
    font-size: 1.2em;
    font-weight: 600;
  }

  .revenueAmount {
    color: #5d78ff;
  }

  .flightTimeAmount {
    color: #fd397a;
  }
</style>
