<template>
  <v-snackbar
    :top="true"
    :timeout="-1"
    :value="error"
  >
    {{ error }}
    <v-btn
      color="error"
      @click="$store.dispatch('hideError')"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
  export default {
    computed: {
      error () {
        return this.$store.state.error;
      }
    }
  };
</script>
