<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>

    <v-form>
      <v-card-text>        
        <v-text-field
          v-model="model.name"
          name="name"
          label="Name"
          :error-messages="validationErrors.name"
          :disabled="loading"
          @input="clearValidation('name')"
        />

        <v-row justify="space-around">
          <v-col cols="6">
            <v-checkbox
              v-model="model.is_cargo"
              :disabled="loading"
              label="Cargo"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="model.is_pax"
              :disabled="loading"
              label="PAX"
            />
          </v-col>
        </v-row>

        <v-textarea
          v-model="model.note"
          name="note"
          label="Note"
          rows="1"
          auto-grow
          :error-messages="validationErrors.note"
          :disabled="loading"
          @input="clearValidation('note')"
        />

        <v-divider />

        <v-list>
          <v-subheader>Links</v-subheader>
          <v-list-item
            v-for="(item, index) in model.links"
            :key="`link-${index}`"
          >
            <v-list-item-action>
              <v-icon>mdi-link-variant</v-icon>
            </v-list-item-action>

            <v-list-item-content class="my-0 py-0">
              <v-list-item-title>
                <v-text-field
                  v-model="item.url"
                  name="url"
                  label="Url"
                  :error-messages="validationErrors[`links.${index}.url`]"
                  @input="clearValidation(`links.${index}.url`)"
                />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                color="error"
                @click="removeLink(item)"
              >
                mdi-delete
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="addLink()">
            <v-list-item-action>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Add</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-subheader>Emails</v-subheader>
          <v-list-item
            v-for="(item, index) in model.emails"
            :key="`email-${index}`"
          >
            <v-list-item-action>
              <v-icon>mdi-email</v-icon>
            </v-list-item-action>

            <v-list-item-content class="my-0 py-0">
              <v-list-item-title>
                <v-text-field
                  v-model="item.email"
                  name="email"
                  label="Email"
                  :error-messages="validationErrors[`emails.${index}.email`]"
                  @input="clearValidation(`emails.${index}.email`)"
                />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                color="error"
                @click="removeEmail(item)"
              >
                mdi-delete
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="addEmail()">
            <v-list-item-action>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Add</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-subheader>Phones</v-subheader>
          <v-list-item
            v-for="(item, index) in model.phones"
            :key="`phone-${index}`"
          >
            <v-list-item-action>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-action>

            <v-list-item-content class="my-0 py-0">
              <v-list-item-title>
                <v-text-field
                  v-model="item.phone"
                  name="phone"
                  label="Phone"
                  :error-messages="validationErrors[`phones.${index}.phone`]"
                  @input="clearValidation(`phones.${index}.phone`)"
                />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                color="error"
                @click="removePhone(item)"
              >
                mdi-delete
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="addPhone()">
            <v-list-item-action>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Add</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-subheader>Messengers</v-subheader>
          <v-list-item
            v-for="(item, index) in model.messengers"
            :key="`messenger-${index}`"
          >
            <v-list-item-action>
              <v-icon>mdi-message-text</v-icon>
            </v-list-item-action>

            <v-list-item-content class="my-0 py-0">
              <v-list-item-title>
                <v-text-field
                  v-model="item.name"
                  name="name"
                  label="Name"
                  :error-messages="validationErrors[`messengers.${index}.name`]"
                  @input="clearValidation(`messengers.${index}.name`)"
                />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                color="error"
                @click="removeMessenger(item)"
              >
                mdi-delete
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="addMessenger()">
            <v-list-item-action>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Add</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </v-list>

        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="model.status"
              :disabled="loading"
              label="Active"
            />
          </v-col>
        </v-row>
        <v-divider />
      </v-card-text>

      <div class="loader-container">
        <v-progress-linear
          v-if="loading"
          class="loader"
          :indeterminate="true"
        />
      </div>

      <v-card-actions>
        <v-btn
          type="button"
          color="primary"
          :disabled="loading"
          @click="applyChanges"
        >
          Save
        </v-btn>
        <v-btn
          type="button"
          color="primary"
          text
          :disabled="loading"
          @click="onClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import { fieldValidation } from '../../mixins/fieldValidation';

  export default {
    mixins: [fieldValidation],

    props: ['person', 'onClose', 'onSubmit', 'airlineId'],

    data () {
      return {
        loading: false,
        model: {
          links: [],
          emails: [],
          phones: [],
          messengers: [],
        },
      };
    },

    computed: {
      title: function () {
        return this.person ? 'Edit person' : 'Add person';
      },
    },

    watch: {
      person: {
        handler: 'setModel',
        immediate: true
      }
    },

    methods: {
      setModel () {
        if (this.person) {
          this.model = { ...this.person };
          this.model.links = this.model.links || [];
          this.model.emails = this.model.emails || [];
          this.model.phones = this.model.phones || [];
          this.model.messengers = this.model.messengers || [];
        }
        this.model.airline_id = this.airlineId;
      },

      applyChanges () {
        this.loading = true;
        const result = this.person
          ? HTTPService.updatePerson(this.model.id, this.model)
          : HTTPService.createPerson(this.model);
                      
        result
          .then(res => {
            this.onSubmit(res.data);
          })
          .catch(({ response }) => {
            this.setValidation(ErrorService.handleError(response));
          })
          .finally(() => {
            this.loading = false;
          });
      },

      addLink () {
        this.model.links.push({});
      },

      addEmail () {
        this.model.emails.push({});
      },

      addPhone () {
        this.model.phones.push({});
      },
      addMessenger () {
        this.model.messengers.push({});
      },

      removeLink (item) {
        let index = this.model.links.findIndex(el => el === item);
        for (let i = 0; i < this.model.links.length; i++) {
          this.clearValidation(`links.${i}.url`);
        }
        this.model.links.splice(index, 1);
      },

      removeEmail (item) {
        let index = this.model.emails.findIndex(el => el === item);
        for (let i = 0; i < this.model.emails.length; i++) {
          this.clearValidation(`emails.${i}.email`);
        }
        this.model.emails.splice(index, 1);
      },

      removePhone (item) {
        let index = this.model.phones.findIndex(el => el === item);
        for (let i = 0; i < this.model.phones.length; i++) {
          this.clearValidation(`phones.${i}.phone`);
        }
        this.model.phones.splice(index, 1);
      },

      removeMessenger (item) {
        let index = this.model.messengers.findIndex(el => el === item);
        for (let i = 0; i < this.model.messengers.length; i++) {
          this.clearValidation(`messengers.${i}.name`);
        }
        this.model.messengers.splice(index, 1);
      },
    }
  };
</script>
