<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
    >
      <div class="d-flex flex-wrap justify-space-between align-center">
        <v-toolbar-title>
          {{ heading }}
        </v-toolbar-title>
        <v-btn-toggle
          v-model="isMapView"
          color="primary"
          light
          rounded
          dense
          mandatory
          class="toggle-btn-group"
        >
          <v-btn>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
          <v-btn>
            <v-icon>mdi-map-marker-radius</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-toolbar>

    <v-container
      fluid
      px-0
      py-0
    >
      <v-row no-gutters>
        <v-col>
          <v-card v-if="isMapView">
            <RecentFlightsMap
              :aircrafts="flights"
              is-quote
            />
          </v-card>
          <v-card
            v-if="!isMapView"
            class="pb-4"
          >
            <v-data-table
              :headers="headers"
              :items="flights"
              no-data-text="No flights yet"
              :hide-default-footer="true"
              :disable-pagination="true"
            >
              <template #[`item.to`]="{ item }">
                <AirportSearch
                  v-if="item.isEdit && leg.arrival"
                  :airport="leg.arrival"
                  class="mt-1"
                  :error-message="errors.arrival"
                  @clear-error="errors.arrival = null"
                  @on-submit="onAirportSelect"
                />
                <span v-else>{{ item.to }}</span>
              </template>

              <template #[`item.is_ferry`]="{ item }">
                <v-select
                  v-if="item.isEdit && item.is_ferry"
                  v-model="leg.is_ferry"
                  :items="isFerryItems"
                  item-value="value"
                  item-text="name"
                  dense
                  disabled
                  class="mt-4"
                />
                <span v-else>
                  {{ item.is_ferry ? 'ferry' : 'cargo' }}
                </span>
              </template>

              <template #[`item.flight_time`]="{ item }">
                <span>{{ item.flight_time || '—' }}</span>
              </template>

              <template #[`item.warnings`]="{ item }">
                <v-tooltip
                  v-if="item.cr"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="light-blue"
                      v-bind="attrs"
                      dense
                      class="pa-1"
                      v-on="on"
                    >
                      mdi-bed
                    </v-icon>
                  </template>
                  <div>Trip time is longer than FDP</div>
                </v-tooltip>

                <v-tooltip
                  v-if="item.refuel"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      :color="item.refuel.reached_reserve ? 'error' : 'light-blue'"
                      v-bind="attrs"
                      dense
                      class="pa-1"
                      v-on="on"
                    >
                      mdi-gas-station
                    </v-icon>
                  </template>
                  <div v-if="!item.refuel.reached_reserve">
                    Needs refuel
                  </div>
                  <div v-if="item.refuel.reached_reserve">
                    Fuel consumption is greater than the fuel reserve
                  </div>
                </v-tooltip>
              </template>

              <template #[`item.actions`]="{ item, index }">
                <div
                  v-if="!item.isEdit && (!item.is_ferry || index > 0 && item.to)"
                  class="d-flex flex-no-wrap"
                >
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        color="light-blue"
                        class="mx-1"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click="editLeg(index)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <div>
                      Edit leg
                    </div>
                  </v-tooltip>
                  <v-tooltip
                    v-if="item.is_ferry"
                    top
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        class="mx-1"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click="openDeleteModal(index)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <div>
                      Delete leg
                    </div>
                  </v-tooltip>
                </div>
                <div
                  v-if="item.isEdit"
                  class="d-flex flex-no-wrap"
                >
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        color="green"
                        class="mx-1"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click="saveLeg(index)"
                      >
                        mdi-check
                      </v-icon>
                    </template>
                    <div>
                      Save leg
                    </div>
                  </v-tooltip>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        color="error"
                        class="mx-1"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click="$emit('dismiss-leg', registration, index)"
                      >
                        mdi-close
                      </v-icon>
                    </template>
                    <div>
                      Dismiss changes
                    </div>
                  </v-tooltip>
                </div>
              </template>

              <template #[`footer`]="{}">
                <div
                  v-if="showAddButton"
                  class="d-flex justify-end"
                >
                  <v-btn
                    type="button"
                    color="primary"
                    class="mt-4 mx-4"
                    @click="addLeg"
                  >
                    Add leg
                  </v-btn>
                </div>
              </template>
            </v-data-table>
            <DeleteDialog
              v-model="showDeleteModal"
              title="Leg"
              :on-submit="deleteLeg"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import AirportSearch from './AirportSearch';
  import DeleteDialog from './DeleteDialog';
  import RecentFlightsMap from './RecentFlightsMap';

  export default {
    components: {
      AirportSearch,
      DeleteDialog,
      RecentFlightsMap,
    },

    props: {
      registration: {
        type: String,
        require: true
      },
      flights: {
        type: Array,
        require: true
      },
    },

    data () {
      return {
        headers: [
          { text: 'Takeoff', value: 'takeoff_time', sortable: true, },
          { text: 'From', value: 'from', sortable: true, },
          { text: 'To', value: 'to', sortable: true, },
          { text: 'Landing', value: 'arrival_time', sortable: true, },
          { text: 'FT', value: 'flight_time', sortable: true, width: '8%' },
          { text: 'Type', value: 'is_ferry', sortable: true, width: '8%' },
          { text: 'Warnings', value: 'warnings', sortable: false, width: '12%' },
          { text: '', value: 'actions', sortable: false, width: '10%' },
        ],
        leg: {},
        errors: {
          arrival: null
        },
        isMapView: false,
        showDeleteModal: false,
        indexToDelete: null,
      };
    },

    computed: {
      heading () {
        const arrivals = this.flights.map(flight => flight.to).join(' - ');
        return `Calculated route for ${this.flights[0].from} - ${arrivals} of ${this.registration}`;
      },

      isFerryItems () {
        return [
          { name: 'Ferry', value: true },
          { name: 'Cargo', value: false },
        ];
      },

      showAddButton () {
        const lastLeg = this.flights[this.flights.length - 1];
        return lastLeg.to && !lastLeg.is_ferry;
      }
    },

    methods: {
      async addLeg () {
        this.$emit('add-leg', this.registration);
        await this.$nextTick();
        this.leg = { ...this.flights[this.flights.length-1] };
      },

      async editLeg (index) {
        this.leg = {};
        this.$emit('edit-leg', this.registration, index);
        await this.$nextTick();
        this.leg = { ...this.flights[index] };
      },

      saveLeg (index) {
        if (!this.leg.to) {
          this.errors.arrival = 'Please select an airport';
          return;
        }
        this.$emit('save-leg', this.registration, index, this.leg);
      },

      deleteLeg () {
        this.$emit('delete-leg', this.registration, this.indexToDelete);
        this.showDeleteModal = false;
      },

      openDeleteModal (index) {
        this.indexToDelete = index;
        this.showDeleteModal = true;
      },

      onAirportSelect (airport) {
        this.leg.arrival = airport || {};
        this.leg.to = airport ? airport.iata ?? airport.icao : null;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .toggle-btn-group {
    position: absolute;
    right: 2em;

    @media (max-width: 600px)  {
      height: 1.5em;
      margin-left: 1em;
      position: relative;
      right: 0;
      .v-btn:not(.v-btn--round).v-size--default {
        height: 1.75em;
      }
    }

    @media (max-width: 500px)  {
      margin-left: 0;
    }
  }

  ::v-deep .v-data-table-header th {
    white-space: nowrap;
  }
</style>
