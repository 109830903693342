<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider="-"
        />

        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            Aircraft Types
          </h1>

          <v-btn
            type="button"
            color="primary"
            @click="openAddModal"
          >
            Add type
          </v-btn>
        </v-row>
        <v-card>
          <AircraftTypesList
            :types="types"
            :on-edit="openEditTypeModal"
            :on-delete="openDeleteModal"
            :filter-types="filterAircraftTypes"
            :prev-filters="prevFilters"
          />

          <v-dialog
            v-model="isAddModalOpened"
            max-width="600px"
          >
            <AircraftTypeEdit
              :key="addDialogKey"
              :on-close="closeAddModal"
              :on-submit="addType"
            />
          </v-dialog>

          <v-dialog
            v-model="isEditModalOpened"
            max-width="600px"
          >
            <AircraftTypeEdit
              :on-close="closeEditTypeModal"
              :on-submit="editType"
              :aircraft-type="typeToEdit"
            />
          </v-dialog>

          <DeleteDialog
            v-model="isDeleteModalOpened"
            title="aircraft type"
            :name="typeToDelete.name"
            :loading="deleting"
            :on-submit="deleteType"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import { getNameById } from '../../constants';
  import AircraftTypeEdit from './AircraftTypeEdit';
  import AircraftTypesList from './AircraftTypesList';
  import DeleteDialog from '../../components/DeleteDialog';

  export default {
    components: { AircraftTypeEdit, AircraftTypesList, DeleteDialog },

    data: () => ({
      loading: true,
      deleting: false,
      search: '',
      types: [],
      isEditModalOpened: false,
      isAddModalOpened: false,
      isDeleteModalOpened: false,
      typeToEdit: {},
      typeToDelete: {},
      addDialogKey: 0,
      prevFilters: {}
    }),

    computed: {
      breadcrumbs: function () {
        return [
          {
            text: 'Home',
            append: true,
            exact: true,
            to: '/'
          },
          {
            text: 'Aircraft Types',
            disabled: true
          }
        ];
      }
    },

    created () {
      this.filterAircraftTypes(this.parseQueryParams(this.$route.query));
    },

    methods: {
      openDeleteModal (type) {
        this.typeToDelete = type;
        this.isDeleteModalOpened = true;
      },

      async deleteType () {
        this.deleting = true;
        try {
          await HTTPService.deleteAircraftType(this.typeToDelete.id);
          this.types = this.types.filter(item => item.id !== this.typeToDelete.id);
          this.isDeleteModalOpened = false;
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.deleting = false;
        }
      },

      openAddModal () {
        this.addDialogKey++;
        this.isAddModalOpened = true;
      },

      addType (type) {
        this.isAddModalOpened = false;
        this.types = [
          ...this.types,
          this.prepareAircraftType(type)
        ];
      },

      closeAddModal () {
        this.isAddModalOpened = false;
      },

      async openEditTypeModal (type) {
        try {
          const { data } = await HTTPService.getAircraftType(type.id);
          this.typeToEdit = { ...data };
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
        this.isEditModalOpened = true;
      },

      editType (type) {
        this.isEditModalOpened = false;
        let types = [...this.types];
        let result = types.map((item) => {
          if (item.id === type.id) {
            item = {
              ...item,
              ...this.prepareAircraftType(type)
            };
            return item;
          }
          return item;
        });
        this.types = [...result];
      },

      closeEditTypeModal () {
        this.isEditModalOpened = false;
      },

      getClass (mtow) {
        if (!mtow) {
          return;
        } else if (mtow > 136000) {
          return 'Heavy';
        } else if (mtow > 7000 && mtow < 136000) {
          return 'Medium';
        } else {
          return 'Light';
        }
      },

      async filterAircraftTypes (params) {
        try {
          const { data } = await HTTPService.getAircraftTypes(params);
          this.types = data.map(this.prepareAircraftType);
          this.prevFilters = { ...params };

          const query = {};
          Object.keys(params).forEach(key => {
            if (params[key] !== null) {
              query[key] = params[key];
            }
          });
          this.$router.replace({ name: 'aircraft-types', query }, () => {});
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
        this.loading = false;
      },

      prepareAircraftType (type) {
        return {
          name: type.name,
          id: type.id,
          nav: type.nav,
          velocity: type.velocity,
          consumption: type.consumption,
          taxi: type.taxi,
          mtow: type.mtow,
          engine: getNameById(type.engine),
          icao: type.icao,
          class: this.getClass(type.mtow)
        };
      },

      parseQueryParams (query) {
        const params = {};
        Object.keys(query).forEach(key => {
          const value = query[key];
          switch (key) {
          case 'engine':
          case 'is_cargo':
          case 'is_pax':
          case 'is_business':
            if (value !== null) {
              params[key] = parseInt(value);
            }
            break;
          default:
            params[key] = value;
          }
        });
        return params;
      }
    }
  };
</script>
