import { DateTime } from 'luxon';
import AircraftService from './AircraftService';

class FlightService {
  getDate (date, includeTime) {
    if (!date) return '—';
    const format = includeTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd';
    const dateObj = date.includes('T') ? DateTime.fromISO(date, { zone: 'UTC' }) : DateTime.fromFormat(date, format);
    return dateObj.toFormat(format);
  }

  getDuration (from, to) {
    if (!from || !to) return '—';
    const format = 'yyyy-MM-dd HH:mm';
    const fromDateTime = from.includes('T') ? DateTime.fromISO(from, { zone: 'UTC' }) : DateTime.fromFormat(from, format);
    const toDateTime = to.includes('T') ? DateTime.fromISO(to, { zone: 'UTC' }) : DateTime.fromFormat(to, format);
    const duration = toDateTime.diff(fromDateTime);

    return duration.toFormat('h:mm');
  }

  getFrom (flight) {
    return flight && flight.departure ?
      (flight.departure.iata ? flight.departure.iata : flight.departure.icao)
      : '—';
  }

  getTo (flight) {
    return flight && flight.arrival ?
      (flight.arrival.iata ? flight.arrival.iata : flight.arrival.icao)
      : '—';
  }

  parseFlight (flight) {
    return {
      ...flight,
      roleIcon: AircraftService.getRoleIcon(flight.aircraft),
      date: this.getDate(flight.timestamp),
      takeoffTimeParsed: this.getDate(flight.takeoff_time, true),
      arrivalTimeParsed: this.getDate(flight.arrival_time, true),
      durationString: this.getDuration(flight.takeoff_time, flight.arrival_time),
      from: this.getFrom(flight),
      to: this.getTo(flight)
    };
  }
}

export default new FlightService();
