<template>
  <v-container
    fluid
    pa-0
  >
    <v-card
      :v-if="stats"
      class="mb-4 pa-4"
    >
      <header>
        <h1 class="card-heading">
          Summary
        </h1>
      </header>
      <v-row>
        <v-col>
          Total FLT
        </v-col>
        <v-spacer />
        <v-col>
          {{ stats.totalFlt }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Total BLK
        </v-col>
        <v-spacer />
        <v-col>
          {{ stats.totalBlk }}
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-4">
      <header>
        <h1 class="card-heading">
          Fuel
        </h1>
      </header>
      <v-row>
        <v-col>
          AVG <small>kg/FLTh</small>
        </v-col>
        <v-spacer />
        <v-col>
          {{ stats.avgFlth }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          MIN <small>kg/FLTh</small>
        </v-col>
        <v-spacer />
        <v-col>
          {{ stats.minFlth }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          MAX <small>kg/FLTh</small>
        </v-col>
        <v-spacer />
        <v-col>
          {{ stats.maxFlth }}
        </v-col>
      </v-row>
      <v-divider class="my-4" />
      <v-row>
        <v-col>
          AVG <small>kg/BLKh</small>
        </v-col>
        <v-spacer />
        <v-col>
          {{ stats.avgBlkh }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          MIN <small>kg/BLKh</small>
        </v-col>
        <v-spacer />
        <v-col>
          {{ stats.minBlkh }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          MAX <small>kg/BLKh</small>
        </v-col>
        <v-spacer />
        <v-col>
          {{ stats.maxBlkh }}
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    props: ['stats']
  };
</script>

<style scoped>
  .card-heading {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    text-align: left;
  }
</style>
