<template>
  <v-container
    fluid
    px-0
    py-0
  >
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-card-title class="py-0">
            <v-row>
              <v-col>
                <v-row no-gutters>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      :color="iconColor"
                      @click="toggleFilters()"
                    >
                      mdi-filter
                    </v-icon>
                  </div>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    class
                    single-line
                    hide-details
                  />
                </v-row>
                <v-row>
                  <AirlineFilters
                    v-if="isFiltersOpened"
                    :on-filter="toggleFilters"
                    :submit="filterAirlines"
                    :prev-filters="prevFilters"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :search="search"
            :headers="headers"
            :items="airlines"
            no-data-text="No airlines yet"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
          >
            <template #[`header.status`]="{}">
              <v-icon>
                mdi-alphabetical-variant-off
              </v-icon>
            </template>

            <template #[`item.name`]="{ item }">
              <router-link :to="'/airlines/' + item.id">
                {{ item.name }}
              </router-link>
            </template>

            <template #[`item.status`]="{ item }">
              <v-icon
                small
                color="primary"
              >
                {{ item.status }}
              </v-icon>
            </template>

            <template #[`item.editId`]="{ item }">
              <v-icon
                color="primary"
                @click="onEdit(item)"
              >
                mdi-pencil
              </v-icon>
            </template>

            <template #[`item.deleteId`]="{ item }">
              <v-icon
                color="error"
                @click="onDelete(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AirlineFilters from '../../components/AirlineFilters';

  export default {
    components: { AirlineFilters },

    props: {
      airlines: {
        type: Array
      },
      onEdit: {
        type: Function
      },
      onDelete: {
        type: Function
      },
      filterAirlines: {
        type: Function
      },
      prevFilters: {
        type: Object
      }
    },

    data: () => ({
      search: '',
      isFiltersOpened: false,
    }),

    computed: {
      headers: function () {
        return [
          { text: 'Name', value: 'name', sortable: true },
          { text: 'IATA', value: 'iata', sortable: true },
          { text: 'ICAO', value: 'icao', sortable: true },
          { text: '', value: 'status', sortable: true },
          { text: '', value: 'editId', sortable: false },
          { text: '', value: 'deleteId', sortable: false }
        ];
      },
      iconColor: function () {
        return this.isFiltersOpened ? 'primary' : 'secondary';
      }
    },

    methods: {
      toggleFilters () {
        this.isFiltersOpened = !this.isFiltersOpened;
      }
    }
  };
</script>

<style scoped>
  .editable {
    cursor: pointer;
    border-bottom: 1px #313131 dashed;
  }

  .v-data-table-header .v-icon {
    color: inherit;
  }
</style>
