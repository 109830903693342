<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider="-"
        />

        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            Airlines
          </h1>

          <v-btn
            type="button"
            color="primary"
            @click="openAddModal"
          >
            Add airline
          </v-btn>
        </v-row>
        <v-card>
          <AirlinesList
            :airlines="airlines"
            :on-edit="openEditModal"
            :on-delete="openDeleteModal"
            :filter-airlines="filterAirlines"
            :prev-filters="prevFilters"
          />

          <v-dialog
            v-model="isModalOpened"
            max-width="600px"
          >
            <AirlineEdit
              :key="addDialogKey"
              :on-close="closeAddModal"
              :on-submit="addAirline"
            />
          </v-dialog>

          <v-dialog
            v-model="isEditModalOpened"
            max-width="600px"
          >
            <AirlineEdit
              :on-close="closeEditModal"
              :on-submit="editAirline"
              :airline="airlineToEdit"
            />
          </v-dialog>

          <DeleteDialog
            v-model="isDeleteModalOpened"
            title="airline"
            :name="airlineToDelete.name"
            :loading="deleting"
            :on-submit="submitDeleteAirline"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import DeleteDialog from '../../components/DeleteDialog';
  import AirlineEdit from './AirlineEdit';
  import AirlinesList from './AirlinesList';

  export default {
    components: { DeleteDialog, AirlineEdit, AirlinesList },

    data: () => ({
      loading: true,
      deleting: false,
      search: '',
      airlines: [],
      isEditModalOpened: false,
      isModalOpened: false,
      isDeleteModalOpened: false,
      airlineToEdit: {},
      airlineToDelete: {},
      addDialogKey: 0,
      prevFilters: {},
    }),

    computed: {
      breadcrumbs: function () {
        return [
          {
            text: 'Home',
            append: true,
            exact: true,
            to: '/'
          },
          {
            text: 'Airlines',
            disabled: true
          }
        ];
      },
    },

    created () {
      this.filterAirlines(this.parseQueryParams(this.$route.query));
    },

    methods: {
      openDeleteModal (airline) {
        this.airlineToDelete = airline;
        this.isDeleteModalOpened = true;
      },

      async submitDeleteAirline () {
        this.deleting = true;

        try {
          await HTTPService.deleteAirline(this.airlineToDelete.id);
          this.airlines = this.airlines.filter(item => item.id !== this.airlineToDelete.id);
          this.isDeleteModalOpened = false;
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.deleting = false;
        }
      },

      openAddModal () {
        this.addDialogKey++;
        this.isModalOpened = true;
      },

      closeAddModal () {
        this.isModalOpened = false;
      },

      async openEditModal (airline) {
        try {
          const { data } = await HTTPService.getAirline(airline.id);
          this.airlineToEdit = { ...data };
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.isEditModalOpened = true;
        }
      },

      closeEditModal () {
        this.isEditModalOpened = false;
      },

      addAirline (airline) {
        this.isModalOpened = false;
        this.airlines = [
          ...this.airlines,
          {
            id: airline.id,
            homebase_id: airline.homebase_id,
            name: airline.name,
            iata: airline.iata,
            icao: airline.icao,
            acmi: airline.acmi,
            fuelpr: airline.fuelpr,
            status: this.getStatusIcon(airline.status)
          }
        ];
      },

      editAirline (airline) {
        this.isEditModalOpened = false;
        let airlines = [...this.airlines];
        let result = airlines.map(item => {
          if (item.id === airline.id) {
            item = {
              ...item,
              id: airline.id,
              name: airline.name,
              iata: airline.iata,
              icao: airline.icao,
              acmi: airline.acmi,
              fuelpr: airline.fuelpr,
              status: this.getStatusIcon(airline.status)
            };
            return item;
          }
          return item;
        });
        this.airlines = [...result];
      },

      getStatusIcon (item) {
        if (item && item > 0) {
          return '';
        }
        return 'mdi-alphabetical-variant-off';
      },

      async filterAirlines (params = {}) {
        try {
          const query = {};
          const { data } = await HTTPService.getAirlines(params);
          this.airlines = this.prepareAirlines(data);
          this.prevFilters = { ...params };
          this.loading = false;

          Object.keys(params).forEach(key => {
            if (params[key] !== null) {
              query[key] = params[key];
            }
          });
          this.$router.replace({ name: 'airlines', query }, () => {});
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
      },

      prepareAirlines (airlines) {
        return airlines.map(item => {
          return {
            id: item.id,
            homebase_id: item.homebase_id,
            name: item.name,
            iata: item.iata,
            icao: item.icao,
            acmi: item.acmi,
            fuelpr: item.fuelpr,
            status: this.getStatusIcon(item.status)
          };
        });
      },

      parseQueryParams (params) {
        const query = {};
        Object.keys(params).forEach(key => {
          const value = params[key];
          switch (key) {
          case 'status':
          case 'engine':
          case 'is_cargo':
          case 'is_pax':
          case 'is_business':
            if (value !== null) {
              query[key] = parseInt(value);
            }
            break;
          case 'aircraft_type_ids':
          case 'countryIds':
            query[key] = Array.isArray(value) ? value.map(id => parseInt(id)) : [parseInt(value)];
            break;
          case 'regions':
            query[key] = Array.isArray(value) ? [...value] : [value];
            break;
          default:
            query[key] = value;
            break;
          }
        });

        return query;
      }
    }
  };
</script>
