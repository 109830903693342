import { DateTime } from 'luxon';
import AircraftService from './AircraftService';
import FlightsService from '@/services/FlightsService';
import { flightAvailability } from '@/constants';

class AircraftsService {
  getAircraftsList (dataArr, availability = null) {
    return dataArr.map(item => {
      const recentFlights = FlightsService.getFlightsList(item.last_flights || []);
      item = { ...item, recentFlights };
      return {
        ...item,
        roleIcon: AircraftService.getRoleIcon(item),
        last_flights: recentFlights,
        isCharter: AircraftService.isCharter(recentFlights),
        isFerry: AircraftService.isFerry(recentFlights),
        availability: AircraftService.getAvailability({
          aircraft: item,
          pricing: availability && availability[item.registration] ? availability[item.registration] : null
        })
      };
    });
  }

  update (dataArr, aircraft) {
    return dataArr.map(item => {
      if (item.registration.toUpperCase() !== aircraft.registration.toUpperCase()) {
        return item;
      }

      const recentFlights = FlightsService.getFlightsList(aircraft.last_flights || []);
      const parsedAircraft = { ...aircraft, recentFlights };
      return {
        ...item,
        last_flights: recentFlights,
        availability: AircraftService.getAvailability({ aircraft: parsedAircraft })
      };
    });
  }

  filterByType (item, params) {
    const { is_regular } = params;
    if (item.isCharter === null && is_regular !== null) return;
    if (is_regular === null || is_regular === undefined ||
      (item.isCharter && !is_regular) || (!item.isCharter && is_regular)) {
      return item;
    }
    return;
  }

  filterByAvailability (item, params) {
    const {
      available,
      notAvailable,
      aog,
    } = params;
    if ((notAvailable || available || aog) && !item.availability) return;
    if ((!notAvailable && !available && !aog) ||
      notAvailable && item.availability.status === flightAvailability.notAvailable.status ||
      available && (item.availability.status === flightAvailability.available.status ||
        item.availability.status === flightAvailability.availableSoon.status) ||
      aog && item.availability.status === flightAvailability.aog.status) {
      return item;
    } else {
      return false;
    }
  }

  filterByPayload (item, params) {
    const { payloadFrom, payloadTo } = params;

    if (!payloadFrom && !payloadTo) {
      return item;
    } else if (!item.aircraftType || item.aircraftType.payload === null) {
      return;
    }
    if (item.aircraftType.payload >= payloadFrom && item.aircraftType.payload <= payloadTo) {
      return item;
    } else {
      return;
    }
  }
}

export default new AircraftsService();
