<template>
  <v-dialog
    v-model="isOpened"
    max-width="400px"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>Delete {{ computedTitle }}</v-toolbar-title>
      </v-toolbar>

      <v-progress-linear
        v-if="loading"
        :indeterminate="true"
      />

      <v-form>
        <h3 class="my-5 px-3">
          Do you want to delete {{ computedName }} ?
        </h3>

        <v-card-actions>
          <v-btn
            type="button"
            color="primary"
            @click="onSubmit"
          >
            Delete
          </v-btn>
          <v-btn
            type="button"
            color="primary"
            text
            @click="closeModal"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {

    props: {
      'value': Boolean,
      'title': String,
      'name': String,
      'loading': Boolean,
      'onSubmit': {
        'type': Function,
        default: () => {}
      },
      'onClose': {
        'type': Function,
        default: () => {}
      }
    },

    computed: {
      isOpened: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('input', value);
        }
      },

      computedTitle: function () {
        return this.title ? this.title : '';
      },

      computedName: function () {
        return this.name ? this.name : 'this item';
      },
    },

    methods: {
      closeModal () {
        this.isOpened = false;
        this.onClose();
      },
    }
  };
</script>
