import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"400px"},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Delete "+_vm._s(_vm.computedTitle))])],1),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),_c(VForm,[_c('h3',{staticClass:"my-5 px-3"},[_vm._v(" Do you want to delete "+_vm._s(_vm.computedName)+" ? ")]),_c(VCardActions,[_c(VBtn,{attrs:{"type":"button","color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" Delete ")]),_c(VBtn,{attrs:{"type":"button","color":"primary","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }