<template>
  <v-app id="inspire">
    <v-app-bar
      fixed
      app
      dark
      color="primary"
    >
      <v-toolbar-title>
        <router-link
          class="d-flex flex-row align-end text-decoration-none white--text"
          to="/"
          custom
        >
          <v-img
            alt="AVEM AERO Track"
            width="40"
            :src="logo"
            class="mr-2"
          />

          Track
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      right
    >
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.path"
        >
          <v-list-item-title class="text-center">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <template #append>
        <div v-if="telegramLink"
             class="pa-2 text-center"
        >
          <v-btn
            icon
            rounded
            :href="telegramLink"
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-send
                </v-icon>
              </template>
              <span>Telegram</span>
            </v-tooltip>
          </v-btn>
        </div>
        <div class="pa-2 text-center">
          <v-btn
            text
            @click.stop="$store.dispatch('logout')"
          >
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import logoImg from '../../assets/img/logo.png';
  import PermissionsService from '../../services/PermissionsService';

  export default {
    data: () => ({
      drawer: null,
      menuItems: [
        {
          title: 'Aircrafts',
          path: '/aircrafts',
          permissions: 'ViewAircrafts',
        },
        {
          title: 'Aircraft Types',
          path: '/aircraft-types',
          permissions: 'EditAircraftTypes',
        },
        {
          title: 'Airlines',
          path: '/airlines',
          permissions: 'ViewOperators',
        },
        {
          title: 'Flights',
          path: '/flights',
          permissions: 'ViewFlights',
        },
        {
          title: 'Stats',
          path: '/stats',
          permissions: 'ViewStatistics',
        },
      ]
    }),

    computed: {
      logo () {
        return logoImg;
      },

      items () {
        return this.menuItems.filter(item => PermissionsService.check(item.permissions));
      },

      telegramLink () {
        const store = this.$store;
        if (!process.env.VUE_APP_TELEGRAM_BOT || !store.state?.userData?.id) {
          return null;
        }
        return `tg://resolve?domain=${ process.env.VUE_APP_TELEGRAM_BOT }&start=${ store.state?.userData?.id }`;
      },
    },
  };
</script>

<style scoped></style>
