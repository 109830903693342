import { DateTime } from 'luxon';
import FlightService from '@/services/FlightService';

class FlightsService {
  getFlightsList (dataArr) {
    const format = 'yyyy-MM-dd HH:mm';
    const res = dataArr
      .sort((a, b) => {
        const getDateObj = date => date.includes('T') ? DateTime.fromISO(date, { zone: 'UTC' }) : DateTime.fromFormat(date, format);
        const aDateObj = getDateObj(a.takeoff_time);
        const bDateObj = getDateObj(b.takeoff_time);
        return bDateObj.toMillis() - aDateObj.toMillis();
      })
      .map(item => {
        return FlightService.parseFlight(item);
      });
    return res;
  }

  async processStats (data, groupBy) {
    if (!data) return;

    const result = {};
    data.forEach(flight => {
      let key;
      switch (groupBy) {
        case 'aircraftType':
          key = flight.aircraft.aircraftType.name;
          break;
        case 'airline':
          key = flight.aircraft.airline.name;
          break;
        default:
          key = flight.date;
          break;
      }

      if (!result[key]) {
        result[key] = {
          cargoRegular: 0,
          cargoCharter: 0,
          paxRegular: 0,
          paxCharter: 0,
          test: 0,
        };
      }

      if (flight.departure_airport_id === flight.arrival_airport_id) {
        result[key].test++;
      } else if (flight.aircraft.is_cargo) {
        if (flight.is_regular) {
          result[key].cargoRegular++;
        } else {
          result[key].cargoCharter++;
        }
      } else if (flight.aircraft.is_pax) {
        if (flight.is_regular) {
          result[key].paxRegular++;
        } else {
          result[key].paxCharter++;
        }
      }
    });

    return result;
  }

  async getDailyStats (data) {
    return this.processStats(data);
  }

  async getStatsByAircraftType (data) {
    return this.processStats(data, 'aircraftType');
  }

  async getStatsByAirline (data) {
    return this.processStats(data, 'airline');
  }

  async getFlightsStats (data) {
    const daily = await this.getDailyStats(data);
    const byType = await this.getStatsByAircraftType(data);
    const byAirline = await this.getStatsByAirline(data);

    return {
      daily,
      byType,
      byAirline
    };
  }
}

export default new FlightsService();
