import store from '../store';

class ErrorService {
  handleException (exception) {
    if (exception.response) {
      return this.handleError(exception.response);
    } else {
      store.dispatch('showError', 'Unexpected exception, please contact tech support.');
    }
  }

  handleError (response) {
    if (!response) {
      store.dispatch('showError', 'Unexpected error, please contact tech support.');
      return;
    }

    // invalid token
    if (response.status === 401) {
      return store.dispatch('logout');
    }

    // validation error
    if (response.status === 400 || response.status === 422) {
      return response.data;
    }

    // server error
    if (response.status >= 500) {
      store.dispatch('showError', 'Service unavailable, please try again later');
    } else {
      store.dispatch('showError', response.data.message);
    }
  }
}

export default new ErrorService();
