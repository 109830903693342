import jwtDecode from 'jwt-decode';
import router from '../router';

class AuthService {
  getToken () {
    const token = localStorage.getItem('token');
    if (!token || token === 'null') return null;

    return token;
  }

  setToken (token) {
    localStorage.setItem('token', token);
  }

  getUser () {
    const user = JSON.parse(localStorage.getItem('userData'));
    if (!user || user === 'null') return null;

    return user;
  }

  setUser (user) {
    localStorage.setItem('userData', JSON.stringify(user));
  }

  getUserData (user) {
    const { token, id, name, email } = user;
    const data = jwtDecode(token);
    const permissionsKey = Object.keys(data).find(key => key.includes('authenticationmethod'));
    const permissions = {};

    if (permissionsKey) {
      data[permissionsKey].forEach(key => permissions[key] = true);
    } else {
      router.push('/login');
    }

    return {
      id,
      name,
      email,
      permissions
    };
  }
}

export default new AuthService();
