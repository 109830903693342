<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
    >
      <div class="d-flex flex-wrap justify-space-between align-center">
        <v-toolbar-title>
          {{ heading }}
        </v-toolbar-title>
        <v-btn-toggle
          v-model="isMapView"
          color="primary"
          light
          rounded
          dense
          mandatory
          class="toggle-btn-group"
        >
          <v-btn>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
          <v-btn>
            <v-icon>mdi-map-marker-radius</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-toolbar>

    <v-container
      fluid
      px-0
      py-0
    >
      <v-row no-gutters>
        <v-col>
          <v-card v-if="isMapView">
            <RecentFlightsMap :aircrafts="flights" />
          </v-card>
          <v-card v-if="!isMapView">
            <v-data-table
              :headers="headers"
              :items="flights"
              no-data-text="No flights yet"
              :items-per-page="50"
              :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
            >
              <template #[`item.is_regular`]="{ item }">
                <span class="nowrap">{{ item.is_ferry ? 'f.' : '' }} {{ item.is_regular ? 'regular' : 'charter' }}</span>
              </template>

              <template #[`item.flight_time`]="{ item }">
                <span>{{ item.flight_time || '—' }}</span>
              </template>

              <template #[`item.cr`]="{ item }">
                <v-tooltip
                  v-if="item.cr"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="light-blue"
                      v-bind="attrs"
                      dense
                      class="pa-1"
                      v-on="on"
                    >
                      mdi-bed
                    </v-icon>
                  </template>
                  <div>Trip time is longer than FDP</div>
                </v-tooltip>
              </template>

              <template #[`item.refuel`]="{ item }">
                <v-tooltip
                  v-if="item.refuel"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      :color="item.refuel.reached_reserve ? 'error' : 'light-blue'"
                      v-bind="attrs"
                      dense
                      class="pa-1"
                      v-on="on"
                    >
                      mdi-gas-station
                    </v-icon>
                  </template>
                  <div v-if="!item.refuel.reached_reserve">
                    Needs refuel
                  </div>
                  <div v-if="item.refuel.reached_reserve">
                    Fuel consumption is greater than the fuel reserve
                  </div>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import RecentFlightsMap from './RecentFlightsMap.vue';

  export default {
    name: 'RecentFlightModal',

    components: { RecentFlightsMap },

    props: {
      registration: {
        type: String,
        require: true
      },
      flights: {
        type: Array,
        require: true
      },
    },

    data () {
      return {
        headers: [
          { text: 'Callsign', value: 'callsign', sortable: true, },
          { text: 'Takeoff', value: 'takeoff_time', sortable: true, },
          { text: 'From', value: 'from', sortable: true, },
          { text: 'To', value: 'to', sortable: true, },
          { text: 'Landing', value: 'arrival_time', sortable: true, },
          { text: 'Type', value: 'is_regular', sortable: true, },
        ],
        isMapView: false
      };
    },

    computed: {
      heading () {
        return `Most recent flights of ${this.registration}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .toggle-btn-group {
    position: absolute;
    right: 2em;

    @media (max-width: 600px)  {
      height: 1.5em;
      margin-left: 1em;
      position: relative;
      right: 0;
      .v-btn:not(.v-btn--round).v-size--default {
        height: 1.75em;
      }
    }

    @media (max-width: 500px)  {
      margin-left: 0;
    }
  }

  ::v-deep .v-data-table-header th {
    white-space: nowrap;
  }
</style>
