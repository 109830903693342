<template>
  <div />
</template>
<script>
  import { flightAvailability } from '../constants';

  export default {
    props: {
      google: {
        type: Object,
        required: true
      },
      map: {
        type: Object,
        required: true
      },
      oms: {
        type: Object,
        required: true
      },
      marker: {
        type: Object,
        required: true
      }
    },

    computed: {
      availabilityConstants () {
        return flightAvailability;
      },
    },

    mounted () {
      const markerParams = {
        ...this.marker,
        animation: google.maps.Animation.DROP,
        icon: {
          ...this.marker.icon,
          path: google.maps.SymbolPath.CIRCLE,
        },
      };
      let marker = new google.maps.Marker(markerParams);
      if (this.marker.visible) {
        this.oms.addMarker(marker);
      }

      const lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 2,
      };
      const flightPath = new google.maps.Polyline({
        path: this.marker.flightCoordinates,
        geodesic: true,
        strokeColor: this.availabilityConstants.notAvailable.color,
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: '0',
            repeat: '20px',
          },
        ],
        strokeWeight: 2,
      });
      flightPath.setMap(this.map);

      const infowindow = new google.maps.InfoWindow({
        content: this.marker.title,
      });

      marker.addListener('click', () => {
        this.oms.unspiderfy();
      });

      marker.addListener('mouseover', () => {
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      });

      marker.addListener('mouseout', () => {
        infowindow.close();
      });
    }
  };
</script>
