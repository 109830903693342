<template>
  <GoogleMapLoader
    v-if="!loading"
    :map-config="mapConfig"
    :api-key="apiKey"
  >
    <template slot-scope="{ google, map }">
      <GoogleMapCluster :map="map">
        <template slot-scope="{ oms }">
          <GoogleMapMarker
            v-for="marker in markers"
            :key="marker.registration"
            :marker="marker"
            :google="google"
            :map="map"
            :oms="oms"
          />
        </template>
      </GoogleMapCluster>
    </template>
  </GoogleMapLoader>
</template>

<script>
  import GoogleMapLoader from './GoogleMapLoader';
  import GoogleMapCluster from './GoogleMapCluster';
  import GoogleMapMarker from './GoogleMapMarker';

  export default {
    components: {
      GoogleMapLoader,
      GoogleMapCluster,
      GoogleMapMarker
    },

    props: {
      aircrafts: {
        type: Array,
        require: true
      },
    },

    data () {
      return {
        loading: false,
        markers: [],
        mapSettings: {
          zoomControl: true,
          zoom: 4
        }
      };
    },

    computed: {
      apiKey () {
        return process.env.VUE_APP_GOOGLE_MAPS_KEY;
      },
      mapConfig () {
        return {
          ...this.mapSettings,
          center: this.mapCenter
        };
      },
      mapCenter () {
        return this.markers[0] ? this.markers[0].position : { lat: 48, lng: 19 };
      },
    },

    watch: {
      aircrafts: {
        handler: async function (arr) {
          this.loading = true;
          this.markers = await this.makeMarkers(arr);
          this.loading = false;
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      async makeMarkers (arr) {
        return arr.reduce((markers, aircraft) => {
          if (aircraft.availability &&
            aircraft.availability.lat &&
            aircraft.availability.lng) {
            markers.push({
              registration: aircraft.registration,
              aircraftTypeName: aircraft.aircraftType.name,
              color: aircraft.availability.color,
              icon: {
                fillColor: 'transparent',
                fillOpacity: 1,
                strokeWeight: 0,
                scale: 10
              },
              label: {
                fontFamily: 'Material Icons',
                text: 'room',
                fontSize: '30px',
                color: aircraft.availability.color,
              },
              position: {
                lat: aircraft.availability.lat,
                lng: aircraft.availability.lng
              },
              availability: aircraft.availability,
              lastFlights: aircraft.last_flights
            });
          }
          return markers;
        }, []);
      },
    }
  };
</script>
