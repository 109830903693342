import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","pa-0":""}},[_c(VCard,{staticClass:"mb-4 pa-4",attrs:{"v-if":_vm.stats}},[_c('header',[_c('h1',{staticClass:"card-heading"},[_vm._v(" Summary ")])]),_c(VRow,[_c(VCol,[_vm._v(" Total FLT ")]),_c(VSpacer),_c(VCol,[_vm._v(" "+_vm._s(_vm.stats.totalFlt)+" ")])],1),_c(VRow,[_c(VCol,[_vm._v(" Total BLK ")]),_c(VSpacer),_c(VCol,[_vm._v(" "+_vm._s(_vm.stats.totalBlk)+" ")])],1)],1),_c(VCard,{staticClass:"pa-4"},[_c('header',[_c('h1',{staticClass:"card-heading"},[_vm._v(" Fuel ")])]),_c(VRow,[_c(VCol,[_vm._v(" AVG "),_c('small',[_vm._v("kg/FLTh")])]),_c(VSpacer),_c(VCol,[_vm._v(" "+_vm._s(_vm.stats.avgFlth)+" ")])],1),_c(VRow,[_c(VCol,[_vm._v(" MIN "),_c('small',[_vm._v("kg/FLTh")])]),_c(VSpacer),_c(VCol,[_vm._v(" "+_vm._s(_vm.stats.minFlth)+" ")])],1),_c(VRow,[_c(VCol,[_vm._v(" MAX "),_c('small',[_vm._v("kg/FLTh")])]),_c(VSpacer),_c(VCol,[_vm._v(" "+_vm._s(_vm.stats.maxFlth)+" ")])],1),_c(VDivider,{staticClass:"my-4"}),_c(VRow,[_c(VCol,[_vm._v(" AVG "),_c('small',[_vm._v("kg/BLKh")])]),_c(VSpacer),_c(VCol,[_vm._v(" "+_vm._s(_vm.stats.avgBlkh)+" ")])],1),_c(VRow,[_c(VCol,[_vm._v(" MIN "),_c('small',[_vm._v("kg/BLKh")])]),_c(VSpacer),_c(VCol,[_vm._v(" "+_vm._s(_vm.stats.minBlkh)+" ")])],1),_c(VRow,[_c(VCol,[_vm._v(" MAX "),_c('small',[_vm._v("kg/BLKh")])]),_c(VSpacer),_c(VCol,[_vm._v(" "+_vm._s(_vm.stats.maxBlkh)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }