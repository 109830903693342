<template>
  <v-container
    fluid
    px-0
    py-0
  >
    <v-row no-gutters>
      <v-col v-if="aircrafts">
        <v-card :elevation="0">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              class
              single-line
              hide-details
            />
          </v-card-title>

          <v-data-table
            :search="search"
            :headers="headers"
            :items="aircrafts"
            no-data-text="No aircrafts yet"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
          >
            <template #item.registration="{ item }">
              <router-link
                :to="'/stats/aircrafts/' + item.id"
              >
                {{ item.registration.toUpperCase() }}
              </router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    components: {},

    props: {
      aircrafts: {
        type: Array,
        required: true
      }
    },

    data: () => ({
      search: ''
    }),

    computed: {
      headers: function () {
        const headers = [
          { text: 'Registration', value: 'registration', sortable: true },
          { text: 'Operator', value: 'operator', sortable: true },
          { text: 'ACMI ', value: 'acmi', sortable: true },
          { text: 'FH ', value: 'durationString', sortable: true },
          { text: 'Revenue', value: 'price', class: 'text-no-wrap', sortable: true },
          { text: 'Per flight', value: 'average', sortable: true },
          { text: 'Quotes', value: 'quotes', sortable: true },
          { text: 'Flights', value: 'flights', sortable: true }
        ];

        return headers;
      }
    },

    methods: {}
  };
</script>
