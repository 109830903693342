<template>
  <LineChart
    :chart-data="chartData"
    compare-years
  />
</template>

<script>
  import LineChart from './LineChart';

  export default {
    components: { LineChart },

    props: {
      currentChartData: {
        required: true
      },
      prevChartData: {
        required: true
      },
    },

    data: () => ({
      loading: true,
      chartData: null,
    }),

    mounted: function () {
      let currentChartData = this.currentChartData;
      let prevChartData = this.prevChartData;
      if (currentChartData.datasets[0].data.length > 0 &&
        prevChartData.datasets[0].data.length < currentChartData.datasets[0].data.length) {
        prevChartData.datasets[0].data.pop();
        let diff = currentChartData.datasets[0].data.length - prevChartData.datasets[0].data.length;
        let arr = new Array(diff);
        arr.fill(0);
        prevChartData.datasets[0].data.forEach(el => arr.push(el));
        prevChartData.datasets[0].data = [...arr];
      }

      if (currentChartData.datasets[0].data.length <= 0) {
        currentChartData.labels = [...prevChartData.labels];
      }

      currentChartData.datasets[0].backgroundColor = ['#00c4004d'];
      currentChartData.datasets[0].fill = true;
      currentChartData.datasets[0].borderColor = ['#00c400'];
      prevChartData.datasets[0].backgroundColor = ['#ffff004d'];
      prevChartData.datasets[0].fill = true;
      prevChartData.datasets[0].borderColor = ['#ffff00'];

      currentChartData.datasets = [...currentChartData.datasets, ...prevChartData.datasets.map(el => el)];

      this.chartData = {
        ...currentChartData,
        compareYears: true
      };
    },
  };
</script>
