<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider="-"
        />

        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            {{ aircraft.registration.toUpperCase() }} Flights
          </h1>

          <v-btn
            v-if="!loading"
            type="button"
            color="primary"
            @click="openAircraftModal"
          >
            Edit aircraft
          </v-btn>
        </v-row>

        <v-row class="primary--text mx-3 mb-3">
          <v-icon
            v-if="!refreshLoading"
            color="primary"
            @click="refreshAircraft"
          >
            mdi-refresh
          </v-icon>
          <v-progress-circular
            v-if="refreshLoading"
            size="24"
            indeterminate
            color="primary"
          />
          <span v-if="updatedAt">
            {{ lastUpdated }}
          </span>
        </v-row>

        <v-card>
          <v-card-title class="py-0">
            <v-row>
              <v-col>
                <v-row no-gutters>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      :color="iconColor"
                      @click="toggleFilters()"
                    >
                      mdi-filter
                    </v-icon>
                  </div>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    class
                    single-line
                    hide-details
                  />
                </v-row>
                <v-row>
                  <FlightFilters
                    v-if="isFiltersOpened"
                    :on-filter="toggleFilters"
                    :submit="filterFlights"
                    :prev-filters="prevFilters"
                    :reset-filters="resetFilters"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            v-model="selectedRows"
            :headers="headers"
            :items="aircraft.flights"
            :search="search"
            no-data-text="No flights yet"
            show-select
            checkbox-color="primary"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
            :sort-by.sync="sort.sortBy"
            :sort-desc.sync="sort.sortDesc"
            :loading="isFiltersReset"
          >
            <template #[`item.is_regular`]="{ item }">
              <v-chip
                :color="item.is_regular ? 'default' : 'primary'"
              >
                {{ item.is_ferry ? 'f.' : '' }} {{ item.is_regular ? 'regular' : 'charter' }}
              </v-chip>
            </template>

            <template #[`item.callsign`]="{ item }">
              {{ item.callsign }}
              <v-tooltip
                v-if="item.icao_route"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-map-marker-path
                  </v-icon>
                </template>
                <span>{{ item.icao_route }}</span>
              </v-tooltip>
            </template>

            <template #[`item.editId`]="{ item }">
              <v-icon
                color="primary"
                @click="openFlightModal(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
          <v-row no-gutters>
            <v-col cols="2">
              <v-speed-dial
                v-if="isFabShown"
                v-model="fab"
                class="fab-container"
                direction="top"
                transition="slide-y-reverse-transition"
                fixed
                bottom
                left
                open-on-hover
              >
                <template #activator>
                  <v-btn
                    v-model="fab"
                    color="primary darken-2"
                    dark
                    fab
                  >
                    <v-icon v-if="fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn
                  dark
                  small
                  rounded
                  color="primary"
                  @click="onFlightTypeChange(false)"
                >
                  <span>Charter</span>
                </v-btn>
                <v-btn
                  dark
                  small
                  rounded
                  color="grey"
                  @click="onFlightTypeChange(true)"
                >
                  <span>Regular</span>
                </v-btn>
              </v-speed-dial>
            </v-col>
          </v-row>
        </v-card>

        <v-dialog
          v-model="isAircraftEditModal"
          max-width="600px"
        >
          <AircraftEdit
            :aircraft="aircraft"
            @on-close="closeAircraftModal"
            @on-submit="editAircraft"
          />
        </v-dialog>

        <v-dialog
          v-model="isFlightEditModal"
          max-width="600px"
        >
          <FlightEdit
            :flight="flightToEdit"
            @on-submit="editFlight"
            @on-close="closeFlightModal"
          />
        </v-dialog>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      absolute
    />
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import FlightsService from '../../services/FlightsService';
  import FlightService from '../../services/FlightService';
  import FlightFilters from '../../components/FlightFilters';
  import AircraftEdit from './AircraftEdit';
  import FlightEdit from '../Flights/FlightEdit';

  export default {
    components: {
      AircraftEdit,
      FlightEdit,
      FlightFilters
    },

    data: () => ({
      search: '',
      loading: true,
      refreshLoading: false,
      isFiltersReset: false,
      aircraft: {},
      flightToEdit: {},
      fab: false,
      selectedRows: [],
      prevFilters: {
        is_regular: null,
        status: 1,
      },
      sort: {
        sortBy: 'takeoffTimeParsed',
        sortDesc: true,
      },
      updatedAt: null,
      isFiltersOpened: false,
      isAircraftEditModal: false,
      isFlightEditModal: false,
    }),

    computed: {
      lastUpdated () {
        return DateTime.fromISO(this.updatedAt, { zone: 'UTC' }).toFormat('yyyy-MM-dd HH:mm:ss');
      },

      breadcrumbs () {
        return [
          {
            text: 'Home',
            append: true,
            exact: true,
            to: '/'
          },
          {
            text: 'Aircrafts',
            append: true,
            exact: true,
            to: '/aircrafts'
          },
          {
            text: `${this.$route.params.id.toUpperCase()}`,
            disabled: true
          }
        ];
      },

      headers () {
        return [
          { text: 'Type', value: 'is_regular', sortable: true },
          { text: 'Callsign', value: 'callsign', sortable: true },
          { text: 'Takeoff', value: 'takeoffTimeParsed', sortable: true },
          { text: 'Landing', value: 'arrivalTimeParsed', sortable: true },
          { text: 'From', value: 'from', sortable: true },
          { text: 'To', value: 'to', sortable: true },
          { text: '', value: 'editId', sortable: false }
        ];
      },

      iconColor () {
        return this.isFiltersOpened ? 'primary' : 'secondary';
      },

      isFabShown () {
        return this.selectedRows.length > 0;
      }
    },

    async created () {
      this.aircraft.registration = this.$route.params.id;
      this.prevFilters.registration = this.aircraft.registration;

      const query = await this.parseQueryParams();
      this.filterFlights({
        registration: this.aircraft.registration,
        status: 1,
        ...query
      });
    },

    methods: {
      async refreshAircraft () {
        this.refreshLoading = true;

        try {
          const { data } = await HTTPService.refreshAircraft(this.aircraft.registration);
          if (data) {
            this.updateFlights(data.last_flights);
          }
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.refreshLoading = false;
        }
      },

      updateFlights (flights) {
        const recentFlights = FlightsService.getFlightsList(flights);
        const aircraftFlightsIdsSet = new Set(this.aircraft.flights.map((flight) => flight.id));
        const newFlights = recentFlights.filter((flight) => !aircraftFlightsIdsSet.has(flight.id));

        if (newFlights.length > 0) {
          this.aircraft.flights = [...newFlights, ...this.aircraft.flights];
        }

        this.updatedAt = recentFlights.find(flight => flight.updated_at).updated_at ?? DateTime.utc().toISO();
      },

      async onFlightTypeChange (is_regular) {
        const flightIds = this.selectedRows.map(flight => flight.id);
        const params = {
          is_regular,
          ids: flightIds
        };
        try {
          await HTTPService.batchUpdateFlights(params);
          this.filterFlights(this.prevFilters);
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
      },

      async openAircraftModal () {
        try {
          const { data } = await HTTPService.getAircraft(this.aircraft.registration);
          this.aircraft = { ...this.aircraft, ...data };
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.isAircraftEditModal = true;
        }
      },

      closeAircraftModal () {
        this.isAircraftEditModal = false;
      },

      editAircraft (aircraft) {
        this.isAircraftEditModal = false;
        this.aircraft = { ...this.aircraft, ...aircraft };
      },

      openFlightModal (flight) {
        this.flightToEdit = { ...flight };
        this.isFlightEditModal = true;
      },

      closeFlightModal () {
        this.isFlightEditModal = false;
      },

      editFlight (flight) {
        this.isFlightEditModal = false;
        const result = this.aircraft.flights.map(item => {
          if (item.id === flight.id) {
            return {
              ...item,
              is_regular: flight.is_regular,
              is_ferry: flight.is_ferry,
              status: flight.status,
              departure: flight.departure,
              arrival: flight.arrival,
              departure_airport_id: flight.departure.id,
              arrival_airport_id: flight.arrival.id,
              from: FlightService.getFrom(flight),
              to: FlightService.getTo(flight)
            };
          }
          return item;
        });
        this.aircraft.flights = [...result];
      },

      toggleFilters () {
        this.isFiltersOpened = !this.isFiltersOpened;
      },

      async filterFlights (params) {
        if (params.is_regular === null) {
          this.isFiltersReset = true;
        }

        try {
          const { data } = await HTTPService.getFlights(this.parseParams(params));
          this.aircraft.flights = data.map(flight => FlightService.parseFlight(flight));
          if (data.length) {
            this.updatedAt = this.aircraft.flights.find(flight => flight.updated_at).updated_at ?? DateTime.utc().toISO();
          }
          this.prevFilters = { ...params };
          this.loading = false;
          this.handleQueryParams(params);
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.isFiltersReset = false;
        }
      },

      resetFilters () {
        this.prevFilters = {
          is_regular: null,
          status: 1,
          registration: this.aircraft.registration
        };
        return this.prevFilters;
      },

      handleQueryParams (params) {
        const query = {};

        Object.keys(params).forEach(key => {
          switch (key) {
          case 'arrivalAirports':
          case 'departureAirports':
            query[key] = [];
            params[key].forEach(el => {
              query[key].push(el.icao);
            });
            break;
          case 'status':
          case 'registration':
          case 'arrival_ids':
          case 'departure_ids':
            break;
          default:
            if (params[key] !== null) {
              query[key] = params[key];
            }
            break;
          }
        });
        this.$router.replace({ name: 'aircraft', params: { id: this.aircraft.registration}, query }, () => {});
      },

      async parseQueryParams () {
        const spliceName = airport => airport.iata ? `${airport.icao}/${airport.iata}` : airport.icao;
        const query = {};
        for (const key of Object.keys(this.$route.query)) {
          const value = this.$route.query[key];
          switch (key) {
          case 'is_regular':
            if (value !== null) {
              query[key] = parseInt(value);
            }
            break;
          case 'arrivalAirports':
          case 'departureAirports':
            query[key] = [];
            const icaoArr = Array.isArray(value) ? value : [value];
            for (const el of icaoArr) {
              const { data } = await HTTPService.getAirports({ search: el });
              if (data) {
                const airport = data[0];
                query[key].push({
                  ...airport,
                  codes: spliceName(airport)
                });
              }
            }
            break;
          case 'registration':
          case 'status': break;
          default:
            query[key] = value;
            break;
          }
        }

        return query;
      },

      parseParams (params) {
        return {
          is_regular: params.is_regular,
          status: params.status,
          arrival_ids: params.arrivalAirports ? params.arrivalAirports.map(airport => airport.id) : null,
          departure_ids: params.departureAirports ? params.departureAirports.map(airport => airport.id) : null,
          registration: params.registration,
        };
      }
    }
  };
</script>

<style scoped>
  .fab-container {
    left: 15vh;
  }
</style>
