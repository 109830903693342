import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Edit flight")])],1),_c(VForm,[_c(VCardText,[_c('AirportSearch',{attrs:{"label":"Departure airport","airport":_vm.model.departure,"error-message":_vm.validationErrors.departure_airport_id},on:{"clear-error":function($event){return _vm.clearValidation('departure_airport_id')},"on-submit":_vm.setDeparture}}),_c('AirportSearch',{attrs:{"label":"Arrival airport","airport":_vm.model.arrival,"error-message":_vm.validationErrors.arrival_airport_id},on:{"clear-error":function($event){return _vm.clearValidation('arrival_airport_id')},"on-submit":_vm.setArrival}}),_c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,[_c(VCheckbox,{attrs:{"disabled":_vm.loading,"label":"Is regular"},model:{value:(_vm.model.is_regular),callback:function ($$v) {_vm.$set(_vm.model, "is_regular", $$v)},expression:"model.is_regular"}})],1),_c(VCol,[_c(VCheckbox,{attrs:{"disabled":_vm.loading,"label":"Is ferry"},model:{value:(_vm.model.is_ferry),callback:function ($$v) {_vm.$set(_vm.model, "is_ferry", $$v)},expression:"model.is_ferry"}})],1),_c(VCol,[_c(VCheckbox,{attrs:{"disabled":_vm.loading,"label":"Active"},model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}})],1)],1)],1),_c('div',{staticClass:"loader-container"},[(_vm.loading)?_c(VProgressLinear,{staticClass:"loader",attrs:{"indeterminate":true}}):_vm._e()],1),_c(VCardActions,[_c(VBtn,{attrs:{"type":"button","color":"primary","disabled":_vm.loading},on:{"click":_vm.applyChanges}},[_vm._v(" Save ")]),_c(VBtn,{attrs:{"type":"button","color":"primary","text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v(" Cancel ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }