<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>

    <v-form>
      <v-card-text>
        <v-combobox
          v-if="!aircraft"
          v-model="model.registration"
          no-filter
          :items="items"
          :loading="autocompleteLoading"
          :search-input.sync="search"
          item-text="registration"
          value="registration"
          label="Registration"
          placeholder="Enter at least 3 characters to search"
          :return-object="true"
          :error-messages="validationErrors.registration"
          @change="aircraftAddSelected"
          @input="clearValidation('registration')"
        />

        <v-text-field
          v-if="aircraft"
          name="registration"
          label="Registration"
          :value="model.registration"
          disabled
        />

        <v-autocomplete
          v-model="model.aircraft_type_id"
          :items="aircraftTypes"
          item-text="name"
          item-value="id"
          label="Aircraft type"
          :error-messages="validationErrors.aircraft_type_id"
          @change="aircraftTypeChanged"
          @input="clearValidation('aircraft_type_id')"
        />

        <v-autocomplete
          v-model="model.airline_id"
          :items="airlines"
          item-text="name"
          item-value="id"
          label="Airline"
          :error-messages="validationErrors.airline_id"
          @change="airlineChanged"
          @input="clearValidation('airline_id')"
        />

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="model.yom"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="model.yom"
              label="Year of manufacture"
              prepend-icon="event"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="model.yom"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(model.yom)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-row justify="space-around">
          <v-col cols="6">
            <v-checkbox
              v-model="model.is_cargo"
              :disabled="loading"
              label="Cargo"
              @change="changeCargo('cargo')"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="model.is_pax"
              :disabled="loading"
              label="PAX"
              @change="changeCargo('pax')"
            />
          </v-col>
        </v-row>
        <v-row v-if="model.is_cargo || model.is_pax">
          <v-col>
            <h3
              v-if="model.is_cargo"
              class="primary--text mx-0 my-0 mb-2"
            >
              Cargo
            </h3>
            <h3
              v-if="model.is_pax"
              class="primary--text mx-0 my-0 mb-2"
            >
              Pax
            </h3>
            <v-text-field
              v-if="model.is_cargo"
              v-model="model.payload"
              name="payload"
              label="Payload"
              type="number"
              :error-messages="validationErrors.payload"
              :disabled="loading"
              @input="clearValidation('payload')"
            />
            <v-text-field
              v-if="model.is_cargo"
              v-model="model.volume"
              name="volume"
              label="Volume"
              type="number"
              :error-messages="validationErrors.volume"
              :disabled="loading"
              @input="clearValidation('volume')"
            />
            <v-text-field
              v-if="model.is_pax"
              v-model="model.payload"
              name="payload"
              label="Luggage payload"
              type="number"
              :error-messages="validationErrors.payload"
              :disabled="loading"
              @input="clearValidation('payload')"
            />
            <v-text-field
              v-if="model.is_pax"
              v-model="model.volume"
              name="volume"
              label="Luggage volume"
              type="number"
              :error-messages="validationErrors.volume"
              :disabled="loading"
              @input="clearValidation('volume')"
            />
            <v-text-field
              v-if="model.is_pax"
              v-model="model.seat"
              name="seat"
              label="Seat"
              :error-messages="validationErrors.seat"
              @input="clearValidation('seat')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
            <v-subheader>Links</v-subheader>
            <v-list-item
              v-for="(item, index) in model.links"
              :key="`link-${index}`"
            >
              <v-list-item-action>
                <v-icon>mdi-link-variant</v-icon>
              </v-list-item-action>

              <v-list-item-content class="my-0 py-0">
                <v-list-item-title>
                  <v-text-field
                    v-model="item.url"
                    name="url"
                    label="Url"
                    :error-messages="validationErrors[`links.${index}.url`]"
                    @input="clearValidation(`links.${index}.url`)"
                  />
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  color="error"
                  @click="removeLink(item)"
                >
                  mdi-delete
                </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="addLink()">
              <v-list-item-action>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Add</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="primary--text mx-0 my-0 mb-2">
              General
            </h3>
            <v-text-field
              v-model="model.serial"
              name="serial"
              label="Serial"
              :error-messages="validationErrors.serial"
              @input="clearValidation('serial')"
            />
            <v-text-field
              v-model="model.model"
              name="model"
              label="Model"
              :error-messages="validationErrors.model"
              @input="clearValidation('model')"
            />
            <v-text-field
              v-model="model.hexcode"
              name="hexcode"
              label="Hexcode"
              :error-messages="validationErrors.hexcode"
              @input="clearValidation('hexcode')"
            />
            <v-text-field
              v-model="model.acmi"
              name="acmi"
              label="ACMI"
              type="number"
              :error-messages="validationErrors.acmi"
              @input="clearValidation('acmi')"
            />
            <v-text-field
              v-model="model.mtow"
              name="mtow"
              label="Mtow"
              type="number"
              :error-messages="validationErrors.mtow"
              @input="clearValidation('mtow')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-checkbox
              v-model="model.status"
              :disabled="loading"
              label="Status"
            />
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="model.is_tracked"
              :disabled="loading"
              label="Is tracked"
            />
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="model.is_aog"
              :disabled="loading"
              label="Is AOG"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <div class="loader-container">
        <v-progress-linear
          v-if="loading"
          class="loader"
          :indeterminate="true"
        />
      </div>

      <v-card-actions>
        <v-btn
          type="button"
          color="primary"
          :disabled="loading"
          @click="applyChanges"
        >
          Save
        </v-btn>
        <v-btn
          type="button"
          color="primary"
          text
          :disabled="loading"
          @click="$emit('on-close')"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { DateTime } from 'luxon';
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import { fieldValidation } from '../../mixins/fieldValidation';

  export default {
    mixins: [fieldValidation],

    props: {
      aircraft: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        search: null,
        menu: false,
        aircraftTypes: [],
        airlines: [],
        entries: [],
        loading: false,
        autocompleteLoading: false,
        model: {
          status: null,
          registration: null,
          aircraft_type_id: null,
          airline_id: null,
          yom: null,
          serial: null,
          model: null,
          hexcode: null,
          is_cargo: null,
          is_pax: null,
          links: [],
          acmi: null,
          mtow: null,
          payload: null,
          volume: null,
          seat: null,
          is_tracked: null,
          is_aog: null,
        }
      };
    },

    computed: {
      title () {
        return this.aircraft ? 'Edit aircraft' : 'Add aircraft';
      },

      items () {
        return this.entries
          ? this.entries.map(entry => {
            return {
              registration: entry.registration,
              aircraftType: {
                name: entry.model.text
              },
              airline: {
                icao: entry.airline ? entry.airline.code.icao : '',
              },
              links: [],
              status: true
            };
          }) : [];
      },
    },

    watch: {
      async search (val) {
        if (!val) return;

        if (val.length < 3) {
          this.entries = [];
          return;
        }

        this.autocompleteLoading = true;

        try {
          const { data } = await HTTPService.getAircraftAutocomplete(val);
          this.entries = data;
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.autocompleteLoading = false;
        }
      },

      aircraft: {
        handler: 'setModel',
        immediate: true
      }
    },

    async created () {
      const typeAutocomplete = HTTPService.getAircraftTypes();
      const airlineAutocomplete = HTTPService.getAirlines();

      try {
        const [aircraftTypes, airlines] = await Promise.all([typeAutocomplete, airlineAutocomplete]);
        this.aircraftTypes = aircraftTypes.data;
        this.airlines = airlines.data;
      } catch ({ response }) {
        ErrorService.handleError(response);
      }
    },

    methods: {
      aircraftAddSelected (item) {
        const aircraftType = this.aircraftTypes.find(el => (item.aircraftType && el.name === item.aircraftType.name));
        const airline = this.airlines.find(el => (item.airline && el.icao === item.airline.icao));
        this.model.registration = item.registration ?? this.model.registration;
        this.model.mtow = aircraftType ? aircraftType.mtow : this.model.mtow;
        this.model.seat = aircraftType ? aircraftType.seat : this.model.seat;
        this.model.payload = aircraftType ? aircraftType.payload : this.model.payload;
        this.model.volume = aircraftType ? aircraftType.volume : this.model.volume;
        this.model.luggage_payload = aircraftType ? aircraftType.luggage_payload : this.model.luggage_payload;
        this.model.luggage_volume = aircraftType ? aircraftType.luggage_volume : this.model.luggage_volume;
        this.model.aircraft_type_id = aircraftType ? aircraftType.id : this.model.aircraft_type_id;
        this.model.airline_id = airline ? airline.id : this.model.airline_id;
        this.model.acmi = airline ? airline.acmi : this.model.acmi;
      },

      aircraftTypeChanged (item) {
        const aircraftType = this.aircraftTypes.find(el => (el.id === item));
        this.model.mtow = aircraftType ? aircraftType.mtow : this.model.mtow;
        this.model.seat = aircraftType ? aircraftType.seat : this.model.seat;
        this.model.payload = aircraftType ? aircraftType.payload : this.model.payload;
        this.model.volume = aircraftType ? aircraftType.volume : this.model.volume;
        this.model.luggage_payload = aircraftType ? aircraftType.luggage_payload : this.model.luggage_payload;
        this.model.luggage_volume = aircraftType ? aircraftType.luggage_volume : this.model.luggage_volume;
      },

      airlineChanged (item) {
        const airline = this.airlines.find(el => (el.id === item));
        this.model.acmi = airline && airline.acmi ? airline.acmi : this.model.acmi;
      },

      changeCargo (type) {
        const aircraftType = this.aircraftTypes.find(el => (el.id === this.model.aircraft_type_id));
        if (type == 'cargo') {
          this.model.is_pax = this.model.is_cargo ? false : true;
          this.model.payload = aircraftType ? aircraftType.payload : null;
          this.model.volume = aircraftType ? aircraftType.volume : null;
        } else {
          this.model.is_cargo = this.model.is_pax ? false : true;
          this.model.payload = aircraftType ? aircraftType.luggage_payload : null;
          this.model.volume = aircraftType ? aircraftType.luggage_volume : null;
        }
      },

      addLink () {
        this.model.links.push({});
      },

      removeLink (item) {
        const index = this.model.links.findIndex(el => el === item);
        for (let i = 0; i < this.model.links.length; i++) {
          this.clearValidation(`links.${i}.url`);
        }
        this.model.links.splice(index, 1);
      },

      async applyChanges () {
        this.loading = true;

        const req = this.aircraft ?
          HTTPService.updateAircraft(this.model.registration, this.model) :
          HTTPService.createAircraft(this.model);

        try {
          const { data } = await req;
          const airline = this.airlines.find(el => (el.id === this.model.airline_id));
          const aircraftType = this.aircraftTypes.find(el => (el.id === this.model.aircraft_type_id));
          const result = {
            ...data,
            aircraftType: data.aircraftType || aircraftType || {},
            airline: data.airline || airline || {}
          };
          this.$emit('on-submit', result);
        } catch ({ response }) {
          this.setValidation(ErrorService.handleError(response));
        } finally {
          this.loading = false;
        }
      },

      setModel () {
        if (this.aircraft) {
          const {
            status,
            registration,
            aircraft_type_id,
            airline_id,
            serial,
            model,
            hexcode,
            is_cargo,
            is_pax,
            acmi,
            mtow,
            payload,
            volume,
            seat,
            is_tracked,
            is_aog,
          } = this.aircraft;
          const links = this.aircraft.links || [];
          const yom = this.aircraft.yom ? DateTime.fromISO(this.aircraft.yom).toFormat('yyyy-MM-dd') : null;
          this.model = {
            status,
            registration,
            aircraft_type_id,
            airline_id,
            yom,
            serial,
            model,
            hexcode,
            is_cargo,
            is_pax,
            links,
            acmi,
            mtow,
            payload,
            volume,
            seat,
            is_tracked,
            is_aog,
          };
        }
      }
    }
  };
</script>
