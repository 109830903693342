<template>
  <v-container
    fluid
    px-0
    py-0
  >
    <RecentFlightChip
      :aircraft="aircraft"
      :flights="aircraft.availability?.price ? aircraft.availability.route : aircraft.last_flights"
      :is-quote="!!aircraft.availability?.price"
      v-on="$listeners"
    />
    <span v-if="isCalculated">
      <v-expansion-panels
        v-if="aircraft.availability.price"
        class="dense"
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold">
            {{ formatCurrency(aircraft.availability.price) }} EUR
            <v-tooltip
              v-if="isRefuelNeeded"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  :color="isFuelReserveReached ? 'error' : 'primary'"
                  v-bind="attrs"
                  dense
                  v-on="on"
                >
                  mdi-gas-station
                </v-icon>
              </template>
              <div
                v-for="refuel in aircraft.availability.refuel"
                :key="refuel.departure.id"
              >
                Needs refuel on {{ refuel.departure.iata || refuel.departure.icao }} - {{ refuel.arrival.iata || refuel.arrival.icao }}
              </div>
            </v-tooltip>
            <v-tooltip
              v-if="aircraft.availability.cr"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="light-blue"
                  v-bind="attrs"
                  dense
                  class="pa-1"
                  v-on="on"
                >
                  mdi-bed
                </v-icon>
              </template>
              <div>Trip time is longer than FDP</div>
            </v-tooltip>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              v-for="logItem in aircraft.availability.log"
              :key="logItem.sortOrder"
              class="d-flex justify-space-between align-start pb-1"
              no-gutters
            >
              <v-col
                class="font-weight-medium pr-2 pr-md-0 text-no-wrap"
                cols="4"
                md="3"
              >
                {{ formatCurrency(logItem.price) }} EUR
              </v-col>
              <v-col
                class="text-left"
                cols="7"
                md="8"
              >
                {{ logItem.description }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <span v-if="!aircraft.availability.price">price is unavailable</span>
    </span>
  </v-container>
</template>

<script>
  import RecentFlightChip from './RecentFlightChip';
  import { flightAvailability } from '../constants';

  export default {
    components: { RecentFlightChip },

    props: {
      aircraft: {
        type: Object,
        required: true,
      }
    },

    data: () => ({}),

    computed: {
      flightStatuses () {
        return flightAvailability;
      },

      isCalculated () {
        return this.aircraft.availability && this.aircraft.availability.isCalculated;
      },

      isRefuelNeeded () {
        return this.aircraft.availability && this.aircraft.availability.refuel && this.aircraft.availability.refuel.length;
      },

      isFuelReserveReached () {
        return this.isRefuelNeeded && this.aircraft.availability.refuel.some(refuel => refuel.reached_reserve);
      }
    },

    methods: {
      formatCurrency (number) {
        return Intl.NumberFormat('en-EN', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'code',
          useGrouping: true,
          maximumFractionDigits: 0,
        }).format(number).replace('EUR', '');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .v-expansion-panels.dense {
    .v-expansion-panel {
      background: transparent;

      &::before {
        display: none;
      }

      &--active {
        max-width: initial;
      }

      &-header {
        min-height: auto;
        max-width: fit-content;
        margin: 0 auto;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      &-content {
        padding: 0;
      }
    }
  }
</style>
