import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","px-0":"","py-0":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VCard,[_c(VCardTitle,[_c(VTextField,{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.persons,"no-data-text":"No info yet","items-per-page":50,"footer-props":{ itemsPerPageOptions: [50, 100, 150, -1] }},scopedSlots:_vm._u([{key:`item.ratio`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.ratio))]),(item.ratio)?_c('span',[_vm._v(":1")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }