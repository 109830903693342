<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row no-gutters>
      <v-col>
        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            Dashboard
          </h1>
          <v-divider
            class="mx-2"
            inline
            vertical
          />
          <span class="cardText">Avem Aero</span>

          <v-spacer />

          <VueCtkDateTimePicker
            v-model="date"
            format="YYYY-MM-DD"
            formatted="YYYY-MM-DD"
            :range="true"
            :no-clear-button="true"
            :only-date="true"
          />
        </v-row>
        <v-row>
          <v-tabs
            v-model="activeTab"
            class="mx-6"
          >
            <v-tab
              v-for="tab of tabs"
              :key="tab.id"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="activeTab"
            class="col-12"
          >
            <v-tab-item :value="tabs[0].id">
              <Overall :date="date" />
            </v-tab-item>
            <v-tab-item :value="tabs[1].id">
              <Flights
                :date="date"
                @resetDate="resetDate"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import Overall from './Overall';
  import Flights from './Legs';

  export default {
    components: {
      VueCtkDateTimePicker,
      Overall,
      Flights
    },

    data: () => ({
      date: {
        start: DateTime.now()
          .minus({ years: 1 })
          .toFormat('yyyy-MM-dd'),
        end: DateTime.now().toFormat('yyyy-MM-dd')
      },
      activeTab: 0
    }),

    computed: {
      tabs () {
        return [
          {
            id: 0,
            name: 'Overall'
          },
          {
            id: 1,
            name: 'Flights'
          }
        ];
      }
    },

    mounted () {
      this.date.start = this.$route.query.fromDate || this.date.start;
      this.date.end = this.$route.query.toDate || this.date.end;
      this.handleQueryString();
    },

    methods: {
      resetDate () {
        this.date.start = DateTime.now()
          .minus({ years: 1 })
          .toFormat('yyyy-MM-dd'),
        this.date.end = DateTime.now().toFormat('yyyy-MM-dd');
      },

      handleQueryString () {
        const query = Object.keys(this.$route.query);
        const params = [
          'airline_ids',
          'aircraft_type_ids',
          'registrations',
          'type'
        ];

        if (params.some(el => query.includes(el))) {
          this.activeTab = this.tabs[1].id;
        }
      },
    }
  };
</script>

<style scoped>
  .date-time-picker {
    max-width: 28.5em;
  }

  .cardText {
    font-size: 0.9em;
    font-weight: 400;
    color: #74788d;
  }
</style>
