<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider="-"
        />

        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            Aircrafts
          </h1>

          <v-btn
            type="button"
            color="primary"
            @click="openAddModal"
          >
            Add aircraft
          </v-btn>
        </v-row>
        <v-card>
          <AircraftsList
            :aircrafts="aircrafts"
            :on-edit="openEditModal"
            :on-edit-type="openEditTypeModal"
            :on-edit-airline="openEditAirlineModal"
            :on-delete="openDeleteModal"
            :filter-aircrafts="filterAircrafts"
            :prev-filters="prevFilters"
            :reset-filters="resetFilters"
          />

          <v-dialog
            v-model="isModalOpened"
            max-width="600px"
          >
            <AircraftEdit
              :key="addDialogKey"
              @on-close="closeAddModal"
              @on-submit="addAircraft"
            />
          </v-dialog>

          <v-dialog
            v-model="isEditModalOpened"
            max-width="600px"
          >
            <AircraftEdit
              :aircraft="aircraftToEdit"
              @on-close="closeEditModal"
              @on-submit="editAircraft"
            />
          </v-dialog>

          <v-dialog
            v-model="isEditTypeModalOpened"
            max-width="600px"
          >
            <AircraftTypeEdit
              :on-close="closeEditTypeModal"
              :on-submit="editAircraftType"
              :aircraft-type="aircraftType"
            />
          </v-dialog>

          <v-dialog
            v-model="isEditAirlineModalOpened"
            max-width="600px"
          >
            <AirlineEdit
              :on-close="closeEditAirlineModal"
              :on-submit="editAirline"
              :airline="airline"
            />
          </v-dialog>

          <DeleteDialog
            v-model="isDeleteModalOpened"
            title="aircraft"
            :name="aircraftToDelete"
            :loading="deleting"
            :on-submit="submitDeleteAircraft"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import AircraftsService from '../../services/AircraftsService';
  import AircraftService from '../../services/AircraftService';
  import DeleteDialog from '../../components/DeleteDialog';
  import AircraftEdit from './AircraftEdit';
  import AircraftTypeEdit from '../AircraftTypes/AircraftTypeEdit';
  import AirlineEdit from '../Airlines/AirlineEdit';
  import AircraftsList from './AircraftsList';

  export default {
    components: { DeleteDialog, AircraftEdit, AircraftTypeEdit, AirlineEdit, AircraftsList },

    data: () => ({
      loading: true,
      deleting: false,
      search: '',
      aircrafts: [],
      aircraftType: {},
      airline: {},
      isEditModalOpened: false,
      isEditTypeModalOpened: false,
      isEditAirlineModalOpened: false,
      isModalOpened: false,
      isDeleteModalOpened: false,
      aircraftToEdit: {},
      aircraftToDelete: '',
      addDialogKey: 0,
      prevFilters: {}
    }),

    computed: {
      breadcrumbs: function () {
        return [
          {
            text: 'Home',
            append: true,
            exact: true,
            to: '/'
          },
          {
            text: 'Aircrafts',
            disabled: true
          }
        ];
      },
    },

    created () {
      this.filterAircrafts(this.parseQueryParams(this.$route.query));
    },

    methods: {
      openDeleteModal (registration) {
        this.aircraftToDelete = registration;
        this.isDeleteModalOpened = true;
      },

      async submitDeleteAircraft () {
        this.deleting = true;
        try {
          await HTTPService.deleteAircraft(this.aircraftToDelete);
          this.aircrafts = this.aircrafts.filter(item => item.registration !== this.aircraftToDelete);
          this.isDeleteModalOpened = false;
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.deleting = false;
        }
      },

      openAddModal () {
        this.addDialogKey++;
        this.isModalOpened = true;
      },

      closeAddModal () {
        this.isModalOpened = false;
      },

      async openEditModal (aircraft) {
        if (aircraft) {
          try {
            const { data } = await HTTPService.getAircraft(aircraft.registration);
            this.aircraftToEdit = data;
            this.loading = false;
          } catch ({response}) {
            ErrorService.handleError(response);
          }
        } else {
          this.aircraftToEdit = { ...aircraft };
        }
        this.isEditModalOpened = true;
      },

      closeEditModal () {
        this.isEditModalOpened = false;
      },

      addAircraft (aircraft) {
        this.isModalOpened = false;

        this.aircrafts = [
          ...this.aircrafts,
          {
            registration: aircraft.registration,
            roleIcon: AircraftService.getRoleIcon(aircraft),
            aircraft_type_id: aircraft.aircraft_type_id,
            aircraftType: aircraft.aircraftType,
            airline_id: aircraft.airline_id,
            airline: aircraft.airline,
            status: aircraft.status,
            yom: aircraft.yom,
            serial: aircraft.serial,
            model: aircraft.model,
          }
        ];
      },

      editAircraft (aircraft) {
        this.isEditModalOpened = false;
        const result = this.aircrafts.map(item => {
          if (item.registration === aircraft.registration) {
            return {
              ...item,
              registration: aircraft.registration,
              roleIcon: AircraftService.getRoleIcon(aircraft),
              aircraft_type_id: aircraft.aircraft_type_id,
              aircraftType: {
                id: aircraft.aircraftType.id,
                name: aircraft.aircraftType.name
              },
              airline_id: aircraft.airline_id,
              airline: {
                id: aircraft.airline.id,
                name: aircraft.airline.name,
                iata: aircraft.airline.iata,
                icao: aircraft.airline.icao
              },
              status: aircraft.status,
              yom: aircraft.yom,
              serial: aircraft.serial,
              model: aircraft.model,
            };
          }
          return item;
        });
        this.aircrafts = [...result];
      },

      async openEditTypeModal (aircraftType) {
        try {
          const { data } = await HTTPService.getAircraftType(aircraftType.id);
          this.aircraftType = data;
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
        this.isEditTypeModalOpened = true;
      },

      closeEditTypeModal () {
        this.isEditTypeModalOpened = false;
      },

      editAircraftType (aircraftType) {
        this.isEditTypeModalOpened = false;
        const result = this.aircrafts.map(item => {
          if (item.aircraft_type_id === aircraftType.id) {
            item = {
              ...item,
              aircraftType: {
                id: aircraftType.id,
                name: aircraftType.name
              },
            };
            return item;
          }
          return item;
        });
        this.aircrafts = [...result];
      },

      async openEditAirlineModal (airline) {
        try {
          const { data } = HTTPService.getAirline(airline.id);
          this.airline = data;
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
        this.isEditAirlineModalOpened = true;
      },

      closeEditAirlineModal () {
        this.isEditAirlineModalOpened = false;
      },

      editAirline (airline) {
        this.isEditAirlineModalOpened = false;
        const result = this.aircrafts.map(item => {
          if (item.airline_id === airline.id) {
            item = {
              ...item,
              airline: {
                id: airline.id,
                name: airline.name,
                iata: airline.iata,
                icao: airline.icao
              },
            };
            return item;
          }
          return item;
        });
        this.aircrafts = [...result];
      },

      async filterAircrafts (params) {
        try {
          const { data } = await HTTPService.getAircrafts({ status: 1 });
          const filterParams = {
            ...params,
            is_regular: params.is_regular !== undefined ? params.is_regular : null
          };
          this.aircrafts = this.applyFilters(AircraftsService.getAircraftsList(data), filterParams);
          this.prevFilters = { ...filterParams };
          this.loading = false;

          const query = await this.prepareQueryParams(params);
          this.$router.replace({ name: 'aircrafts', query }, () => {});
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
      },

      applyFilters (aircrafts, params) {
        const filters = [
          AircraftsService.filterByType,
          AircraftsService.filterByAvailability
        ];
        return filters.reduce((aircrafts, filterFn) => aircrafts.filter((el) => filterFn(el, params)), aircrafts);
      },

      resetFilters () {
        this.prevFilters = { status: 1 };
        return this.prevFilters;
      },

      async prepareQueryParams (params) {
        const query = {};

        Object.keys(params).forEach(key => {
          switch (key) {
          case 'is_regular':
            if (params[key] !== null) {
              query[key] = params[key];
            }
            break;
          case 'status':
            break;
          default:
            if (params[key]) {
              query[key] = params[key];
            }
          }
        });
        return query;
      },

      parseQueryParams (query) {
        const params = {
          status: 1
        };

        Object.keys(query).forEach(key => {
          switch (key) {
          case 'is_regular':
            if (query[key] !== null) {
              params[key] = parseInt(query[key]);
            }
            break;
          default:
            if (query[key] === 'true') {
              params[key] = true;
            }
          }
        });
        return params;
      }
    }
  };
</script>
