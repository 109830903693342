<template>
  <Doughnut
    :data="mainData"
    :options="chartOptions"
  />
</template>

<script>
  import { Doughnut } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
  } from 'chart.js';

  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

  export default {
    name: 'DoughnutChart',
    components: { Doughnut },

    props: {
      chartData: {
        type: Object,
        default: null
      }
    },

    computed: {
      mainData () {
        const chartData = this.chartData;
        const chartDataSet = [...this.chartData.datasets[0].data];

        if (this.isEmpty(chartDataSet)) {
          chartData.datasets[0].data = chartDataSet.map(el => {
            el = el == 0 ? -1 : el;
            return el;
          });
        }

        return chartData;
      },

      chartOptions () {
        return {
          maintainAspectRatio: true,
          responsive: true,
          cutoutPercentage: 75,
          plugins: {
            legend: {
              display: true,
              position: 'right'
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  if (this.isEmpty(ctx.dataset.data)) {
                    return `${ctx.label}: 0`;
                  } else {
                    return `${ctx.label}: ${ctx.formattedValue}%`;
                  }
                }
              }
            }
          }
        };
      }
    },

    methods: {
      isEmpty (dataset) {
        return dataset.every(el => el <= 0);
      }
    }
  };
</script>
