import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,{attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"xs":"12","sm":"8","md":"4"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.doLogin.apply(null, arguments)}}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("AVEM AERO Track")])],1),_c(VCardText,[_c(VTextField,{attrs:{"prepend-icon":"person","name":"login","label":"Login","type":"email","error-messages":_vm.validationErrors.email,"outlined":""},on:{"input":function($event){return _vm.clearValidation('email')}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c(VTextField,{attrs:{"id":"password","prepend-icon":"lock","name":"password","label":"Password","type":"password","error-messages":_vm.validationErrors.password,"outlined":""},on:{"input":function($event){return _vm.clearValidation('password')}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"type":"submit","color":"primary"}},[_vm._v(" Login ")])],1)],1)],1)],1)],1)],1),_c(VFooter,{attrs:{"app":""}},[_c(VSpacer),_c('a',{staticClass:"font-weight-light caption mr-4",attrs:{"href":"https://galsd.com/","target":"_blank"}},[_vm._v(" galsd ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }