<template>
  <v-row
    no-gutters
  >
    <v-autocomplete
      v-if="!multiple"
      v-model="model.airport"
      :items="entries"
      :loading="loading"
      :search-input.sync="search"
      item-text="codes"
      item-value="id"
      :label="label"
      placeholder="Enter at least 3 characters to search"
      return-object
      hide-no-data
      clearable
      cache-items
      :error-messages="errorMessage"
      @change="setAirport"
      @input="$emit('clear-error')"
    />
    <v-autocomplete
      v-if="multiple"
      v-model="model.airports"
      :items="entries"
      :loading="loading"
      :search-input.sync="search"
      item-text="codes"
      item-value="id"
      :label="label"
      :chips="multiple"
      :multiple="multiple"
      placeholder="Enter at least 3 characters to search"
      return-object
      hide-no-data
      clearable
      cache-items
      small-chips
      deletable-chips
      :error-messages="errorMessage"
      @change="setAirport"
      @input="$emit('clear-error')"
    />
  </v-row>
</template>

<script>
  import HTTPService from '../services/HTTPService';

  export default {
    props: {
      airport: {
        type: Object
      },
      airports: {
        type: Array
      },
      label: {
        type: String
      },
      multiple: {
        type: Boolean
      },
      errorMessage: {
        type: String
      }
    },

    data: () => ({
      loading: false,
      search: '',
      entries: [],
      model: {},
    }),

    watch: {
      async search (val) {
        if (!val || val.length < 3) {
          this.entries = this.model.airport && this.model.airport.id
            ? [{ ...this.model.airport, codes: this.spliceName(this.model.airport.iata, this.model.airport.icao) }]
            : [];
          return;
        }
        const request = { search: val, };
        this.loading = true;

        try {
          const { data } = await HTTPService.getAirports(request);
          if (data.length > 0) {
            this.entries = data.map(entry => {
              return {
                ...entry,
                codes: this.spliceName(entry.iata, entry.icao),
              };
            });
          }
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.loading = false;
        }
      },
      airport: {
        handler: 'setModel',
        immediate: true
      },
      airports: {
        handler: 'setModel',
        immediate: true
      }
    },

    methods: {
      spliceName (iata, icao) {
        return iata ? `${icao}/${iata}` : icao;
      },

      setModel () {
        this.loading = true;
        if (this.airport && this.airport.id) {
          this.model.airport = { ...this.airport };
        } else if (this.multiple && this.airports) {
          this.model.airports = [...this.airports];
          this.entries = [...this.airports];
        } else if (this.multiple && !this.airports) {
          this.model.airports = [];
        } else {
          this.model.airport = {};
        }
        this.loading = false;
      },

      setAirport (val) {
        this.loading = true;
        if (!val) {
          if (this.multiple) {
            this.model.airports = [];
            this.$emit('on-submit', this.model.airport);
          } else {
            this.model.airport = {};
            this.$emit('on-submit', this.model.airports);
          }
        } else {
          if (this.multiple) {
            this.$emit('on-submit', this.model.airports);
          } else {
            this.model.airport = { ...val };
            this.entries = [{
              ...val,
              codes: this.spliceName(val.iata, val.icao)
            }];
            this.$emit('on-submit', this.model.airport);
          }
        }
        this.loading = false;
      },
    }
  };
</script>
