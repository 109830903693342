<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>

    <v-form>
      <v-card-text>
        <v-text-field
          v-model="model.name"
          name="name"
          label="Model"
          :error-messages="validationErrors.name"
          :disabled="loading"
          @input="clearValidation('name')"
        />

        <v-text-field
          v-model="model.icao"
          name="icao"
          label="ICAO"
          :error-messages="validationErrors.icao"
          :disabled="loading"
          @input="clearValidation('icao')"
        />

        <v-row justify="space-around">
          <v-col cols="4">
            <v-checkbox
              v-model="model.is_cargo"
              :disabled="loading"
              label="Cargo"
            />
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="model.is_pax"
              :disabled="loading"
              label="PAX"
            />
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="model.is_business"
              :disabled="loading"
              label="Business Jet"
            />
          </v-col>
        </v-row>
        <v-row v-if="model.is_cargo">
          <v-col>
            <h3 class="primary--text mx-0 my-0 mb-2">
              Cargo
            </h3>
            <v-text-field
              v-model="model.payload"
              name="payload"
              label="Payload"
              type="number"
              :error-messages="validationErrors.payload"
              :disabled="loading"
              @input="clearValidation('payload')"
            />
            <v-text-field
              v-model="model.volume"
              name="volume"
              label="Volume"
              type="number"
              :error-messages="validationErrors.volume"
              :disabled="loading"
              @input="clearValidation('volume')"
            />
            <v-text-field
              v-if="model.is_cargo"
              v-model="model.attendant"
              name="attendant"
              label="Cargo attendant"
              type="number"
              :error-messages="validationErrors.attendant"
              :disabled="loading"
              @input="clearValidation('attendant')"
            />
          </v-col>
        </v-row>
        <v-row v-if="model.is_cargo">
          <v-col cols="6">
            <v-text-field
              v-model="model.door_height"
              name="door_height"
              label="Door height"
              :error-messages="validationErrors.door_height"
              :disabled="loading"
              @input="clearValidation('door_height')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="model.door_width"
              name="door_width"
              label="Door width"
              :error-messages="validationErrors.door_width"
              :disabled="loading"
              @input="clearValidation('door_width')"
            />
          </v-col>
        </v-row>
        <v-row v-if="model.is_pax || model.is_business">
          <v-col>
            <h3
              v-if="model.is_pax"
              class="primary--text mx-0 my-0 mb-2"
            >
              PAX
            </h3>
            <h3
              v-if="!model.is_pax && model.is_business"
              class="primary--text mx-0 my-0 mb-2"
            >
              Business
            </h3>
            <v-text-field
              v-if="model.is_pax || model.is_business"
              v-model="model.seat"
              name="seat"
              label="Seats capacity"
              type="number"
              :error-messages="validationErrors.seat"
              :disabled="loading"
              @input="clearValidation('seat')"
            />
            <v-text-field
              v-if="model.is_pax"
              v-model="model.luggage_payload"
              name="luggage_payload"
              label="Luggage Payload"
              type="number"
              :error-messages="validationErrors.luggage_payload"
              :disabled="loading"
              @input="clearValidation('luggage_payload')"
            />
            <v-text-field
              v-if="model.is_pax"
              v-model="model.luggage_volume"
              name="luggage_volume"
              label="Luggage Volume"
              type="number"
              :error-messages="validationErrors.luggage_volume"
              :disabled="loading"
              @input="clearValidation('luggage_volume')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="primary--text mx-0 my-0 mb-2">
              General
            </h3>
            <v-text-field
              v-model="model.mtow"
              name="mtow"
              label="MTOW"
              type="number"
              :error-messages="validationErrors.mtow"
              :disabled="loading"
              @input="clearValidation('mtow')"
            />
            <v-select
              v-model="model.engine"
              :items="enginesList"
              item-text="name"
              item-value="value"
              name="engine"
              menu-props="auto"
              label="Engine"
              :disabled="loading"
              :error-messages="validationErrors.engine"
              single-line
              @input="clearValidation('engine')"
            />

            <v-text-field
              v-model="model.runway"
              name="runway"
              label="Runway"
              type="number"
              :error-messages="validationErrors.runway"
              :disabled="loading"
              @input="clearValidation('runway')"
            />

            <v-text-field
              v-model="model.range_loaded"
              name="range_loaded"
              label="Range loaded"
              type="number"
              :error-messages="validationErrors.range_loaded"
              :disabled="loading"
              @input="clearValidation('range_loaded')"
            />

            <v-text-field
              v-model="model.range_ferry"
              name="range_ferry"
              label="Range ferry"
              type="number"
              :error-messages="validationErrors.range_ferry"
              :disabled="loading"
              @input="clearValidation('range_ferry')"
            />

            <v-text-field
              v-model="model.nav"
              name="nav"
              label="Nav"
              :error-messages="validationErrors.nav"
              :disabled="loading"
              @input="clearValidation('nav')"
            />

            <v-text-field
              v-model="model.velocity"
              name="velocity"
              label="Velocity"
              :error-messages="validationErrors.velocity"
              :disabled="loading"
              @input="clearValidation('velocity')"
            />

            <v-text-field
              v-model="model.consumption"
              name="consumption"
              label="Consumption"
              :error-messages="validationErrors.consumption"
              :disabled="loading"
              @input="clearValidation('consumption')"
            />

            <v-text-field
              v-model="model.taxi"
              name="taxi"
              label="Taxi"
              :error-messages="validationErrors.taxi"
              :disabled="loading"
              @input="clearValidation('taxi')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <div class="loader-container">
        <v-progress-linear
          v-if="loading"
          class="loader"
          :indeterminate="true"
        />
      </div>

      <v-card-actions>
        <v-btn
          type="button"
          color="primary"
          :disabled="loading"
          @click="applyChanges"
        >
          Save
        </v-btn>
        <v-btn
          type="button"
          color="primary"
          text
          :disabled="loading"
          @click="onClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import { fieldValidation } from '../../mixins/fieldValidation';
  import { engine } from '../../constants';

  export default {
    mixins: [fieldValidation],

    props: ['aircraftType', 'onClose', 'onSubmit'],

    data () {
      return {
        loading: false,
        model: {},
      };
    },

    computed: {
      title: function () {
        return this.aircraftType ? 'Edit aircraft type' : 'Add aircraft type';
      },

      enginesList: function () {
        return Object.keys(engine).map((key) => {
          return { name: key, value: engine[key] };
        });
      }
    },

    watch: {
      aircraftType: {
        handler: 'setModel',
        immediate: true
      }
    },

    methods: {
      applyChanges () {
        this.loading = true;

        const result = this.aircraftType
          ? HTTPService.updateAircraftType(this.model.id, this.model)
          : HTTPService.createAircraftType(this.model);

        result
          .then(res => {
            this.onSubmit(res.data);
          })
          .catch(({ response }) => {
            this.setValidation(ErrorService.handleError(response));
          })
          .finally(() => {
            this.loading = false;
          });
      },

      setModel () {
        if (this.aircraftType) {
          this.model = { ...this.aircraftType };
        }
      }
    }
  };
</script>
