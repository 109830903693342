import Vue from 'vue';
import Router from 'vue-router';

import PermissionsService from './services/PermissionsService';
import Login from './pages/Auth/Login.vue';
import Layout from './pages/Layout/Layout.vue';
import Dashboard from './pages/Dashboard/Dashboard.vue';
import Aircraft from './pages/Aircrafts/Aircraft.vue';
import Aircrafts from './pages/Aircrafts/Aircrafts.vue';
import AircraftType from './pages/AircraftTypes/AircraftType.vue';
import AircraftTypes from './pages/AircraftTypes/AircraftTypes.vue';
import Flights from './pages/Flights/Flights.vue';
import Airline from './pages/Airlines/Airline.vue';
import Airlines from './pages/Airlines/Airlines.vue';
import Persons from './pages/Airlines/Persons.vue';
import Stats from './pages/Stats/Stats.vue';
import StatsByAircraft from './pages/Stats/StatsByAircraft.vue';
import Rules from './pages/Rules/Rules.vue';
import NotFound from './pages/NotFound/NotFound.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { requiredAuth: false },
    },
    {
      path: '/',
      name: '',
      component: Layout,
      meta: { requiredAuth: true },
      children: [
        {
          path: '',
          component: Dashboard,
          name: 'dashboard',
        },
        {
          path: '/aircrafts',
          component: Aircrafts,
          name: 'aircrafts',
          meta: { permissions: 'ViewAircrafts' },
        },
        {
          path: '/aircrafts/:id',
          component: Aircraft,
          name: 'aircraft',
          meta: { permissions: 'ViewAircrafts' },
        },
        {
          path: '/airlines',
          component: Airlines,
          name: 'airlines',
          meta: { permissions: 'ViewOperators' },
        },
        {
          path: '/airlines/:id',
          component: Airline,
          name: 'airline',
          meta: { permissions: 'ViewOperators' },
        },
        {
          path: '/airlines/:id/persons',
          component: Persons,
          name: 'persons',
          meta: { permissions: 'ViewOperators' },
        },
        {
          path: '/aircraft-types',
          component: AircraftTypes,
          name: 'aircraft-types',
          meta: { permissions: 'EditAircraftTypes' },
        },
        {
          path: '/aircraft-type/:id',
          component: AircraftType,
          name: 'aircraft-type',
          meta: { permissions: 'EditAircraftTypes' },
        },
        {
          path: '/flights',
          component: Flights,
          name: 'flights',
          meta: { permissions: 'ViewFlights' },
        },
        {
          path: '/stats',
          component: Stats,
          name: 'stats',
          meta: { permissions: 'ViewStatistics' },

        },
        {
          path: '/stats/aircrafts/:id',
          component: StatsByAircraft,
          name: 'aircraftStats',
          props: true,
          meta: { permissions: 'ViewStatistics' },
        },
        {
          path: '/rules',
          component: Rules,
          name: 'rules',
          meta: { permissions: 'ViewCompany' },
        },
      ]
    },
    {
      path: '/404',
      component: NotFound,
      meta: { requiredAuth: false },
    },
    {
      path: '*',
      redirect: '/404',
      meta: { requiredAuth: false },
    },
  ]
});

router.beforeEach((to, from, next) => PermissionsService.guard(to, from, next));

export default router;
