<template>
  <v-card-text v-if="!loading">
    <v-row class="py-0 d-flex align-end">
      <v-col cols="2">
        <v-select
          v-model="model.type"
          :items="flightTypeList"
          label="Type of flights"
          item-value="value"
          menu-props="auto"
        >
          <template #selection="{item}">
            {{ item.name }}
          </template>
          <template #item="{item}">
            {{ item.name }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="model.airline_ids"
          :items="airlines"
          item-text="name"
          item-value="id"
          label="Airline"
          chips
          small-chips
          deletable-chips
          multiple
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="model.aircraft_type_ids"
          :items="aircraftTypes"
          item-text="name"
          item-value="id"
          label="Aircraft types"
          chips
          small-chips
          deletable-chips
          multiple
        />
      </v-col>
      <v-col cols="2">
        <v-combobox
          v-model="model.registrations"
          label="Registrations"
          clearable
          multiple
          small-chips
          deletable-chips
        />
      </v-col>
    </v-row>
    <v-card-actions class="pa-0">
      <v-btn
        type="button"
        color="primary"
        @click="onSubmit"
      >
        Apply
      </v-btn>
      <v-btn
        type="button"
        color="primary"
        text
        @click="onClose"
      >
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import { legFlightType } from '../../constants';

  export default {
    props: {
      onFilter: {
        type: Function
      },
      applyFilters: {
        type: Function
      },
      prevFilters: {
        type: Object
      },
      resetFilters: {
        type: Function
      }
    },

    data: () => ({
      loading: false,
      model: {},
      airlines: [],
      aircraftTypes: []
    }),

    computed: {
      flightTypeList: function () {
        return legFlightType;
      }
    },

    created () {
      this.model = { ...this.prevFilters };
      this.getFilters();
    },

    methods: {
      onClose () {
        if (this.resetFilters) {
          this.model = this.resetFilters();
        } else {
          this.model = {};
        }
        this.applyFilters(this.model);
        this.onFilter();
      },

      async getFilters () {
        const promises = [
          HTTPService.getPortalAirlines(),
          HTTPService.getPortalAircraftTypes()
        ];
        const [airlines, aircraftTypes] = await Promise.allSettled(promises);
        const handleResponse = res => {
          if (res.status === 'fulfilled') {
            const { data } = res.value;
            return data;
          } else {
            const { response } = res.reason;
            ErrorService.handleError(response);
          }
        };

        this.airlines = handleResponse(airlines);
        this.aircraftTypes = handleResponse(aircraftTypes);
      },

      onSubmit () {
        this.applyFilters(this.model);
      }
    }
  };
</script>
