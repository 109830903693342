<template>
  <GoogleMapLoader
    v-if="!loading"
    :map-config="mapConfig"
    :api-key="apiKey"
  >
    <template slot-scope="{ google, map }">
      <GoogleMapCluster :map="map">
        <template slot-scope="{ oms }">
          <RecentFlightMarker
            v-for="marker in markers"
            :key="marker.registration"
            :marker="marker"
            :google="google"
            :map="map"
            :oms="oms"
          />
        </template>
      </GoogleMapCluster>
    </template>
  </GoogleMapLoader>
</template>

<script>
  import { flightAvailability } from '../constants';
  import GoogleMapLoader from './GoogleMapLoader';
  import GoogleMapCluster from './GoogleMapCluster';
  import RecentFlightMarker from './RecentFlightMarker';

  export default {
    components: {
      GoogleMapLoader,
      GoogleMapCluster,
      RecentFlightMarker
    },

    props: {
      aircrafts: {
        type: Array,
        require: true
      },
      isQuote: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        loading: false,
        markers: [],
        mapSettings: {
          zoomControl: true,
          zoom: 5
        }
      };
    },

    computed: {
      apiKey () {
        return process.env.VUE_APP_GOOGLE_MAPS_KEY;
      },
      mapConfig () {
        return {
          ...this.mapSettings,
          center: this.mapCenter
        };
      },
      mapCenter () {
        return this.markers[0] ? this.markers[0].position : { lat: 48, lng: 19 };
      },
      availabilityConstants () {
        return flightAvailability;
      }
    },

    watch: {
      aircrafts: {
        handler: async function (arr) {
          this.loading = true;
          this.markers = await this.makeMarkers(arr);
          this.loading = false;
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      async makeMarkers (arr) {
        const markers = [];
        const fillMarker = (flight, index) => {
          const hideDeparture = this.isQuote ? index > 0 && flight.departure.id === markers[markers.length-1].position.airportId : false;
          const arrIcon = this.isQuote && !flight.is_ferry ? 'where_to_vote' : '';
          const flightObj = {
            flightCoordinates: [
              {
                lat: flight.departure.lat,
                lng: flight.departure.lon
              },
              {
                lat: flight.arrival.lat,
                lng: flight.arrival.lon
              },
            ],
            icon: {
              fillColor: 'transparent',
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 10
            },
            label: {
              fontFamily: 'Material Icons',
              text: 'room',
              fontSize: '30px',
            },
          };

          const departure = {
            ...flightObj,
            position: {
              airportId: flight.departure.id,
              lat: flight.departure.lat,
              lng: flight.departure.lon
            },
            label: {
              ...flightObj.label,
              color: '#3872fd',
            },
            title: `${flight.departure.iata}/${flight.departure.icao}`,
            visible: !hideDeparture
          };
          const arrival = {
            ...flightObj,
            position: {
              airportId: flight.arrival.id,
              lat: flight.arrival.lat,
              lng: flight.arrival.lon
            },
            label: {
              ...flightObj.label,
              color: '#3872fd',
              text: arrIcon
            },
            title: `${flight.arrival.iata}/${flight.arrival.icao}`,
            visible: true
          };

          return [
            departure,
            arrival
          ];
        };

        arr.forEach((flight, i) =>  {
          if (flight.departure && flight.arrival) {
            markers.push(...fillMarker(flight, i));
          }
        });

        return markers;
      },
    }
  };
</script>
