import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","px-0":"","py-0":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VCard,[_c(VCardTitle,{staticClass:"py-0"},[_c(VRow,[_c(VCol,[_c(VRow,{attrs:{"no-gutters":""}},[_c('div',{staticClass:"pr-3 align-self-end"},[_c(VIcon,{attrs:{"medium":"","color":_vm.iconColor},on:{"click":function($event){return _vm.toggleFilters()}}},[_vm._v(" mdi-filter ")])],1),_c(VTextField,{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VRow,[(_vm.isFiltersOpened)?_c('AirlineFilters',{attrs:{"on-filter":_vm.toggleFilters,"submit":_vm.filterAirlines,"prev-filters":_vm.prevFilters}}):_vm._e()],1)],1)],1)],1),_c(VDataTable,{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.airlines,"no-data-text":"No airlines yet","items-per-page":50,"footer-props":{ itemsPerPageOptions: [50, 100, 150, -1] }},scopedSlots:_vm._u([{key:`header.status`,fn:function({}){return [_c(VIcon,[_vm._v(" mdi-alphabetical-variant-off ")])]}},{key:`item.name`,fn:function({ item }){return [_c('router-link',{attrs:{"to":'/airlines/' + item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.status`,fn:function({ item }){return [_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:`item.editId`,fn:function({ item }){return [_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:`item.deleteId`,fn:function({ item }){return [_c(VIcon,{attrs:{"color":"error"},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }