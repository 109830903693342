import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","px-3":"","py-3":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VRow,{staticClass:"mx-3 my-3",attrs:{"justify":"space-between","align":"center"}},[_c('h1',{staticClass:"primary--text mx-0 my-0"},[_vm._v(" Dashboard ")]),_c(VDivider,{staticClass:"mx-2",attrs:{"inline":"","vertical":""}}),_c('span',{staticClass:"cardText"},[_vm._v("Avem Aero")]),_c(VSpacer),_c('VueCtkDateTimePicker',{attrs:{"format":"YYYY-MM-DD","formatted":"YYYY-MM-DD","range":true,"no-clear-button":true,"only-date":true},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c(VRow,[_c(VTabs,{staticClass:"mx-6",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.id},[_vm._v(" "+_vm._s(tab.name)+" ")])}),1),_c(VTabsItems,{staticClass:"col-12",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTabItem,{attrs:{"value":_vm.tabs[0].id}},[_c('Overall',{attrs:{"date":_vm.date}})],1),_c(VTabItem,{attrs:{"value":_vm.tabs[1].id}},[_c('Flights',{attrs:{"date":_vm.date},on:{"resetDate":_vm.resetDate}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }