import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","px-0":"","py-0":""}},[_c(VDialog,{attrs:{"max-width":_vm.dialogWidth,"transition":"scale-transition","origin":"center right"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.aircraft.availability)?_c(VChip,_vm._g(_vm._b({class:{
          'white-text': _vm.aircraft.availability.status === _vm.availabilityConstants.notAvailable.status,
          'dense': _vm.isQuote
        },attrs:{"color":_vm.aircraft.availability.color}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.aircraft.availability.value)+" ")]):_vm._e()]}}])},[(!_vm.isQuote)?_c('RecentFlightModal',{attrs:{"registration":_vm.aircraft.registration,"flights":_vm.flights}}):_vm._e(),(_vm.isQuote)?_c('QuoteFlightsModal',_vm._g({attrs:{"registration":_vm.aircraft.registration,"flights":_vm.flights}},_vm.$listeners)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }