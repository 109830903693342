<template>
  <v-card-text v-if="!loading">
    <v-row class="py-0 d-flex align-end">
      <v-col cols="2">
        <v-select
          v-model="model.is_regular"
          :items="flightTypeList"
          label="Type"
          item-value="value"
          menu-props="auto"
        >
          <template #selection="{item}">
            {{ item.name }}
          </template>
          <template #item="{item}">
            {{ item.name }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="5">
        <AirportSearch
          label="Departure airports"
          :airports="model.departureAirports"
          multiple
          @on-submit="setDeparture"
        />
      </v-col>
      <v-col cols="5">
        <AirportSearch
          label="Arrival airports"
          :airports="model.arrivalAirports"
          multiple
          @on-submit="setArrival"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="!isAircraftPage"
      class="py-0 d-flex align-end"
    >
      <v-col cols="4">
        <v-autocomplete
          v-model="model.aircraft_type_ids"
          :items="aircraftTypes"
          item-text="name"
          item-value="id"
          label="Aircraft types"
          chips
          small-chips
          deletable-chips
          multiple
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="model.airline_ids"
          :items="airlines"
          item-text="name"
          item-value="id"
          label="Airlines"
          chips
          small-chips
          deletable-chips
          multiple
        />
      </v-col>
      <v-col cols="4">
        <v-combobox
          v-model="model.registration"
          label="Registrations"
          clearable
          multiple
          small-chips
          deletable-chips
        />
      </v-col>
    </v-row>
    <v-card-actions class="pa-0">
      <v-btn
        type="button"
        color="primary"
        @click="applyFilters"
      >
        Apply
      </v-btn>
      <v-btn
        type="button"
        color="primary"
        text
        @click="onClose"
      >
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
  import ErrorService from '../services/ErrorService';
  import HTTPService from '../services/HTTPService';
  import { flightType } from '../constants';
  import AirportSearch from './AirportSearch';

  export default {
    components: { AirportSearch },

    props: {
      onFilter: {
        type: Function
      },
      submit: {
        type: Function
      },
      prevFilters: {
        type: Object
      },
      resetFilters: {
        type: Function
      }
    },

    data: () => ({
      loading: false,
      model: {},
      aircraftTypes: [],
      airlines: []
    }),

    computed: {
      isAircraftPage: function () {
        return this.$route.params.id && this.prevFilters.registration && this.$route.params.id === this.prevFilters.registration;
      },
      flightTypeList: function () {
        return flightType;
      }
    },

    created: function () {
      this.model = { ...this.prevFilters };

      if (!this.isAircraftPage) {
        let aircraftTypes = HTTPService.getAircraftTypes();
        let airlines = HTTPService.getAirlines();

        this.loading = true;
        Promise.all([aircraftTypes, airlines])
          .then(res => {
            this.aircraftTypes = res[0].data;
            this.airlines = res[1].data;
          })
          .catch(e => {
            ErrorService.handleException(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    methods: {
      onClose () {
        if (this.resetFilters) {
          this.model = this.resetFilters();
        } else {
          this.model = {};
        }
        this.submit(this.model);
        this.onFilter();
      },

      setDeparture (airports) {
        this.model.departureAirports = [...airports];
      },

      setArrival (airports) {
        this.model.arrivalAirports = [...airports];
      },

      applyFilters () {
        this.submit(this.model);
      }
    }
  };
</script>
