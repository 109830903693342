<template>
  <v-container
    fluid
    px-0
    py-0
  >
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              class
              single-line
              hide-details
            />
          </v-card-title>

          <v-data-table
            :search="search"
            :headers="headers"
            :items="stats"
            no-data-text="No info yet"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
          >
            <template #[`item.ratio`]="{ item }">
              <span>{{ item.ratio }}</span><span v-if="item.ratio">:1</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    props: ['stats'],

    data: () => ({
      search: ''
    }),

    computed: {
      headers: function () {
        const headers = [
          { text: 'Company', value: 'company', sortable: true },
          { text: 'Quotes', value: 'quotes', class: 'text-no-wrap', sortable: true },
          { text: 'Flights', value: 'flights', class: 'text-no-wrap', sortable: true },
          { text: 'Ratio', value: 'ratio', class: 'text-no-wrap', sortable: true },
          { text: 'Revenue', value: 'revenue', class: 'text-no-wrap', sortable: true }
        ];

        return headers;
      }
    }
  };
</script>
