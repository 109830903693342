import Vue from 'vue';
import Vuex from 'vuex';
import router from './router';
import AuthService from './services/AuthService';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    token: null,
    userData: {},
    error: null,
  },

  mutations: {
    setToken (state, token) {
      state.token = token;
    },

    setError (state, error) {
      state.error = error;
    },

    updateUser (state, user) {
      state.userData = user;
    }
  },

  actions: {
    async login ({ commit }, user) {
      const { token } = user;
      const userData = AuthService.getUserData(user);
      AuthService.setToken(token);
      AuthService.setUser(userData);

      commit('setToken', token);
      commit('updateUser', userData);
      router.push('/');
    },

    logout ({ commit }) {
      AuthService.setToken(null);
      AuthService.setUser(null);

      commit('setToken', null);
      router.push('/login');
    },

    tryAutoLogin ({ commit }) {
      const token = AuthService.getToken();
      const user = AuthService.getUser();

      if (token) {
        commit('setToken', token);
        commit('updateUser', user);
      }
    },

    showError ({ commit }, error) {
      commit('setError', error);
    },

    hideError ({ commit }) {
      commit('setError', null);
    },
  }
});

store.dispatch('tryAutoLogin');

export default store;
