<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            Flights
          </h1>

          <v-spacer />

          <VueCtkDateTimePicker
            v-model="dates"
            format="YYYY-MM-DD"
            formatted="YYYY-MM-DD"
            :range="true"
            :no-clear-button="true"
            :only-date="true"
          />
        </v-row>
        <v-row no-gutters>
          <FlightsList
            v-if="!loading"
            :flights="flights"
            :on-edit="openEditModal"
            :filter-flights="filterFlights"
            :prev-filters="prevFilters"
            :reset-filters="resetFilters"
            @on-type-change="onTypeChange"
          />
          <v-dialog
            v-model="isEditModalOpened"
            max-width="600px"
          >
            <FlightEdit
              v-if="isEditModalOpened"
              :flight="flightToEdit"
              @on-close="closeEditModal"
              @on-submit="editFlight"
            />
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      absolute
    />
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import FlightService from '../../services/FlightService';
  import FlightEdit from './FlightEdit';
  import FlightsList from './FlightsList';

  export default {
    components: {
      VueCtkDateTimePicker,
      FlightEdit,
      FlightsList
    },

    data: () => ({
      dates: {
        start: DateTime.now().minus({ days: 6 }).toFormat('yyyy-MM-dd'),
        end: DateTime.now().toFormat('yyyy-MM-dd')
      },
      loading: true,
      deleting: false,
      search: '',
      flights: [],
      isEditModalOpened: false,
      flightToEdit: {},
      prevFilters: {
        is_regular: null,
        status: 1
      }
    }),

    computed: {
      breadcrumbs: function () {
        return [
          {
            text: 'Home',
            append: true,
            exact: true,
            to: '/'
          },
          {
            text: 'Flights',
            disabled: true
          }
        ];
      },
    },

    watch: {
      dates: {
        handler: function () {
          if (!this.dates.end) return;
          return this.filterFlights(this.prevFilters);
        }
      }
    },

    created () {
      this.parseQueryParams();
    },

    methods: {
      openEditModal (flight) {
        this.flightToEdit = { ...flight };
        this.isEditModalOpened = true;
      },

      closeEditModal () {
        this.isEditModalOpened = false;
      },

      editFlight (flight) {
        this.isEditModalOpened = false;
        let result = this.flights.map(item => {
          if (item.id === flight.id) {
            return {
              ...item,
              is_regular: flight.is_regular,
              is_ferry: flight.is_ferry,
              status: flight.status,
              departure: flight.departure,
              arrival: flight.arrival,
              departure_airport_id: flight.departure.id,
              arrival_airport_id: flight.arrival.id,
              from: FlightService.getFrom(flight),
              to: FlightService.getTo(flight)
            };
          }
          return item;
        });
        this.flights = [...result];
      },

      async filterFlights (params) {
        const requestParams = await this.applyFilters(params);
        this.handleQueryParams(requestParams);
      },

      async applyFilters (params) {
        try {
          const requestParams = this.parseParams(params);
          const { data } = await HTTPService.getFlights(requestParams);

          this.prevFilters = { ...params };
          this.flights = data.map(flight => FlightService.parseFlight(flight));
          return {
            ...requestParams,
            arrivalAirports: params.arrivalAirports || null,
            departureAirports: params.departureAirports || null
          };
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.loading = false;
        }
      },

      resetFilters () {
        this.prevFilters = {
          is_regular: null,
          status: 1,
          departure_ids: [],
          arrival_ids: [],
          arrivalAirports: [],
          departureAirports: [],
          aircraft_type_ids: [],
          airline_ids: [],
          registration: []
        };
        return this.prevFilters;
      },

      handleQueryParams (params) {
        const query = {};

        for (const key of Object.keys(params)) {
          if (params[key] === null) continue;

          switch (key) {
          case 'fromDate':
          case 'toDate':
            query[key] = DateTime.fromFormat(params[key], 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd');
            break;
          case 'arrivalAirports':
          case 'departureAirports':
            query[key] = [];
            params[key].forEach(el => {
              query[key].push(el.icao);
            });
            break;
          case 'status':
          case 'arrival_ids':
          case 'departure_ids':
            break;
          default:
            if (params[key] !== null) {
              query[key] = params[key];
            }
            break;
          }
        }
        this.$router.replace({ name: 'flights', query }, () => {});
      },

      async parseQueryParams () {
        const spliceName = airport => airport.iata ? `${airport.icao}/${airport.iata}` : airport.icao;
        const query = {
          is_regular: null,
          status: 1
        };

        for (const key of Object.keys(this.$route.query)) {
          const value = this.$route.query[key];
          switch (key) {
          case 'is_regular':
            if (value !== null) {
              query[key] = parseInt(value);
            }
            break;
          case 'fromDate':
            this.dates.start = DateTime.fromISO(value).startOf('day').toISO();
            break;
          case 'toDate':
            this.dates.end = DateTime.fromISO(value).endOf('day').toISO();
            break;
          case 'arrivalAirports':
          case 'departureAirports':
            query[key] = [];
            const icaoArr = Array.isArray(value) ? value : [value];
            for (const el of icaoArr) {
              const { data } = await HTTPService.getAirports({ search: el });
              if (data) {
                const airport = data[0];
                query[key].push({
                  ...airport,
                  codes: spliceName(airport)
                });
              }
            }
            break;
          case 'aircraft_type_ids':
            query[key] = Array.isArray(value) ? value.map(id => parseInt(id)) : [parseInt(value)];
            break;
          case 'airline_ids':
            query[key] = Array.isArray(value) ? value.map(id => parseInt(id)) : [parseInt(value)];
            break;
          case 'registration':
            query[key] = Array.isArray(value) ? [...value] : [value];
            break;
          case 'status': break;
          default:
            query[key] = value;
            break;
          }
        }
        await this.applyFilters(query);
      },

      parseParams (params) {
        return {
          is_regular: params.is_regular,
          status: params.status,
          arrival_ids: params.arrivalAirports ? params.arrivalAirports.map(airport => airport.id) : null,
          departure_ids: params.departureAirports ? params.departureAirports.map(airport => airport.id) : null,
          aircraft_type_ids: params.aircraft_type_ids ? [...params.aircraft_type_ids] : null,
          airline_ids: params.airline_ids ? [...params.airline_ids] : null,
          registration: params.registration ? [...params.registration] : null,
          fromDate: DateTime.fromISO(this.dates.start).startOf('day').toFormat('yyyy-MM-dd HH:mm:ss'),
          toDate: DateTime.fromISO(this.dates.end).endOf('day').toFormat('yyyy-MM-dd HH:mm:ss')
        };
      },

      async onTypeChange (data) {
        const flightIds = data.flights.map(flight => flight.id);
        const params = {
          is_regular: data.is_regular,
          ids: flightIds
        };

        try {
          await HTTPService.batchUpdateFlights(params);
          this.filterFlights(this.prevFilters);
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
      },
    }
  };
</script>
<style scoped>
  .date-time-picker {
    max-width: 28.5em;
  }
</style>
