<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider="-"
        />

        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            {{ aircraftType.name }} aircrafts
          </h1>

          <v-btn
            v-if="!loading"
            type="button"
            color="primary"
            @click="openModal"
          >
            Edit aircraft type
          </v-btn>
        </v-row>

        <v-card>
          <v-card-title class="py-0">
            <v-row>
              <v-col>
                <v-row no-gutters>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      color="secondary"
                      @click="toggleMap()"
                    >
                      {{ isMapShown ? 'mdi-format-list-bulleted' : 'mdi-map-marker-radius' }}
                    </v-icon>
                  </div>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      :color="filtersIconColor"
                      @click="toggleFilters()"
                    >
                      mdi-filter
                    </v-icon>
                  </div>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    class
                    single-line
                    hide-details
                  />
                </v-row>
                <v-row>
                  <AircraftFilters
                    v-if="isFiltersOpened"
                    :prev-filters="prevFilters"
                    :reset-filters="resetFilters"
                    @toggle-filters="toggleFilters"
                    @on-filter="filterAircrafts"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <FlightsMap 
            v-if="isMapShown"
            :aircrafts="aircrafts"
          />

          <v-data-table
            v-if="!isMapShown"
            :loading="requestLoading"
            :headers="headers"
            :items="aircrafts"
            :search="search"
            no-data-text="No flights yet"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
            sort-by="registration"
            :sort-desc="true"
          >
            <template #[`header.roleIcon`]="{}">
              <v-icon>
                mdi-truck-trailer
              </v-icon>
            </template>

            <template #[`item.registration`]="{ item }">
              <router-link :to="'/aircrafts/' + item.registration">
                {{ item.registration.toUpperCase() }}
              </router-link>
            </template>

            <template #[`item.roleIcon`]="{ item }">
              <v-icon
                v-if="item.roleIcon"
                color="primary"
              >
                {{ item.roleIcon }}
              </v-icon>
            </template>

            <template #[`item.airline.name`]="{ item }">
              <router-link :to="'/airlines/' + item.airline.id">
                {{ item.airline.name }}
              </router-link>
            </template>

            <template #[`item.availability.value`]="{ item }">
              <RecentFlightChip
                v-if="item.availability"
                :aircraft="item"
                :flights="item.last_flights"
              />
            </template>

            <template #[`item.refreshId`]="{ item }">
              <v-icon
                v-if="item.registration !== refreshId"
                color="success"
                @click="refreshAircraft(item.registration)"
              >
                mdi-refresh
              </v-icon>

              <v-progress-circular
                v-if="item.registration === refreshId"
                size="24"
                indeterminate
                color="success"
              />
            </template>
          </v-data-table>
        </v-card>

        <v-dialog
          v-model="isModalOpened"
          max-width="600px"
        >
          <AircraftTypeEdit
            :on-close="closeModal"
            :on-submit="editAircraftType"
            :aircraft-type="aircraftType"
          />
        </v-dialog>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      absolute
    />
  </v-container>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import AircraftTypeEdit from './AircraftTypeEdit';
  import AircraftsService from '../../services/AircraftsService';
  import AircraftFilters from '../../components/AircraftFilters';
  import RecentFlightChip from '../../components/RecentFlightChip';
  import FlightsMap from '../../components/FlightsMap';

  export default {
    components: {
      AircraftTypeEdit,
      AircraftFilters,
      RecentFlightChip,
      FlightsMap
    },

    data: () => ({
      search: '',
      loading: true,
      requestLoading: false,
      aircraftType: {},
      aircrafts: [],
      isModalOpened: false,
      refreshId: '',
      isMapShown: false,
      isFiltersOpened: false,
      prevFilters: {}
    }),

    computed: {
      breadcrumbs () {
        return [
          {
            text: 'Home',
            append: true,
            exact: true,
            to: '/'
          },
          {
            text: 'Aircraft Types',
            append: true,
            exact: true,
            to: '/aircraft-types'
          },
          {
            text: `${this.aircraftType.name.toUpperCase()}`,
            disabled: true
          }
        ];
      },

      headers () {
        return [
          { text: 'Registration', value: 'registration', sortable: true },
          { text: '', value: 'roleIcon', sortable: true },
          { text: 'Airline', value: 'airline.name', sortable: true },
          { text: 'IATA', value: 'airline.iata', sortable: true },
          { text: 'ICAO', value: 'airline.icao', sortable: true },
          { text: 'Availability', value: 'availability.value', sortable: true, align: 'center' },
          { text: '', value: 'refreshId', sortable: false }
        ];
      },

      filtersIconColor () {
        return this.isFiltersOpened ? 'primary' : 'secondary';
      }
    },

    async created () {
      const aircraftTypeId = this.$route.params.id;

      try {
        this.aircraftType = await this.getAircraftType(aircraftTypeId);
        await this.filterAircrafts(this.parseQueryParams(this.$route.query));
      } catch ({ response }) {
        ErrorService.handleError(response);
      } finally {
        this.loading = false;
      }
    },

    methods: {
      openModal () {
        this.isModalOpened = true;
      },

      closeModal () {
        this.isModalOpened = false;
      },

      async editAircraftType (aircraftType) {
        this.isModalOpened = false;
        this.aircraftType = { ...aircraftType };
        await this.filterAircrafts(this.parseQueryParams(this.$route.query));
      },

      async refreshAircraft (registration) {
        this.refreshId = registration;

        try {
          const { data } = await HTTPService.refreshAircraft(registration);
          if (data) {
            this.aircrafts = AircraftsService.update(this.aircrafts, data);
          }
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.refreshId = '';
        }
      },

      toggleFilters () {
        this.isFiltersOpened = !this.isFiltersOpened;
      },

      toggleMap () {
        this.isMapShown = !this.isMapShown;
      },

      async getAircrafts (params) {
        this.requestLoading = true;

        try {
          const { data } = await HTTPService.getAircrafts({
            ...params,
            aircraft_type_id: this.aircraftType.id
          });
          return data;
        } catch ({ response }) {
          ErrorService.handleException(response);
        } finally {
          this.requestLoading = false;
        }
      },

      async getAircraftType (id) {
        this.requestLoading = true;

        try {
          const { data } = await HTTPService.getAircraftType(id);
          return data;
        } catch ({ response }) {
          ErrorService.handleException(response);
        } finally {
          this.requestLoading = false;
        }
      },

      async filterAircrafts (params) {
        const aircrafts = AircraftsService.getAircraftsList(await this.getAircrafts(params));
        if (!params || Object.values(params).every(value => value === null || value === undefined)) {
          this.aircrafts = [...aircrafts];
          this.$router.replace({ name: 'aircraft-type', params: { id: this.aircraftType.id } }, () => {});
          return;
        }

        const filters = [
          AircraftsService.filterByType,
          AircraftsService.filterByAvailability
        ];
        const filteredData = filters.reduce((aircrafts, filterFn) => aircrafts.filter((el) => filterFn(el, params)), aircrafts);
        this.aircrafts = [...filteredData];
        this.prevFilters = { ...params };

        const query = {};
        Object.keys(params).forEach(key => {
          if (Number.isInteger(params[key]) || params[key]) {
            query[key] = params[key];
          }
        });
        this.$router.replace({ name: 'aircraft-type', params: { id: this.aircraftType.id }, query }, () => {});
      },

      resetFilters () {
        this.prevFilters = {
          is_regular: null
        };

        this.$router.replace({ name: 'aircraft-type', params: { id: this.aircraftType.id } }, () => {});

        return this.prevFilters;
      },

      parseQueryParams (query) {
        const params = {};

        Object.keys(query).forEach(key => {
          switch (key) {
          case 'is_regular':
            if (query[key] !== null) {
              params[key] = parseInt(query[key]);
            }
            break;
          default:
            if (query[key] === 'true') {
              params[key] = true;
            }
          }
        });
        return params;
      }
    }
  };
</script>

<style scoped>
  .v-data-table-header .v-icon {
    color: inherit;
  }
</style>
