<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-card
          v-if="dailyChartData"
          class="card chart-card"
        >
          <header class="card-header">
            <h1 class="card-heading d-flex justify-start align-start">
              Flights stats
            </h1>
          </header>
          <LineChart
            v-if="!loading"
            :chart-data="dailyChartData"
            :custom-legend="dailyChartLegendConfig"
            class="line-chart"
          />
        </v-card>
        <v-card class="charts">
          <header class="card-header">
            <h1 class="card-heading chart-card-heading">
              Stats by airline
            </h1>
          </header>
          <v-col class="card chart-card">
            <v-card
              v-if="doughnutData.byAirline.cargoCharter"
              class="pr-0 pl-6 py-0"
              :elevation="0"
            >
              <v-subheader class="pl-0">
                Charter cargo
              </v-subheader>
              <DoughnutChart
                v-if="!loading"
                :chart-data="doughnutData.byAirline.cargoCharter"
                class="chartByAirline"
                style="position: relative;"
              />
            </v-card>
            <v-card
              v-if="doughnutData.byAirline.paxCharter"
              class="pr-0 pl-6 px-md-0 py-0"
              :elevation="0"
            >
              <v-subheader class="pl-0">
                Charter PAX
              </v-subheader>
              <DoughnutChart
                v-if="!loading"
                :chart-data="doughnutData.byAirline.paxCharter"
                class="chartByAirline"
                style="position: relative;"
              />
            </v-card>
            <v-card
              v-if="doughnutData.byAirline.cargoRegular"
              class="pr-0 pl-6 py-0"
              :elevation="0"
            >
              <v-subheader class="pl-0">
                Regular cargo
              </v-subheader>
              <DoughnutChart
                v-if="!loading"
                :chart-data="doughnutData.byAirline.cargoRegular"
                class="chartByAirline"
                style="position: relative;"
              />
            </v-card>
            <v-card
              v-if="doughnutData.byAirline.paxRegular"
              class="pr-0 pl-6 px-md-0 py-0"
              :elevation="0"
            >
              <v-subheader class="pl-0">
                Regular PAX
              </v-subheader>
              <DoughnutChart
                v-if="!loading"
                :chart-data="doughnutData.byAirline.paxRegular"
                class="chartByAirline"
                style="position: relative;"
              />
            </v-card>
          </v-col>
        </v-card>
        <v-card class="charts">
          <header class="card-header">
            <h1 class="card-heading chart-card-heading">
              Stats by aircraft type
            </h1>
          </header>
          <v-col class="card chart-card">
            <v-card
              v-if="doughnutData.byAircraftType.cargoCharter"
              class="pr-0 pl-6 py-0"
              :elevation="0"
            >
              <v-subheader class="pl-0">
                Charter cargo
              </v-subheader>
              <DoughnutChart
                v-if="!loading"
                :chart-data="doughnutData.byAircraftType.cargoCharter"
                class="chartByAircraftType"
                style="position: relative;"
              />
            </v-card>
            <v-card
              v-if="doughnutData.byAircraftType.paxCharter"
              class="pr-0 pl-6 px-md-0 py-0"
              :elevation="0"
            >
              <v-subheader class="pl-0">
                Charter PAX
              </v-subheader>
              <DoughnutChart
                v-if="!loading"
                :chart-data="doughnutData.byAircraftType.paxCharter"
                class="chartByAircraftType"
                style="position: relative;"
              />
            </v-card>
            <v-card
              v-if="doughnutData.byAircraftType.cargoRegular"
              class="pr-0 pl-6 py-0"
              :elevation="0"
            >
              <v-subheader class="pl-0">
                Regular cargo
              </v-subheader>
              <DoughnutChart
                v-if="!loading"
                :chart-data="doughnutData.byAircraftType.cargoRegular"
                class="chartByAircraftType"
                style="position: relative;"
              />
            </v-card>
            <v-card
              v-if="doughnutData.byAircraftType.paxRegular"
              class="pr-0 pl-6 px-md-0 py-0"
              :elevation="0"
            >
              <v-subheader class="pl-0">
                Regular PAX
              </v-subheader>
              <DoughnutChart
                v-if="!loading"
                :chart-data="doughnutData.byAircraftType.paxRegular"
                class="chartByAircraftType"
                style="position: relative;"
              />
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      absolute
    />
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import ErrorService from '../../services/ErrorService';
  import FlightsService from '../../services/FlightsService';
  import LineChart from '../Stats/LineChart';
  import DoughnutChart from '../Stats/DoughnutChart';

  export default {
    components: {
      LineChart,
      DoughnutChart
    },

    props: {
      flights: {
        type: Array,
        required: true
      }
    },

    data: () => ({
      loading: true,
      dailyChartLegendConfig: {
        display: true,
        position: 'right'
      },
      dailyChartData: {},
      doughnutData: {
        byAirline: {},
        byAircraftType: {},
      },
    }),

    watch: {
      flights: {
        handler: 'getStats',
        immediate: true
      }
    },

    methods: {
      getStats () {
        this.loading = true;
        FlightsService.getFlightsStats(this.flights)
          .then(stats => {
            this.dailyChartData = this.prepareChartData(this.parseDailyData(stats.daily), true);
            this.doughnutData.byAirline = this.prepareChartData(this.parseData(stats.byAirline));
            this.doughnutData.byAircraftType = this.prepareChartData(this.parseData(stats.byType));
            this.loading = false;
          })
          .catch(e => {
            ErrorService.handleException(e);
          });
      },

      parseDailyData (stats) {
        const cargoRegular = [];
        const cargoCharter = [];
        const paxRegular = [];
        const paxCharter = [];
        const test = [];
        const labels = [];

        Object.keys(stats).sort(
          (a, b) => DateTime.fromISO(a).diff(DateTime.fromISO(b)).toMillis(),
        ).forEach(key => {
          const el = stats[key];

          cargoRegular.push(el.cargoRegular);
          cargoCharter.push(el.cargoCharter);
          paxRegular.push(el.paxRegular);
          paxCharter.push(el.paxCharter);
          test.push(el.test);
          labels.push(key);
        });

        return {
          set: {
            cargoRegular,
            cargoCharter,
            paxRegular,
            paxCharter,
            test
          },
          labels
        };
      },

      parseData (stats) {
        const data = {
          cargoRegular: [],
          cargoCharter: [],
          paxRegular: [],
          paxCharter: [],
        };
        const labels = {
          cargoRegular: [],
          cargoCharter: [],
          paxRegular: [],
          paxCharter: [],
        };

        Object.keys(stats).forEach(key => {
          const el = stats[key];

          Object.keys(data).forEach(group => {
            if (el[group]) {
              if (labels[group].length < 10) {
                data[group].push(el[group]);
                labels[group].push(key);
              } else if (labels[group].length === 10) {
                data[group].push(el[group]);
                labels[group].push('Others');
              } else {
                data[group][data[group].length - 1] += el[group];
              }
            }
          });
        });

        return {
          set: data,
          labels
        };
      },

      prepareChartData (data, isLine) {
        if (isLine) {
          return {
            datasets: this.getChartData(data.set),
            labels: data.labels
          };
        } else {
          return this.getDoughnutChartData(data);
        }
      },

      getLabel (key) {
        switch (key) {
        case 'cargoRegular':
          return 'Regular cargo';
        case 'cargoCharter':
          return 'Charter cargo';
        case 'paxRegular':
          return 'Regular PAX';
        case 'paxCharter':
          return 'Charter PAX';
        case 'test':
          return 'Test flight';
        default:
          return null;
        }
      },

      getChartData (data) {
        const colors = [
          '#69c918',
          '#4052f9',
          '#c708fc',
          '#ff882a',
          '#cccccc'
        ];
        return Object.keys(data).map((key, index) => {
          const label = this.getLabel(key);

          return {
            data: data[key],
            backgroundColor: [colors[index] + '90'],
            borderColor: [colors[index]],
            fill: true,
            lineTension: 0.1,
            label
          };
        });
      },

      getDoughnutChartData (data) {
        const obj = {};

        Object.keys(data.set).map(key => {
          const values = data.set[key];
          const labels = data.labels[key];

          if (!values.length) return null;
          const total = values.reduce((acc = 0, val) => acc + val);
          const result = values.map(num => Math.round(num / total * 100));

          obj[key] = {
            datasets: [
              {
                data: result,
                backgroundColor: [
                  '#c2a0df',
                  '#63a1d9',
                  '#2f3ec9',
                  '#ec9387',
                  '#1e7719',
                  '#9381ff',
                  '#cd2d21',
                  '#f46036',
                  '#9ac4f8',
                  '#2e294e',
                  '#a790a5'
                ],
                borderColor: ['#ffb822']
              }
            ],
            labels
          };
        });

        return obj;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    margin: 1em 0;
    padding: 0.5em 1em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

  }

  .chart-card {
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;

    .line-chart {
      position: relative;
      height: 180px;
      width: 100%;
    }
  }

  .card-header {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-flow: column nowrap;
    align-self: flex-start;
    text-align: left;

    .card-heading {
      margin: 0;
      font-size: 1em;
      font-weight: 400;
      text-align: left;
    }

    .chart-card-heading {
      color: #48465b;
    }
  }

  .charts {
    margin: 1em 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .chart-card {
      padding: 0;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-around;
      -webkit-box-shadow: none;
      box-shadow: none;

      .v-card {
        flex: 0 1 40%;
      }
    }
  }
</style>
