import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","px-3":"","py-3":""}},[(!_vm.loading)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":"-"}}),_c(VRow,{staticClass:"mx-3 my-3",attrs:{"justify":"space-between","align":"center"}},[_c('h1',{staticClass:"primary--text mx-0 my-0"},[_vm._v(" Airlines ")]),_c(VBtn,{attrs:{"type":"button","color":"primary"},on:{"click":_vm.openAddModal}},[_vm._v(" Add airline ")])],1),_c(VCard,[_c('AirlinesList',{attrs:{"airlines":_vm.airlines,"on-edit":_vm.openEditModal,"on-delete":_vm.openDeleteModal,"filter-airlines":_vm.filterAirlines,"prev-filters":_vm.prevFilters}}),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.isModalOpened),callback:function ($$v) {_vm.isModalOpened=$$v},expression:"isModalOpened"}},[_c('AirlineEdit',{key:_vm.addDialogKey,attrs:{"on-close":_vm.closeAddModal,"on-submit":_vm.addAirline}})],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.isEditModalOpened),callback:function ($$v) {_vm.isEditModalOpened=$$v},expression:"isEditModalOpened"}},[_c('AirlineEdit',{attrs:{"on-close":_vm.closeEditModal,"on-submit":_vm.editAirline,"airline":_vm.airlineToEdit}})],1),_c('DeleteDialog',{attrs:{"title":"airline","name":_vm.airlineToDelete.name,"loading":_vm.deleting,"on-submit":_vm.submitDeleteAirline},model:{value:(_vm.isDeleteModalOpened),callback:function ($$v) {_vm.isDeleteModalOpened=$$v},expression:"isDeleteModalOpened"}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }