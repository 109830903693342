import { render, staticRenderFns } from "./Legs.vue?vue&type=template&id=07be7fd1&scoped=true&"
import script from "./Legs.vue?vue&type=script&lang=js&"
export * from "./Legs.vue?vue&type=script&lang=js&"
import style0 from "./Legs.vue?vue&type=style&index=0&id=07be7fd1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07be7fd1",
  null
  
)

export default component.exports