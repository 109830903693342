<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>

    <v-form>
      <v-card-text>
        <v-text-field
          v-model="model.name"
          name="name"
          label="Name"
          :error-messages="validationErrors.name"
          :disabled="loading"
          @input="clearValidation('name')"
        />

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="model.iata"
              name="iata"
              label="IATA"
              :error-messages="validationErrors.iata"
              :disabled="loading"
              @input="clearValidation('iata')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="model.icao"
              name="icao"
              label="ICAO"
              :error-messages="validationErrors.icao"
              :disabled="loading"
              @input="clearValidation('icao')"
            />
          </v-col>
        </v-row>

        <v-autocomplete
          v-model="model.homebase"
          :items="entries"
          :loading="autocompleteLoading"
          :search-input.sync="search"
          item-text="codes"
          item-value="id"
          label="Homebase airport"
          placeholder="Enter at least 3 characters to search"
          :error-messages="validationErrors.homebase_id"
          return-object
          hide-no-data
          clearable
          @change="setHomebase"
          @input="clearValidation('homebase_id')"
        />

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="model.acmi"
              name="acmi"
              label="ACMI"
              :error-messages="validationErrors.acmi"
              :disabled="loading"
              @input="clearValidation('acmi')"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="model.fuelpr"
              name="fuelpr"
              label="Fuel price"
              :error-messages="validationErrors.fuelpr"
              :disabled="loading"
              @input="clearValidation('fuelpr')"
            />
          </v-col>
        </v-row>

        <v-row justify="space-around">
          <v-col cols="6">
            <v-checkbox
              v-model="model.is_cargo"
              :disabled="loading"
              label="Cargo"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="model.is_pax"
              :disabled="loading"
              label="PAX"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="model.is_homebase_return"
              :disabled="loading"
              label="Always return to homebase"
            />
          </v-col>
        </v-row>

        <v-textarea
          v-model="model.note"
          name="note"
          label="Note"
          rows="1"
          auto-grow
          :error-messages="validationErrors.note"
          :disabled="loading"
          @input="clearValidation('note')"
        />

        <v-divider />

        <v-list>
          <v-subheader>Headquarter</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>

            <v-list-item-content class="my-0 py-0">
              <v-list-item-title>
                <v-text-field
                  v-model="model.address.street"
                  name="street"
                  label="Street"
                  :error-messages="validationErrors['address.street']"
                  @input="clearValidation('address.street')"
                />
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="model.address.city"
                      name="city"
                      label="City"
                      :error-messages="validationErrors['address.city']"
                      @input="clearValidation('address.city')"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="model.address.state"
                      name="state"
                      label="State"
                      :error-messages="validationErrors['address.state']"
                      @input="clearValidation('address.state')"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="model.address.postcode"
                      name="postcode"
                      label="Postcode"
                      :error-messages="validationErrors['address.postcode']"
                      @input="clearValidation('address.postcode')"
                    />
                  </v-col>
                </v-row>
                <v-select
                  v-model="model.address.country"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  label="Country"
                  persistent-hint
                  return-object
                  single-line
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-subheader>Links</v-subheader>
          <v-list-item
            v-for="(item, index) in model.links"
            :key="`link-${index}`"
          >
            <v-list-item-action>
              <v-icon>mdi-link-variant</v-icon>
            </v-list-item-action>

            <v-list-item-content class="my-0 py-0">
              <v-list-item-title>
                <v-text-field
                  v-model="item.url"
                  name="url"
                  label="Url"
                  :error-messages="validationErrors[`links.${index}.url`]"
                  @input="clearValidation(`links.${index}.url`)"
                />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                color="error"
                @click="removeLink(item)"
              >
                mdi-delete
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="addLink()">
            <v-list-item-action>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Add</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-subheader>Emails</v-subheader>
          <v-list-item
            v-for="(item, index) in model.emails"
            :key="`email-${index}`"
          >
            <v-list-item-action class="col-1 mb-0 mr-1 pl-0">
              <v-select
                v-model="item.type"
                :items="mailTypeList"
                item-value="id"
                menu-props="auto"
                class="contact-type"
                single-line
              >
                <template
                  slot="selection"
                  slot-scope="data"
                >
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-icon v-on="on">
                        {{ data.item.icon }}
                      </v-icon>
                    </template>
                    <label>{{ data.item.name }}</label>
                  </v-tooltip>
                </template>
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <v-icon class="pr-2">
                    {{ data.item.icon }}
                  </v-icon>
                  <span>
                    {{ data.item.name }}
                  </span>
                </template>
              </v-select>
            </v-list-item-action>

            <v-list-item-content class="my-0 py-0">
              <v-list-item-title>
                <v-text-field
                  v-model="item.email"
                  name="email"
                  label="Email"
                  :error-messages="validationErrors[`emails.${index}.email`]"
                  @input="clearValidation(`emails.${index}.email`)"
                />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                color="error"
                @click="removeEmail(item)"
              >
                mdi-delete
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="addEmail()">
            <v-list-item-action>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Add</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-subheader>Phones</v-subheader>
          <v-list-item
            v-for="(item, index) in model.phones"
            :key="`phone-${index}`"
          >
            <v-list-item-action class="col-1 mb-0 mr-1 pl-0">
              <v-select
                v-model="item.type"
                :items="phoneTypeList"
                item-value="id"
                menu-props="auto"
                class="contact-type"
                single-line
              >
                <template
                  slot="selection"
                  slot-scope="data"
                >
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-icon v-on="on">
                        {{ data.item.icon }}
                      </v-icon>
                    </template>
                    <label>{{ data.item.name }}</label>
                  </v-tooltip>
                </template>
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <v-icon class="pr-2">
                    {{ data.item.icon }}
                  </v-icon>
                  <span>
                    {{ data.item.name }}
                  </span>
                </template>
              </v-select>
            </v-list-item-action>

            <v-list-item-content class="my-0 py-0">
              <v-list-item-title>
                <v-text-field
                  v-model="item.phone"
                  name="phone"
                  label="Phone"
                  :error-messages="validationErrors[`phones.${index}.phone`]"
                  @input="clearValidation(`phones.${index}.phone`)"
                />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                color="error"
                @click="removePhone(item)"
              >
                mdi-delete
              </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="addPhone()">
            <v-list-item-action>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Add</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </v-list>

        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="model.status"
              :disabled="loading"
              label="Active"
            />
          </v-col>
        </v-row>
        <v-divider />
      </v-card-text>

      <div class="loader-container">
        <v-progress-linear
          v-if="loading"
          class="loader"
          :indeterminate="true"
        />
      </div>

      <v-card-actions>
        <v-btn
          type="button"
          color="primary"
          :disabled="loading"
          @click="applyChanges"
        >
          Save
        </v-btn>
        <v-btn
          type="button"
          color="primary"
          text
          :disabled="loading"
          @click="onClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import { fieldValidation } from '../../mixins/fieldValidation';
  import { phoneType, mailType } from '../../constants';

  export default {
    mixins: [fieldValidation],

    props: ['airline', 'onClose', 'onSubmit'],

    data () {
      return {
        search: '',
        entries: [],
        loading: true,
        autocompleteLoading: false,
        model: {
          homebase: {},
          address: {},
          links: [],
          emails: [],
          phones: [],
        },
        countries: []
      };
    },

    computed: {
      title: function () {
        return this.airline ? 'Edit airline' : 'Add airline';
      },

      phoneTypeList: function () {
        return phoneType;
      },

      mailTypeList: function () {
        return mailType;
      }
    },

    watch: {
      async search (val) {
        if (!val || val.length < 3) {
          this.entries = this.model.homebase_id
            ? [{ ...this.model.homebase, codes: this.spliceName(this.model.homebase.iata, this.model.homebase.icao) }]
            : [];
          return;
        }

        this.autocompleteLoading = true;

        const request = {
          search: val,
        };

        try {
          const { data } = await HTTPService.getAirports(request);
          if (data.length > 0) {
            this.entries = data.map(entry => {
              return {
                ...entry,
                codes: this.spliceName(entry.iata, entry.icao),
              };
            });
          }
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.autocompleteLoading = false;
        }
      },

      airline: {
        handler: 'setModel',
        immediate: true
      }
    },

    async created () {
      try {
        const { data } = await HTTPService.getCountries();
        this.countries = [...data];
      } catch ({ response }) {
        ErrorService.handleError(response);
      } finally {
        this.loading = false;
      }
    },

    methods: {
      setHomebase (val) {
        if (!val) {
          this.model.homebase_id = null;
          return;
        }
        this.model.homebase_id = val.id;
        this.entries = [{
          ...val,
          codes: this.spliceName(val.iata, val.icao)
        }];
      },

      spliceName (iata, icao) {
        return iata ? `${icao}/${iata}` : icao;
      },

      async applyChanges () {
        this.loading = true;
        const result = this.airline
          ? HTTPService.updateAirline(this.model.id, this.model)
          : HTTPService.createAirline(this.model);

        try {
          const { data } = await result;
          const airline = {
            homebase: this.model.homebase,
            ...data
          };
          this.onSubmit(airline);
        } catch ({ response }) {
          this.setValidation(ErrorService.handleError(response));
        } finally {
          this.loading = false;
        }
      },

      setModel () {
        if (this.airline) {
          this.model = { ...this.airline };
          this.model.homebase = this.model.homebase || {};
          this.model.address = this.model.address || {};
          this.model.links = this.model.links || [];
          this.model.emails = this.model.emails ? this.model.emails.map(el => el.type ? el : { ...el, type: 1 }) : [];
          this.model.phones = this.model.phones ? this.model.phones.map(el => el.type ? el : { ...el, type: 1 }) : [];
          this.entries = this.model.homebase.id
            ? [{ ...this.model.homebase, codes: this.spliceName(this.model.homebase.iata, this.model.homebase.icao) }]
            : [];
        }
      },

      addLink () {
        this.model.links.push({});
      },

      addEmail () {
        this.model.emails.push({
          type: 1
        });
      },

      addPhone () {
        this.model.phones.push({
          type: 1
        });
      },

      removeLink (item) {
        const index = this.model.links.findIndex(el => el === item);
        for (let i = 0; i < this.model.links.length; i++) {
          this.clearValidation(`links.${i}.url`);
        }
        this.model.links.splice(index, 1);
      },

      removeEmail (item) {
        const index = this.model.emails.findIndex(el => el === item);
        for (let i = 0; i < this.model.emails.length; i++) {
          this.clearValidation(`emails.${i}.email`);
        }
        this.model.emails.splice(index, 1);
      },

      removePhone (item) {
        const index = this.model.phones.findIndex(el => el === item);
        for (let i = 0; i < this.model.phones.length; i++) {
          this.clearValidation(`phones.${i}.phone`);
        }
        this.model.phones.splice(index, 1);
      },
    }
  };
</script>

<style>
  .contact-type .v-input__icon {
    display: none;
  }

  .contact-type .v-input__slot:before {
    border: none !important;
  }

  .contact-type .v-input__slot:after  {
    border: none !important;
  }
</style>
