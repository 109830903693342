import axios from '../plugins/axios';

class HTTPService {
  // LOGIN REQUESTS
  login (options) {
    return axios.post('/account/login', options, { baseURL: '/api/v2' });
  }

  // AIRCRAFT REQUESTS
  getAircrafts (params = {}) {
    return axios.get('/aircrafts', { params });
  }

  getAircraft (registration) {
    return axios.get(`/aircrafts/${registration}`);
  }

  deleteAircraft (registration) {
    return axios.delete(`/aircrafts/${registration}`);
  }

  updateAircraft (registration, options) {
    return axios.put(`/aircrafts/${registration}`, options);
  }

  createAircraft (options) {
    return axios.post('/aircrafts', options);
  }

  getAircraftAutocomplete (value) {
    return axios.get(`/aircrafts/search/${value}`);
  }

  refreshAircraft (registration) {
    return axios.put(`/aircrafts/refresh/${registration}`);
  }

  getQuotePrices (params = {}) {
    return axios.get('/quotes', { params });
  }

  updateQuoteRoute (registration, options) {
    return axios.put(`/quotes/${registration}`, options);
  }

  // AIRCRAFT TYPES REQUESTS
  getAircraftTypes (params = {}) {
    return axios.get('/aircraft-types', { params });
  }

  getAircraftType (id) {
    return axios.get(`/aircraft-types/${id}`);
  }

  createAircraftType (options) {
    return axios.post('/aircraft-types', options);
  }

  updateAircraftType (id, options) {
    return axios.put(`/aircraft-types/${id}`, options);
  }

  deleteAircraftType (id) {
    return axios.delete(`/aircraft-types/${id}`);
  }

  getPortalAircraftTypes (params = {}) {
    return axios.get('/aircraft-types/portal', { params });
  }

  // FLIGHT REQUESTS
  getFlights (params = {}) {
    return axios.get('/flights', { params });
  }

  updateFlight (registration, options) {
    return axios.put(`/flights/${registration}`, options);
  }

  batchUpdateFlights (options) {
    return axios.post('/flights/batch-update', options);
  }

  // AIRLINES REQUESTS
  getAirlines (params = {}) {
    return axios.get('/airlines', { params });
  }

  getAirline (id) {
    return axios.get(`/airlines/${id}`);
  }

  createAirline (options) {
    return axios.post('/airlines', options);
  }

  updateAirline (id, options) {
    return axios.put(`/airlines/${id}`, options);
  }

  deleteAirline (id) {
    return axios.delete(`/airlines/${id}`);
  }

  refreshAirline (id) {
    return axios.put(`/airlines/refresh/${id}`);
  }

  getPortalAirlines (params = {}) {
    return axios.get('/airlines/portal', { params });
  }

  // PERSONS REQUESTS
  getPersons (params = {}) {
    return axios.get('/persons', { params });
  }

  getPerson (id) {
    return axios.get(`/persons/${id}`);
  }

  createPerson (options) {
    return axios.post('/persons', options);
  }

  updatePerson (id, options) {
    return axios.put(`/persons/${id}`, options);
  }

  deletePerson (id) {
    return axios.delete(`/persons/${id}`);
  }

  // AIRPORTS REQUESTS
  getAirports (params = {}) {
    return axios.get('/airports', { params });
  }

  // STATISTICS REQUESTS
  getStats (params = {}) {
    return axios.get('/stats', { params });
  }

  getWeeklyStats (params = {}) {
    return axios.get('/stats/weekly', { params });
  }

  getFlightsStats (params = {}) {
    return axios.get('/stats/flights', { params });
  }

  getStatsExcel (params = {}) {
    const config = {
      baseURL: '/api/v2',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    };
    return axios.get('/flight-log/export-excel', {
      params,
      ...config
    });
  }

  // COUNTRY REQUESTS
  getCountries (params = {}) {
    return axios.get('/countries', { params });
  }
}

export default new HTTPService();
