import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VAppBar,{attrs:{"fixed":"","app":"","dark":"","color":"primary"}},[_c(VToolbarTitle,[_c('router-link',{staticClass:"d-flex flex-row align-end text-decoration-none white--text",attrs:{"to":"/","custom":""}},[_c(VImg,{staticClass:"mr-2",attrs:{"alt":"AVEM AERO Track","width":"40","src":_vm.logo}}),_vm._v(" Track ")],1)],1),_c(VSpacer),_c(VToolbarItems,[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],1)],1),_c(VNavigationDrawer,{attrs:{"app":"","right":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.telegramLink)?_c('div',{staticClass:"pa-2 text-center"},[_c(VBtn,{attrs:{"icon":"","rounded":"","href":_vm.telegramLink}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v(" mdi-send ")])]}}],null,false,429110571)},[_c('span',[_vm._v("Telegram")])])],1)],1):_vm._e(),_c('div',{staticClass:"pa-2 text-center"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('logout')}}},[_vm._v(" Logout ")])],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"link":"","to":item.path}},[_c(VListItemTitle,{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }