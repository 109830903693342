<template>
  <Bar
    :data="chartData"
    :options="chartOptions"
    style="position: relative; height: 280px; width: 100%;"
  />
</template>

<script>
  import { Bar } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  } from 'chart.js';

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

  export default {
    name: 'BarChart',
    components: { Bar },

    props: {
      chartData: {
        type: Object,
        default: null
      }
    },

    computed: {
      chartOptions () {
        return {
          maintainAspectRatio: true,
          responsive: true,
          plugins: {
            legend: {
              display: false
            }
          },
          elements: {
            point: {
              radius: 1,
              hoverRadius: 4
            }
          },
        };
      }
    }
  };
</script>
