<template>
  <div>
    <template v-if="oms">
      <slot :oms="oms" />
    </template>
  </div>
</template>
<script>
  export default {
    props: {
      map: {
        type: Object,
        required: true
      },
    },

    data () {
      return {
        oms: null,
      };
    },

    mounted () {
      const oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        nearbyDistance: 11
      });

      oms.addListener('format', (marker, status) => {
        const markerIcon = {
          fontFamily: 'Material Icons',
          text: marker.label.text || 'room',
          fontSize: '30px',
          color: marker.label.color || marker.color
        };
        if (status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE) {
          markerIcon.text = 'add_location';
          markerIcon.color = '#3872fd';
        }
        marker.setLabel(markerIcon);
      });

      this.oms = oms;
    },

  };
</script>
