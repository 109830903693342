import store from '../store';
import AuthService from './AuthService';

class PermissionsService {
  check (permissions) {
    const user = store.state.userData;

    if (user && user.permissions && user.permissions[permissions]) {
      return true;
    }
    return;
  }

  guard (to, from, next) {
    if (to.matched.some(record => record.meta.permissions)) {
      if (this.check(to.meta.permissions)) {
        next();
      } else {
        next({ path: '/login' });
      }
    } else if (to.matched.some(record => record.meta.requiredAuth)) {
      if (AuthService.getToken()) {
        next();
      } else {
        next({ path: '/login' });
      }
    } else {
      next();
    }
  }
}

export default new PermissionsService();
