import { DateTime } from 'luxon';
import { flightAvailability } from '../constants';

class AircraftService {
  getRoleIcon (aircraft) {
    if (!aircraft) return '';

    let icon;

    if (aircraft.is_cargo && !aircraft.is_pax) {
      icon = 'mdi-truck-trailer';
    } else if (!aircraft.is_cargo && aircraft.is_pax) {
      icon = 'mdi-human-male-male';
    } else {
      icon = null;
    }

    return icon;
  }

  getAvailability ({ aircraft, pricing }) {
    const { availability, recentFlights } = aircraft;
    if (!availability) return null;

    if (pricing) {
      const {
        postime,
        price,
        flight_time,
        route,
        refuel,
        log,
        cr,
        currentAirport,
        departureAirport
      } = pricing;

      const isPos = currentAirport.id !== departureAirport.id;
      let valuePrefix = 'Pos to';
      let color;

      if ((availability.status === flightAvailability.available.status ||
           availability.status === flightAvailability.availableAtHomebase.status) && !isPos) {
        valuePrefix = 'Available at';
      }

      return {
        color: this.getAvailabilityColor(availability),
        value: `${valuePrefix} ${departureAirport.iata || departureAirport.icao} ${
          DateTime
            .fromISO(postime)
            .setZone('UTC')
            .toFormat('yyyy-MM-dd HH:mm')
        }`,
        price,
        flightTime: flight_time,
        route,
        refuel,
        log,
        cr,
        lat: currentAirport.lat,
        lng: currentAirport.lon,
        status: availability.status,
        isCalculated: true
      };
    }

    return {
      ...availability,
      color: this.getAvailabilityColor(availability),
    };
  }

  getAvailabilityColor (availability) {
    switch (availability.status) {
      case flightAvailability.aog.status:
        return flightAvailability.aog.color;
      case flightAvailability.available.status:
        return flightAvailability.available.color;
      case flightAvailability.availableAtHomebase.status:
        return flightAvailability.availableAtHomebase.color;
      case flightAvailability.availableSoon.status:
        return flightAvailability.availableSoon.color;
      default:
        return flightAvailability.notAvailable.color;
    }
  }

  isCharter (flightsArr) {
    if (!flightsArr.length) return null;
    const now = DateTime.now();
    const filteredFlightsArr = flightsArr.sort(
      (a, b) => DateTime.fromFormat(b.takeoff_time, 'yyyy-MM-dd HH:mm') - DateTime.fromFormat(a.takeoff_time, 'yyyy-MM-dd HH:mm')
    ).filter(flight => DateTime.fromFormat(flight.takeoff_time, 'yyyy-MM-dd HH:mm') < now);
    if (!filteredFlightsArr.length) return null;
    return !filteredFlightsArr[0].is_regular;
  }

  isFerry (flightsArr) {
    if (!flightsArr.length) return null;
    const now = DateTime.now();
    const filteredFlightsArr = flightsArr.sort(
      (a, b) => DateTime.fromFormat(b.takeoff_time, 'yyyy-MM-dd HH:mm') - DateTime.fromFormat(a.takeoff_time, 'yyyy-MM-dd HH:mm')
    ).filter(flight => DateTime.fromFormat(flight.takeoff_time, 'yyyy-MM-dd HH:mm') < now);
    if (!filteredFlightsArr.length) return null;
    return filteredFlightsArr[0].is_ferry;
  }
}

export default new AircraftService();
