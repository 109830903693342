<template>
  <v-container
    fluid
    px-0
    py-0
  >
    <v-row no-gutters>
      <v-col>
        <v-card :loading="loading">
          <template #loader="{ isActive }">
            <v-progress-linear
              :active="isActive"
              indeterminate
            />
          </template>

          <v-card-title class="py-0">
            <v-row>
              <v-col>
                <v-row no-gutters>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      color="secondary"
                      @click="toggleMap()"
                    >
                      {{ isMapShown ? 'mdi-format-list-bulleted' : 'mdi-map-marker-radius' }}
                    </v-icon>
                  </div>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      :color="filtersIconColor"
                      @click="toggleFilters()"
                    >
                      mdi-filter
                    </v-icon>
                  </div>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    class
                    single-line
                    hide-details
                    @input="searchForMap"
                  />
                </v-row>
                <v-row>
                  <AircraftFilters
                    v-if="isFiltersOpened"
                    :prev-filters="prevFilters"
                    :show-payload-filters="showPayloadFilters"
                    :show-airport-filters="showAirportFilters"
                    :validation-errors="validationErrors"
                    :reset-filters="resetFilters"
                    @toggle-filters="toggleFilters"
                    v-on="$listeners"
                  />
                </v-row>
                <v-row
                  v-if="isCalculated"
                  class="pb-2"
                >
                  <v-btn-toggle
                    v-model="sortBy"
                    color="primary"
                    light
                    dense
                    group
                    mandatory
                    class="toggle-btn-group"
                    @change="sortQuotes"
                  >
                    <v-btn
                      v-for="sort in sortOptions"
                      :key="sort.value"
                      :value="sort.value"
                      text
                    >
                      {{ sort.text }}
                    </v-btn>
                  </v-btn-toggle>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <FlightsMap
            v-if="isMapShown"
            :aircrafts="aircraftsForMap"
          />

          <v-data-table
            v-if="!isMapShown"
            :headers="headers"
            :items="aircrafts"
            :search="search"
            no-data-text="No aircrafts yet"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
            @current-items="getFilteredAircrafts"
          >
            <template #[`header.roleIcon`]="{}">
              <v-icon>
                mdi-truck-trailer
              </v-icon>
            </template>

            <template #[`item.registration`]="{ item }">
              <router-link :to="'/aircrafts/' + item.registration">
                {{ item.registration.toUpperCase() }}
              </router-link>
            </template>

            <template #[`item.roleIcon`]="{ item }">
              <v-icon
                v-if="item.roleIcon"
                color="primary"
              >
                {{ item.roleIcon }}
              </v-icon>
            </template>

            <template #[`item.aircraftType.name`]="{ item }">
              <router-link
                v-if="item.aircraftType"
                :to="'/aircraft-type/' + item.aircraftType.id"
              >
                {{ item.aircraftType.name }}
              </router-link>
            </template>

            <template #[`item.airline.name`]="{ item }">
              <router-link
                v-if="item.airline"
                :to="'/airlines/' + item.airline.id"
              >
                {{ item.airline.name }}
              </router-link>
            </template>

            <template #[`item.isCharter`]="{ item }">
              <span v-if="item.isCharter !== null">
                {{ item.isFerry ? 'f.' : '' }} {{ item.isCharter ? 'charter' : 'regular' }}
              </span>
            </template>

            <template #[`item.availability.value`]="{ item }">
              <AvailabilityChip
                v-if="item.availability"
                :aircraft="item"
                v-on="$listeners"
              />
            </template>

            <template #[`item.refreshId`]="{ item }">
              <v-icon
                v-if="item.registration !== refreshingId"
                color="success"
                @click="$emit('on-refresh', item.registration)"
              >
                mdi-refresh
              </v-icon>

              <v-progress-circular
                v-if="item.registration === refreshingId"
                size="24"
                indeterminate
                color="success"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AircraftFilters from '../../components/AircraftFilters';
  import AvailabilityChip from '../../components/AvailabilityChip';
  import FlightsMap from '../../components/FlightsMap';

  export default {
    components: {
      AircraftFilters,
      AvailabilityChip,
      FlightsMap
    },

    props: {
      loading: {
        type: Boolean
      },
      showPayloadFilters: {
        type: Boolean
      },
      showAirportFilters: {
        type: Boolean
      },
      aircrafts: {
        type: Array
      },
      refreshingId: {
        type: String
      },
      prevFilters: {
        type: Object
      },
      validationErrors: {
        type: Object
      },
      resetFilters: {
        type: Function
      }
    },

    data: () => ({
      search: '',
      sortBy: 'default',
      isMapShown: false,
      isFiltersOpened: false,
      aircraftsForMap: []
    }),

    computed: {
      headers () {
        return [
          { text: 'Registration', value: 'registration', sortable: true },
          { text: '', value: 'roleIcon', sortable: true },
          { text: 'Model ', value: 'aircraftType.name', sortable: true },
          { text: 'Airline', value: 'airline.name', sortable: true },
          { text: 'Type', value: 'isCharter', sortable: true, align: 'center' },
          { text: 'Availability', value: 'availability.value', sortable: true, align: 'center' },
          { text: '', value: 'refreshId', sortable: false }
        ];
      },

      filtersIconColor () {
        return this.isFiltersOpened ? 'primary' : 'secondary';
      },

      isCalculated ()  {
        return this.aircrafts.some(item => item.availability && item.availability.isCalculated);
      },

      sortOptions () {
        return [
          { text: 'Default', value: 'default' },
          { text: 'Cheapest', value: 'price' },
          { text: 'Fastest', value: 'arrival' }
        ];
      }
    },

    watch: {
      aircrafts: {
        handler (arr) {
          this.getFilteredAircrafts(arr);
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      toggleFilters () {
        this.isFiltersOpened = !this.isFiltersOpened;
      },

      toggleMap () {
        this.isMapShown = !this.isMapShown;
      },

      searchForMap () {
        if (!this.isMapShown) return;
        const text = this.search.toLowerCase().trim();
        const arr = [...this.aircrafts];
        let result = [];
        result = arr.filter(obj => {
          let found;
          Object.keys(obj).forEach((objKey) => {
            if (!obj[objKey]) return;
            if (typeof obj[objKey] === 'object' &&
              (objKey === 'aircraftType' || objKey === 'airline')) {
              Object.values(obj[objKey]).forEach(val => {
                if (!val) return;
                const value = val.toString().toLowerCase().trim();
                if (value.includes(text)) {
                  found = true;
                }
              });
            } else {
              const value = obj[objKey].toString().toLowerCase().trim();
              if (value.includes(text)) {
                found = true;
              }
            }
          });
          return found;
        });
        this.aircraftsForMap = [...result];
      },

      getFilteredAircrafts (e) {
        this.aircraftsForMap = [...e];
      },

      sortQuotes (e) {
        this.$emit('sort-quotes', e);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .v-data-table-header .v-icon {
    color: inherit;
  }

  .toggle-btn-group {
    width: 100%;
    justify-content: space-between;

    .v-btn{
      flex-grow: 1;
      margin: 0;
      border: none;

      &--active {
        &::before {
          background-color: transparent !important;
          border-bottom: 2px solid #1976d2;
        }
      }
    }
  }
</style>
