<template>
  <v-container
    fluid
    px-0
    py-0
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-card>
          <v-card-title class="py-0">
            <v-row>
              <v-col>
                <v-row no-gutters>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      color="secondary"
                      @click="toggleStatsView()"
                    >
                      {{ isStatsView ? 'mdi-format-list-bulleted' : 'mdi-chart-bar' }}
                    </v-icon>
                  </div>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      :color="iconColor"
                      @click="toggleFilters()"
                    >
                      mdi-filter
                    </v-icon>
                  </div>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    class
                    single-line
                    hide-details
                  />
                </v-row>
                <v-row>
                  <FlightFilters
                    v-if="isFiltersOpened"
                    :on-filter="toggleFilters"
                    :submit="filterFlights"
                    :prev-filters="prevFilters"
                    :reset-filters="resetListFilters"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <FlightsStats
            v-if="isStatsView"
            :flights="flights"
          />

          <v-data-table
            v-if="!isStatsView"
            v-model="selectedRows"
            :search="search"
            :headers="headers"
            :items="flights"
            no-data-text="No flights yet"
            show-select
            checkbox-color="primary"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
            :sort-by.sync="sort.sortBy"
            :sort-desc.sync="sort.sortDesc"
          >
            <template #[`item.registration`]="{ item }">
              <router-link :to="'/aircrafts/' + item.registration">
                {{ item.registration.toUpperCase() }}
              </router-link>
            </template>

            <template #[`item.aircraft.aircraftType.name`]="{ item }">
              <router-link
                v-if="item.aircraft.aircraftType"
                :to="'/aircraft-type/' + item.aircraft.aircraft_type_id"
              >
                {{ item.aircraft.aircraftType.name }}
              </router-link>
            </template>

            <template #[`item.aircraft.airline.name`]="{ item }">
              <router-link
                v-if="item.aircraft.airline"
                :to="'/airlines/' + item.aircraft.airline_id"
              >
                {{ item.aircraft.airline.name }}
              </router-link>
            </template>

            <template #[`item.is_regular`]="{ item }">
              <v-chip
                :color="item.is_regular ? 'default' : 'primary'"
              >
                {{ item.is_ferry ? 'f.' : '' }} {{ item.is_regular ? 'regular' : 'charter' }}
              </v-chip>
            </template>

            <template #[`item.callsign`]="{ item }">
              {{ item.callsign }}
              <v-tooltip
                v-if="item.icao_route"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-map-marker-path
                  </v-icon>
                </template>
                <span>{{ item.icao_route }}</span>
              </v-tooltip>
            </template>

            <template #[`item.editId`]="{ item }">
              <v-icon
                color="primary"
                @click="onEdit(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
          <v-row no-gutters>
            <v-col cols="2">
              <v-speed-dial
                v-if="isFabShown"
                v-model="fab"
                class="fab-container"
                direction="top"
                transition="slide-y-reverse-transition"
                fixed
                bottom
                left
                open-on-hover
              >
                <template #activator>
                  <v-btn
                    v-model="fab"
                    color="primary darken-2"
                    dark
                    fab
                  >
                    <v-icon v-if="fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn
                  dark
                  small
                  rounded
                  color="primary"
                  @click="handleTypeChange(false)"
                >
                  <span>Charter</span>
                </v-btn>
                <v-btn
                  dark
                  small
                  rounded
                  color="grey"
                  @click="handleTypeChange(true)"
                >
                  <span>Regular</span>
                </v-btn>
              </v-speed-dial>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import FlightFilters from '../../components/FlightFilters';
  import FlightsStats from './FlightsStats';

  export default {
    components: {
      FlightFilters,
      FlightsStats
    },

    props: {
      loading: {
        type: Boolean
      },
      flights: {
        type: Array
      },
      onEdit: {
        type: Function
      },
      filterFlights: {
        type: Function
      },
      prevFilters: {
        type: Object
      },
      resetFilters: {
        type: Function
      }
    },

    data: () => ({
      search: '',
      isFiltersOpened: false,
      isStatsView: true,
      fab: false,
      selectedRows: [],
      sort: {
        sortBy: 'takeoffTimeParsed',
        sortDesc: true,
      }
    }),

    computed: {
      headers: function () {
        return [
          { text: 'Registration', value: 'registration', sortable: true },
          { text: 'Model ', value: 'aircraft.aircraftType.name', sortable: true },
          { text: 'Airline', value: 'aircraft.airline.name', sortable: true },
          { text: 'Type', value: 'is_regular', sortable: true },
          { text: 'Callsign', value: 'callsign', sortable: true },
          { text: 'Takeoff', value: 'takeoffTimeParsed', sortable: true },
          { text: 'Landing', value: 'arrivalTimeParsed', sortable: true },
          { text: 'FH', value: 'durationString', sortable: true },
          { text: 'From', value: 'from', sortable: true },
          { text: 'To', value: 'to', sortable: true },
          { text: '', value: 'editId', sortable: false }
        ];
      },
      iconColor: function () {
        return this.isFiltersOpened ? 'primary' : 'secondary';
      },
      isFabShown: function () {
        return !this.isStatsView && this.selectedRows.length > 0;
      }
    },

    methods: {
      toggleFilters () {
        this.isFiltersOpened = !this.isFiltersOpened;
      },

      resetListFilters () {
        this.search = '';
        return this.resetFilters();
      },

      toggleStatsView () {
        this.isStatsView = !this.isStatsView;
      },

      handleTypeChange (is_regular) {
        this.$emit('on-type-change', {
          flights: this.selectedRows,
          is_regular
        });
      }
    }
  };
</script>

<style scoped>
  .v-data-table-header .v-icon {
    color: inherit;
  }

  .fab-container {
    left: 15vh;
  }
</style>
