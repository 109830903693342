<template>
  <v-container
    fluid
    px-0
    py-0
  >
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-card-title class="py-0">
            <v-row>
              <v-col>
                <v-row no-gutters>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      :color="iconColor"
                      @click="toggleFilters()"
                    >
                      mdi-filter
                    </v-icon>
                  </div>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    class
                    single-line
                    hide-details
                  />
                </v-row>
                <v-row>
                  <AircraftFilters
                    v-if="isFiltersOpened"
                    :prev-filters="prevFilters"
                    :reset-filters="resetFilters"
                    @on-filter="filterAircrafts"
                    @toggle-filters="toggleFilters"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :search="search"
            :headers="headers"
            :items="aircrafts"
            no-data-text="No aircrafts yet"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
          >
            <template #[`header.roleIcon`]="{}">
              <v-icon>
                mdi-truck-trailer
              </v-icon>
            </template>

            <template #[`item.registration`]="{ item }">
              <router-link :to="'/aircrafts/' + item.registration">
                {{ item.registration.toUpperCase() }}
              </router-link>
            </template>

            <template #[`item.roleIcon`]="{ item }">
              <v-icon
                v-if="item.roleIcon"
                color="primary"
              >
                {{ item.roleIcon }}
              </v-icon>
            </template>

            <template #[`item.aircraftType.name`]="{ item }">
              <span
                class="editable"
                color="primary"
                @click="onEditType(item.aircraftType)"
              >
                {{ item.aircraftType ? item.aircraftType.name : '' }}
              </span>
            </template>

            <template #[`item.airline.name`]="{ item }">
              <span
                class="editable"
                color="primary"
                @click="onEditAirline(item.airline)"
              >
                {{ item.airline ? item.airline.name : '' }}
              </span>
            </template>

            <template #[`item.editId`]="{ item }">
              <v-icon
                color="primary"
                @click="onEdit(item)"
              >
                mdi-pencil
              </v-icon>
            </template>

            <template #[`item.deleteId`]="{ item }">
              <v-icon
                color="error"
                @click="onDelete(item.registration)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AircraftFilters from '../../components/AircraftFilters';

  export default {
    components: { AircraftFilters },

    props: {
      aircrafts: {
        type: Array
      },
      onEdit: {
        type: Function
      },
      onEditType: {
        type: Function
      },
      onEditAirline: {
        type: Function
      },
      onDelete: {
        type: Function
      },
      filterAircrafts: {
        type: Function
      },
      prevFilters: {
        type: Object
      },
      resetFilters: {
        type: Function
      }
    },

    data: () => ({
      search: '',
      isFiltersOpened: false,
    }),

    computed: {
      headers: function () {
        return [
          { text: 'Registration', value: 'registration', sortable: true },
          { text: '', value: 'roleIcon', sortable: true },
          { text: 'Model ', value: 'aircraftType.name', sortable: true },
          { text: 'Airline', value: 'airline.name', sortable: true },
          { text: 'IATA', value: 'airline.iata', sortable: true },
          { text: 'ICAO', value: 'airline.icao', sortable: true },
          { text: '', value: 'editId', sortable: false },
          { text: '', value: 'deleteId', sortable: false }
        ];
      },
      iconColor: function () {
        return this.isFiltersOpened ? 'primary' : 'secondary';
      }
    },

    methods: {
      toggleFilters () {
        this.isFiltersOpened = !this.isFiltersOpened;
      }
    }
  };
</script>


<style scoped>
.editable {
  cursor: pointer;
  border-bottom: 1px #313131 dashed;
}
</style>
