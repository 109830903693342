<template>
  <div>
    <v-dialog
      v-model="isModalOpened"
      :max-width="dialogWidth"
    >
      <RecentFlightModal
        v-if="!marker.availability?.price"
        :registration="marker.registration"
        :flights="marker.lastFlights"
      />
      <QuoteFlightsModal
        v-if="marker.availability?.price"
        :registration="marker.registration"
        :flights="marker.availability.route"
      />
    </v-dialog>
  </div>
</template>
<script>
  import RecentFlightModal from './RecentFlightModal';
  import QuoteFlightsModal from './QuoteFlightsModal';

  export default {
    components: {
      RecentFlightModal,
      QuoteFlightsModal
    },

    props: {
      google: {
        type: Object,
        required: true
      },
      map: {
        type: Object,
        required: true
      },
      oms: {
        type: Object,
        required: true
      },
      marker: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        isModalOpened: false
      };
    },

    computed: {
      markerTitle () {
        return `${this.marker.registration} - ${this.marker.aircraftTypeName}`;
      },

      dialogWidth () {
        return `${this.marker.availability?.price ? 800 : 600}px`;
      },

      infoWindowContent () {
        const { availability } = this.marker;
        const log = availability.price ? availability.log.map(item => {
          return `<div
                    class="d-flex justify-start flex-nowrap"
                    no-gutters
                  >
                    <span class="font-weight-medium mr-2">
                      ${item.price.toFixed()}&euro;
                    </span>
                    <span class="ml-auto">
                      ${item.description}
                    </span>
                  </div>`;
        }) : [];
        const content = `
        <div class='font-weight-bold'>${this.markerTitle}</div>
        ${availability.price ?
        `<div>
          <span class="font-weight-medium">${availability.value}.</span>
          <br/>
          <span>
            Price: <span class="font-weight-medium">${availability.price}\u20ac</span>
            ${log.join('')}
          </span>
        </div>`
        : `<span
            class="font-weight-medium"
            style="color: ${availability.color}">
            ${availability.value}
          </span>`
        }`;
        return content;
      }
    },

    mounted () {
      const markerParams = {
        marker: this.marker,
        map: this.map,
        position: this.marker.position,
        label: this.marker.label,
        title: this.markerTitle,
        animation: google.maps.Animation.DROP,
        icon: {
          ...this.marker.icon,
          path: google.maps.SymbolPath.CIRCLE,
        },
        color: this.marker.color
      };
      const marker = new google.maps.Marker(markerParams);
      this.oms.addMarker(marker);

      const infowindow = new google.maps.InfoWindow({
        ariaLabel: this.markerTitle,
        content: this.infoWindowContent,
      });

      marker.addListener('spider_click', () => {
        this.isModalOpened = true;
      });

      marker.addListener('mouseover', () => {
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      });

      marker.addListener('mouseout', () => {
        infowindow.close();
      });
    }
  };
</script>
