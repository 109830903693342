<template>
  <v-container
    fluid
    px-0
    py-0
  >
    <v-dialog
      :max-width="dialogWidth"
      transition="scale-transition"
      origin="center right"
    >
      <template #activator="{ on, attrs }">
        <v-chip
          v-if="aircraft.availability"
          :color="aircraft.availability.color"
          :class="{
            'white-text': aircraft.availability.status === availabilityConstants.notAvailable.status,
            'dense': isQuote
          }"
          v-bind="attrs"
          v-on="on"
        >
          {{ aircraft.availability.value }}
        </v-chip>
      </template>

      <RecentFlightModal
        v-if="!isQuote"
        :registration="aircraft.registration"
        :flights="flights"
      />
      <QuoteFlightsModal
        v-if="isQuote"
        :registration="aircraft.registration"
        :flights="flights"
        v-on="$listeners"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import RecentFlightModal from './RecentFlightModal';
  import QuoteFlightsModal from './QuoteFlightsModal';
  import { flightAvailability } from '../constants';

  export default {
    components: {
      RecentFlightModal,
      QuoteFlightsModal
    },

    props: {
      aircraft: {
        type: Object
      },
      flights: {
        type: Array
      },
      isQuote: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({}),

    computed: {
      availabilityConstants () {
        return flightAvailability;
      },

      dialogWidth () {
        return `${this.isQuote ? 800 : 600}px`;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .white-text.v-chip {
    color: #fff;
  }

  .dense.v-chip {
    height: 1.5em;
  }
</style>
