<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            Rules
          </h1>
          <v-spacer />
          <v-btn
            type="button"
            color="primary"
          >
            Add Rule
          </v-btn>
        </v-row>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              class
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :search="search"
            :headers="headers"
            no-data-text="No aircrafts yet"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      search: ''
    }),

    computed: {
      headers: () => {
        const headers = [
          {
            text: 'Airline',
            value: 'airline',
            sortable: true
          },
          {
            text: 'Registration',
            value: 'registration',
            sortable: true
          },
          {
            text: 'From',
            value: 'from',
            sortable: true
          },
          {
            text: 'To',
            value: 'to',
            sortable: true
          },
          {
            text: '',
            value: 'refreshId',
            sortable: false
          }
        ];

        return headers;
      }
    }
  };
</script>
