import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[(!_vm.loading)?_c(VRow,{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c(VCol,[_c(VCard,{staticClass:"ma-0 pa-0"},[_c('header',{staticClass:"px-4 pt-4"},[_c('h1',{staticClass:"card-heading"},[_vm._v(" Flights ")])]),_c(VRow,{attrs:{"no-gutters":""}},[_c('LegsList',{attrs:{"flights":_vm.flights,"prev-filters":_vm.prevFilters,"excel":_vm.getExcel,"filter-flights":_vm.filterFlights,"reset-filters":_vm.resetFilters}})],1)],1)],1)],1):_vm._e(),(!_vm.loading)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('LegsSummary',{attrs:{"stats":_vm.stats}})],1)],1):_vm._e(),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":true,"absolute":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }