import { DateTime, Duration } from 'luxon';

class StatsService {
  organizeStaffData (staffArr) {
    return staffArr.staff.map(el => {
      const ratio = this.calculatePercentage(el.quotes, el.flights);
      el.ratio = ratio;
      return el;
    });
  }

  getRevenueChartData (revenueByDate, dateLabels, totalRevenue) {
    return {
      datasets: [
        {
          data: revenueByDate,
          lineTension: false,
        }
      ],
      labels: dateLabels,
      totalRevenue: totalRevenue
    };
  }

  getFlightTimeChartData (flightTimeByDate, dateLabels, totalFlightTime, averageFlightTime) {
    return {
      datasets: [
        {
          data: flightTimeByDate,
          lineTension: false,
        }
      ],
      labels: dateLabels,
      totalFlightTime: totalFlightTime,
      averageFlightTime: averageFlightTime
    };
  }

  getProfitShareChartData (data, labels, totalProfit) {
    return {
      datasets: [
        {
          data: data,
          lineTension: false,
          backgroundColor: [
            '#c2a0df',
            '#63a1d9',
            '#2f3ec9',
            '#ec9387',
            '#1e7719',
            '#188975',
            '#cd2d21'
          ],
          borderColor: ['#ffb822']
        }
      ],
      labels: labels,
      totalProfit: totalProfit
    };
  }

  getFlightTimeByAircraftChartData (data, labels, totalFlightTime) {
    return {
      datasets: [
        {
          data: data,
          lineTension: false,
          backgroundColor: [
            '#c2a0df',
            '#63a1d9',
            '#2f3ec9',
            '#ec9387',
            '#1e7719',
            '#188975',
            '#cd2d21'
          ],
          borderColor: ['#ffb822']
        }
      ],
      labels: labels,
      totalFlightTime: totalFlightTime
    };
  }

  getChartDataByCompany (data, labels, total) {
    return {
      datasets: [
        {
          data: data,
          lineTension: false,
          backgroundColor: [
            '#48bf8e',
            '#f6248f',
            '#3ff44c',
            '#9a3c70',
            '#79be02',
            '#60249e',
            '#aebf8a',
            '#cd1fce',
            '#207a3f',
            '#e27db1',
            '#53c6ef'
          ],
          borderColor: ['#ffb822']
        }
      ],
      labels: labels,
      total: total
    };
  }

  organizeTotals (rawData) {
    let aircrafts = null;
    if (rawData.aircrafts) {
      aircrafts = rawData.aircrafts.map(el => {
        el.acmi = Math.round(el.acmi);
        el.price = Math.round(el.price);
        el.quotes = el.quotes ? el.quotes : 0;
        el.flights = el.flights ? el.flights : 0;
        el.ratio = this.calculatePercentage(el.quotes, el.flights);
        el.average = Math.round(el.average);
        el.durationString = Duration.fromObject({ seconds: el.duration }).toFormat('h:mm');
        return el;
      });
    }

    return {
      quotes: {
        amount: Math.round(rawData.quotes.amount),
        acmi: Math.round(rawData.quotes.acmi)
      },
      flights: {
        amount: Math.round(rawData.flights.amount),
        price: Math.round(rawData.flights.price)
      },
      aircrafts: aircrafts,
    };
  }

  organizeChartData (rawData) {
    let dateLabels = [];
    let revenueByDate = [];
    let flightTimeByDate = [];
    let resultByDate = {};
    let filledResultByDate = {};
    let totalRevenue = null;
    let totalFlightAmount = null;
    let totalFlightTime = null;
    let averageFlightTime = null;

    rawData.forEach(item => {
      const { id, registration, week, year, flights } = item;
      const price = item.price > 0 ? item.price : 0;
      const duration = item.duration > 0 ? Math.round(item.duration / 3600) : 0;
      const date = DateTime.fromObject({
        weekday: 1,
        weekNumber: week,
        weekYear: year,
      }).toFormat('MMM d, yyyy');
      const labelDate = week.toString() + year.toString();

      totalRevenue += price;
      totalFlightTime += duration;
      totalFlightAmount += flights;

      if (resultByDate.hasOwnProperty(labelDate)) {
        resultByDate[labelDate].price += price;
        resultByDate[labelDate].duration += duration;
      } else {
        resultByDate[labelDate] = {};
        resultByDate[labelDate].date = date;
        resultByDate[labelDate].price = price;
        resultByDate[labelDate].duration = duration;
      }
    });

    averageFlightTime = (totalFlightTime && totalFlightAmount) ? parseFloat((totalFlightTime / totalFlightAmount).toFixed(1)) : 0;
    filledResultByDate = this.fillSkippedWeeks(resultByDate);

    dateLabels = this.sortDataByDate(resultByDate);
    dateLabels = dateLabels.map(el => {
      const week = el.slice(0, -4);
      const year = el.slice(-4);
      const date = DateTime.fromObject({
        weekday: 1,
        weekNumber: week,
        weekYear: year,
      }).toFormat('MMM d, yyyy');

      revenueByDate.push(filledResultByDate[el].price);
      flightTimeByDate.push(filledResultByDate[el].duration);

      return date;
    });

    return {
      dateLabels,
      revenueByDate,
      flightTimeByDate,
      totalRevenue,
      totalFlightTime,
      averageFlightTime
    };
  }

  organizeChartDataByAircraft (rawData) {
    let aircraftProfitLabels = [];
    let aircraftTimeLabels = [];
    let profitShareByAircraft = [];
    let flightTimeByAircraft = [];
    let resultByAircraft = rawData.totals.aircrafts;
    let profitArr = resultByAircraft.map(el => {
      el.price = el.price > 0 ? el.price : 0;
      return el.price;
    });
    let flightTimeArr = resultByAircraft.map(el => {
      el.duration = el.duration > 0 ? Math.round(el.duration / 3600) : 0;
      return el.duration;
    });
    let totalProfit = profitArr.length > 0 ? profitArr.reduce((acc, el) => acc + el) : 0;
    let totalFlightTime = flightTimeArr.length > 0 ? flightTimeArr.reduce((acc, el) => acc + el) : 0;
    resultByAircraft.forEach(el => {
      let priceInPercents = Math.round(
        (el.price / totalProfit) * 100
      );
      if (priceInPercents > 0) {
        aircraftProfitLabels.push(el.registration);
        profitShareByAircraft.push(priceInPercents);
      }
      let flightTimeInPercents = Math.round(
        (el.duration / totalFlightTime) * 100
      );
      if (flightTimeInPercents > 0) {
        aircraftTimeLabels.push(el.registration);
        flightTimeByAircraft.push(flightTimeInPercents);
      }
    });

    return {
      aircraftProfitLabels,
      aircraftTimeLabels,
      profitShareByAircraft,
      flightTimeByAircraft
    };
  }

  organizeChartDataByOperator (rawData) {
    let operatorLabels = [];
    let profitShareByOperator = [];
    let flightTimeByOperator = [];
    let resultByOperator = {};
    let overallFlightTime = null;
    let overallProfit = null;

    if (rawData.totals.aircrafts) {
      rawData.totals.aircrafts.forEach(item => {
        let { id, registration, operator } = item;
        let price = item.price > 0 ? item.price : 0;
        let duration = item.duration > 0 ? item.duration : 0;
        overallFlightTime += duration;
        overallProfit += price;

        if (resultByOperator.hasOwnProperty(operator)) {
          resultByOperator[operator].duration += duration;
          resultByOperator[operator].price += price;
        } else {
          resultByOperator[operator] = {};
          resultByOperator[operator].id = id;
          resultByOperator[operator].duration = duration;
          resultByOperator[operator].price = price;
        }
      });
    }

    for (let key in resultByOperator) {
      let priceInPercents = Math.round(
        (resultByOperator[key].price / overallProfit) * 100
      );
      let flightTimeInPercents = Math.round(
        (resultByOperator[key].duration / overallFlightTime) * 100
      );
      operatorLabels.push(key);
      profitShareByOperator.push(priceInPercents > 0 ? priceInPercents : 0);
      flightTimeByOperator.push(flightTimeInPercents > 0 ? flightTimeInPercents : 0);
    }

    return {
      operatorLabels,
      profitShareByOperator,
      flightTimeByOperator,
      overallFlightTime,
      overallProfit
    };
  }

  organizePersonsByCompany (rawData) {
    let rawCompanyQuotesLabels = [];
    let rawCompanyFlightsLabels = [];
    let companyQuotesLabels = [];
    let companyFlightsLabels = [];
    let flightsByCompany = [];
    let quotesByCompany = [];
    let resultByCompany = {};
    let overallFlights = null;
    let overallQuotes = null;

    if (rawData.persons) {
      rawData.persons.forEach(item => {
        let company = item.company ? item.company : 0;
        let flights = item.flights > 0 ? item.flights : 0;
        let quotes = item.quotes > 0 ? item.quotes : 0;
        let revenue = item.price > 0 ? Math.round(item.price) : 0;
        overallFlights += flights;
        overallQuotes += quotes;

        if (resultByCompany.hasOwnProperty(company)) {
          resultByCompany[company].flights += flights;
          resultByCompany[company].quotes += quotes;
          resultByCompany[company].revenue += revenue;
        } else {
          resultByCompany[company] = {
            company,
            flights,
            quotes,
            revenue,
          };
        }
      });
    }

    let statsByCompany = Object.keys(resultByCompany).map(key => {
      return resultByCompany[key];
    });
    let sortedByFlights = [...statsByCompany].sort((a, b) => parseFloat(b.flights) - parseFloat(a.flights));
    let sortedByQuotes = [...statsByCompany].sort((a, b) => parseFloat(b.quotes) - parseFloat(a.quotes));

    sortedByFlights.forEach(el => {
      let flightsInPercents = Math.round(
        (el.flights / overallFlights) * 100
      );
      if (flightsInPercents > 0) {
        rawCompanyFlightsLabels.push(el.company);
        flightsByCompany.length <= 9 ? flightsByCompany.push(el.flights) : flightsByCompany[flightsByCompany.length - 1] += el.flights;
      }
      companyFlightsLabels = rawCompanyFlightsLabels.slice(0, 9);
      companyFlightsLabels.push('Other');
    });

    sortedByQuotes.forEach(el => {
      let quotesInPercents = Math.round(
        (el.quotes / overallQuotes) * 100
      );
      if (quotesInPercents > 0) {
        rawCompanyQuotesLabels.push(el.company);
        quotesByCompany.length <= 9 ? quotesByCompany.push(el.quotes) : quotesByCompany[quotesByCompany.length - 1] += el.quotes;
      }
      companyQuotesLabels = rawCompanyQuotesLabels.slice(0, 9);
      companyQuotesLabels.push('Other');
    });

    return {
      companyQuotesLabels,
      companyFlightsLabels,
      flightsByCompany,
      quotesByCompany,
      overallFlights,
      overallQuotes,
      statsByCompany
    };
  }

  fillSkippedWeeks (resultByDate) {
    let filteredArr = this.sortDataByDate(resultByDate);

    filteredArr.forEach(el => {
      if (!resultByDate.hasOwnProperty(el)) {
        const weekNumber = el.slice(0, -4);
        const weekYear = el.slice(-4);
        const date = DateTime.fromObject({
          weekday: 1,
          weekNumber,
          weekYear,
        }).toFormat('MMM d, yyyy');

        resultByDate[el] = {
          date,
          price: 0,
          duration: 0,
        };
      }
    });

    return resultByDate;
  }

  sortDataByDate (resultByDate) {
    const arr = Object.keys(resultByDate).sort((a, b) => {
      return DateTime.fromObject({
        weekNumber: a.slice(0, -4),
        weekYear: a.slice(-4)
      }).toMillis() - DateTime.fromObject({
        weekNumber: b.slice(0, -4),
        weekYear: b.slice(-4)
      }).toMillis();
    });
    let years = [];
    let filteredArr = [];

    arr.forEach(el => {
      const year = el.slice(-4);
      if (years.indexOf(year) === -1) {
        years.push(year);
      }
    });

    years = years.sort((a, b) => {
      return +a - +b;
    });

    years.forEach(el => {
      const year = el.slice(-4);
      const filtered = arr.filter(el => el.slice(-4) === year);
      filteredArr = [...filteredArr, ...filtered];
    });

    for (let i = 1; i < filteredArr.length; i++) {
      if (filteredArr[i].slice(0, -4) - filteredArr[i - 1].slice(0, -4) !== 1
        && filteredArr[i - 1].slice(0, -4) < 52
      ) {
        let week = filteredArr[i - 1].slice(0, -4);
        const year = filteredArr[i - 1].slice(-4);
        week = +week + 1;
        const newDate = week.toString() + year.toString();
        filteredArr.splice(i, 0, newDate);
      }
    }

    return filteredArr;
  }

  calculatePercentage (quotes, flights) {
    if (quotes === 0) {
      return 0;
    } else if (flights === 0) {
      return 100;
    } else if (quotes === flights) {
      return 50;
    } else if (quotes > flights) {
      let ratio = (flights / quotes) * 100;
      return 100 - ratio;
    } else if (quotes < flights) {
      let ratio = (quotes / flights) * 100;
      return ratio;
    } else return 0;
  }

  getStats (params) {
    let totals = this.organizeTotals(params.totals);
    let {
      aircraftProfitLabels,
      aircraftTimeLabels,
      profitShareByAircraft,
      flightTimeByAircraft,
    } = this.organizeChartDataByAircraft(params);
    let {
      operatorLabels,
      profitShareByOperator,
      flightTimeByOperator,
      overallProfit,
      overallFlightTime
    } = this.organizeChartDataByOperator(params);
    let staff = params.staff.map(el => {
      let ratio = this.calculatePercentage(el.quotes, el.flights);
      el.ratio = ratio;
      return el;
    });
    let {
      companyQuotesLabels,
      companyFlightsLabels,
      flightsByCompany,
      quotesByCompany,
      overallFlights,
      overallQuotes,
      statsByCompany
    } = this.organizePersonsByCompany(params);
    let persons = params.persons.map(el => {
      el.email = el.email.toLowerCase();
      el.revenue = Math.round(el.price);
      el.ratio = el.flights ? Math.round(el.quotes / el.flights) : 0;
      return el;
    });
    statsByCompany.forEach(el => {
      el.ratio = el.flights ? Math.round(el.quotes / el.flights) : 0;
    });
    totals.ratio = totals.quotes.amount && totals.flights.amount ? Math.round(totals.quotes.amount / totals.flights.amount) : 0;

    const profitShareByOperatorChartData = this.getProfitShareChartData(profitShareByOperator, operatorLabels, overallProfit);
    const flightTimeByOperatorChartData = this.getFlightTimeByAircraftChartData(flightTimeByOperator, operatorLabels, overallFlightTime);
    const profitShareByAircraftChartData = this.getProfitShareChartData(profitShareByAircraft, aircraftProfitLabels, overallProfit);
    const flightTimeByAircraftChartData = this.getFlightTimeByAircraftChartData(flightTimeByAircraft, aircraftTimeLabels, overallProfit);
    const flightsByCompanyChartData = this.getChartDataByCompany(flightsByCompany, companyFlightsLabels, overallFlights);
    const quotesByCompanyChartData = this.getChartDataByCompany(quotesByCompany, companyQuotesLabels, overallQuotes);

    return {
      totals,
      staff,
      profitShareByOperatorChartData,
      flightTimeByOperatorChartData,
      profitShareByAircraftChartData,
      flightTimeByAircraftChartData,
      flightsByCompanyChartData,
      quotesByCompanyChartData,
      persons,
      statsByCompany
    };
  }

  getWeeklyStats (params) {
    let {
      dateLabels,
      revenueByDate,
      flightTimeByDate,
      totalRevenue,
      totalFlightTime,
      averageFlightTime
    } = this.organizeChartData(params);
    const revenueChartData = this.getRevenueChartData(revenueByDate, dateLabels, totalRevenue);
    const flightTimeChartData = this.getFlightTimeChartData(flightTimeByDate, dateLabels, totalFlightTime, averageFlightTime);

    return {
      revenueChartData,
      flightTimeChartData
    };
  }

  getStatsByAircraft (params) {
    let aircraft = params.aircraft;
    let totals = this.organizeTotals(params.totals);

    return {
      aircraft,
      totals
    };
  }

  parseFlightsStats (flights) {
    const parsedFlights = [];
    const totals = {
      totalFlt: '',
      totalBlk: '',
      avgFlth: 0,
      minFlth: 0,
      maxFlth: 0,
      avgBlkh: 0,
      minBlkh: 0,
      maxBlkh: 0
    };
    const flth = [];
    const blkh = [];
    let totalFlt = 0;
    let totalBlk = 0;

    for (const flight of flights) {
      const parsedFlight = { ...flight };
      const blockHours = DateTime.fromISO(flight.blockon).diff(DateTime.fromISO(flight.blockoff), ['hours', 'minutes']);
      const flightHours = DateTime.fromISO(flight.takeon).diff(DateTime.fromISO(flight.takeoff), ['hours', 'minutes']);

      totalFlt += flightHours.isValid ? flightHours.toMillis() : 0;
      totalBlk += blockHours.isValid ? blockHours.toMillis() : 0;
      parsedFlight.blockoff = flight.blockoff ? DateTime.fromISO(flight.blockoff).toFormat('yyyy-MM-dd HH:mm') : 'N/A';
      parsedFlight.blockon = flight.blockon ? DateTime.fromISO(flight.blockon).toFormat('yyyy-MM-dd HH:mm') : 'N/A';
      parsedFlight.takeoff = flight.takeoff ? DateTime.fromISO(flight.takeoff).toFormat('yyyy-MM-dd HH:mm') : 'N/A';
      parsedFlight.takeon = flight.takeon ? DateTime.fromISO(flight.takeon).toFormat('yyyy-MM-dd HH:mm') : 'N/A';
      parsedFlight.block_hours = blockHours.isValid ? blockHours.toFormat('hh:mm') : 'N/A';
      parsedFlight.flight_hours = blockHours.isValid ? flightHours.toFormat('hh:mm') : 'N/A';
      parsedFlight.date = flight.blockoff ? DateTime.fromISO(flight.blockoff).toFormat('yyyy-MM-dd') : '-';
      parsedFlight.cargo_weight = flight.cargo_weight || 0;

      if (flightHours.isValid) {
        const value = Math.ceil(parsedFlight.fuel_used / flightHours.as('hours') * 1e2) / 1e2;
        flth.push(value);
        totals.minFlth = totals.minFlth && value >= totals.minFlth ? totals.minFlth : value;
        totals.maxFlth = totals.maxFlth && value <= totals.maxFlth ? totals.maxFlth : value;
      }
      if (blockHours.isValid) {
        const value = Math.ceil(parsedFlight.fuel_used / blockHours.as('hours') * 1e2) / 1e2;
        blkh.push(value);
        totals.minBlkh = totals.minBlkh && value >= totals.minBlkh ? totals.minBlkh : value;
        totals.maxBlkh = totals.maxBlkh && value <= totals.maxBlkh ? totals.maxBlkh : value;
      }

      parsedFlights.push(parsedFlight);
    }

    totals.totalFlt = Duration.fromMillis(totalFlt).toFormat('hh:mm');
    totals.totalBlk = Duration.fromMillis(totalBlk).toFormat('hh:mm');
    totals.avgFlth = flth.length ? Math.round((flth.reduce((acc, val) => acc + val, 0) / flth.length) * 1e2) / 1e2 : 0;
    totals.avgBlkh = blkh.length ? Math.round((blkh.reduce((acc, val) => acc + val, 0) / blkh.length) * 1e2) / 1e2 : 0;

    return {
      flights: parsedFlights,
      totals
    };
  }
}

export default new StatsService();
