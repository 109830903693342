<template>
  <v-container
    fluid
    px-3
    py-3
  >
    <v-row
      v-if="!loading"
      no-gutters
    >
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider="-"
        />

        <v-row
          justify="space-between"
          align="center"
          class="mx-3 my-3"
        >
          <h1 class="primary--text mx-0 my-0">
            {{ airline.name }} aircrafts
          </h1>
          <div>
            <v-btn
              v-if="!loading"
              type="button"
              color="primary"
              :to="{ name: 'persons' }"
            >
              Persons
            </v-btn>

            <v-btn
              v-if="!loading"
              type="button"
              color="primary"
              class="ma-1"
              @click="openModal"
            >
              Edit airline
            </v-btn>
          </div>
        </v-row>

        <v-row class="primary--text mx-3 mb-3">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="!refreshLoading"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="refreshAirline"
              >
                mdi-refresh
              </v-icon>
            </template>
            <div>
              Refresh airline
            </div>
          </v-tooltip>

          <v-progress-circular
            v-if="refreshLoading"
            size="24"
            indeterminate
            color="primary"
          />

          <span
            v-if="lastUpdated"
            class="ml-2"
          >
            {{ lastUpdated }}
          </span>
        </v-row>

        <v-card>
          <v-card-title class="py-0">
            <v-row>
              <v-col>
                <v-row no-gutters>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      color="secondary"
                      @click="toggleMap()"
                    >
                      {{ isMapShown ? 'mdi-format-list-bulleted' : 'mdi-map-marker-radius' }}
                    </v-icon>
                  </div>
                  <div class="pr-3 align-self-end">
                    <v-icon
                      medium
                      :color="filtersIconColor"
                      @click="toggleFilters()"
                    >
                      mdi-filter
                    </v-icon>
                  </div>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    class
                    single-line
                    hide-details
                  />
                </v-row>
                <v-row>
                  <AircraftFilters
                    v-if="isFiltersOpened"
                    :prev-filters="prevFilters"
                    @toggle-filters="toggleFilters"
                    @on-filter="filterAircrafts"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <FlightsMap
            v-if="isMapShown"
            :aircrafts="aircrafts"
          />

          <v-data-table
            v-if="!isMapShown"
            :headers="headers"
            :items="aircrafts"
            :search="search"
            no-data-text="No flights yet"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, -1] }"
            sort-by="registration"
            :sort-desc="true"
          >
            <template #[`header.roleIcon`]="{}">
              <v-icon>
                mdi-truck-trailer
              </v-icon>
            </template>

            <template #[`item.registration`]="{ item }">
              <router-link :to="'/aircrafts/' + item.registration">
                {{ item.registration.toUpperCase() }}
              </router-link>
            </template>

            <template #[`item.roleIcon`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-if="item.roleIcon"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.roleIcon }}
                  </v-icon>
                </template>
                <div>
                  {{ item.roleIcon === 'mdi-truck-trailer' ? 'Cargo' : 'PAX' }}
                </div>
              </v-tooltip>
            </template>

            <template #[`item.aircraftType.name`]="{ item }">
              <router-link
                v-if="item.aircraftType"
                :to="'/aircraft-type/' + item.aircraftType.id"
              >
                {{ item.aircraftType.name }}
              </router-link>
            </template>

            <template #[`item.availability.value`]="{ item }">
              <RecentFlightChip
                v-if="item.availability"
                :aircraft="item"
                :flights="item.last_flights"
              />
            </template>

            <template #[`item.refreshId`]="{ item }">
              <v-tooltip
                v-if="item.registration !== refreshId"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="success"
                    dense
                    v-bind="attrs"
                    class="mr-1"
                    v-on="on"
                    @click="refreshAircraft(item.registration)"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <div>
                  Refresh aircraft
                </div>
              </v-tooltip>

              <v-progress-circular
                v-if="item.registration === refreshId"
                size="24"
                indeterminate
                color="success"
              />
            </template>
          </v-data-table>
        </v-card>

        <v-dialog
          v-model="isModalOpened"
          max-width="600px"
        >
          <AirlineEdit
            :on-close="closeModal"
            :on-submit="editAirline"
            :airline="airline"
          />
        </v-dialog>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
      absolute
    />
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { eventBus } from '../../main';
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';
  import AircraftsService from '../../services/AircraftsService';
  import AirlineEdit from './AirlineEdit';
  import AircraftFilters from '../../components/AircraftFilters';
  import RecentFlightChip from '../../components/RecentFlightChip';
  import FlightsMap from '../../components/FlightsMap';

  export default {
    components: {
      AirlineEdit,
      AircraftFilters,
      RecentFlightChip,
      FlightsMap
    },

    data: () => ({
      search: '',
      loading: true,
      refreshLoading: false,
      airline: {},
      aircrafts: [],
      isModalOpened: false,
      refreshId: '',
      isMapShown: false,
      isFiltersOpened: false,
      prevFilters: {}
    }),

    computed: {
      breadcrumbs: function () {
        return [
          {
            text: 'Home',
            append: true,
            exact: true,
            to: '/'
          },
          {
            text: 'Airlines',
            append: true,
            exact: true,
            to: '/airlines'
          },
          {
            text: `${this.airline.name.toUpperCase()}`,
            disabled: true
          }
        ];
      },

      lastUpdated () {
        const updatedAt = this.aircrafts.length ?
          this.aircrafts.reduce((a,b)=> a.updated_at > b.updated_at ? a : b).updated_at :
          DateTime.now().toISO();
        return DateTime.fromISO(updatedAt, { zone: 'UTC' }).toFormat('yyyy-MM-dd HH:mm:ss');
      },

      headers () {
        return [
          { text: 'Registration', value: 'registration', sortable: true },
          { text: '', value: 'roleIcon', sortable: true },
          { text: 'Model', value: 'aircraftType.name', sortable: true },
          { text: 'Type Code', value: 'aircraftType.icao', sortable: true },
          { text: 'IATA', value: 'airline.iata', sortable: true },
          { text: 'ICAO', value: 'airline.icao', sortable: true },
          { text: 'Availability', value: 'availability.value', sortable: true, align: 'center' },
          { text: '', value: 'refreshId', sortable: false }
        ];
      },

      filtersIconColor () {
        return this.isFiltersOpened ? 'primary' : 'secondary';
      }
    },

    async created () {
      const airlineId = this.$route.params.id;
      try {
        const { data } = await HTTPService.getAirline(airlineId);
        this.airline = data;
        await this.filterAircrafts(this.parseQueryParams(this.$route.query));
      } catch ({ response }) {
        ErrorService.handleError(response);
      } finally {
        this.loading = false;
      }
    },

    methods: {
      async refreshAirline () {
        this.refreshLoading = true;

        try {
          const { data } = await HTTPService.refreshAirline(this.airline.id);
          this.loading = true;
          const rawAircrafts = await HTTPService.getAircrafts({ airline_id: this.airline.id });
          this.aircrafts = AircraftsService.getAircraftsList(rawAircrafts.data);
          this.loading = false;
          if (data.length) {
            eventBus.$emit('notify', `${ data.length } aircraft found`);
          }
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.refreshLoading = false;
        }
      },

      openModal () {
        this.isModalOpened = true;
      },

      closeModal () {
        this.isModalOpened = false;
      },

      async editAirline (airline) {
        this.isModalOpened = false;
        this.loading = true;
        this.airline = { ...airline };

        try {
          const { data } = await HTTPService.getAircrafts({ airline_id: this.airline.id });
          this.aircrafts = AircraftsService.getAircraftsList(data);
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.loading = false;
        }
      },

      async refreshAircraft (registration) {
        this.refreshId = registration;

        try {
          const { data } = await HTTPService.refreshAircraft(registration);
          if (data) {
            this.aircrafts = AircraftsService.update(this.aircrafts, data);
          }
        } catch ({ response }) {
          ErrorService.handleError(response);
        } finally {
          this.refreshId = '';
        }
      },

      toggleFilters () {
        this.isFiltersOpened = !this.isFiltersOpened;
      },

      toggleMap () {
        this.isMapShown = !this.isMapShown;
      },

      async filterAircrafts (params) {
        try {
          const { data } = await HTTPService.getAircrafts({ airline_id: this.airline.id });
          const aircrafts = AircraftsService.getAircraftsList(data);
          if (!params || Object.values(params).every(value => value === null || value === undefined)) {
            this.aircrafts = [...aircrafts];
            this.$router.replace({ name: 'airline', params: { id: this.airline.id } }, () => {});
            return;
          }
          const filters = [
            AircraftsService.filterByType,
            AircraftsService.filterByAvailability
          ];
          this.aircrafts = filters.reduce((aircrafts, filterFn) => aircrafts.filter((el) => filterFn(el, params)), aircrafts);
          this.prevFilters = { ...params };
          this.loading = false;

          const query = {};
          Object.keys(params).forEach(key => {
            if (Number.isInteger(params[key]) || params[key]) {
              query[key] = params[key];
            }
          });
          this.$router.replace({ name: 'airline', params: { id: this.airline.id }, query }, () => {});
        } catch ({ response }) {
          ErrorService.handleError(response);
        }
      },

      parseQueryParams (query) {
        const params = {};

        Object.keys(query).forEach(key => {
          switch (key) {
          case 'is_regular':
            if (query[key] !== null) {
              params[key] = parseInt(query[key]);
            }
            break;
          default:
            if (query[key] === 'true') {
              params[key] = true;
            }
          }
        });

        return params;
      }
    }
  };
</script>

<style scoped>
  .v-data-table-header .v-icon {
    color: inherit;
  }
</style>
