const engine = {
  'TURBOPROP': 1,
  'JET': 2,
  'HELICOPTER': 3,
  'BALOON': 4,
};

const phoneType = [
  {
    id: 1,
    name: 'General',
    icon: 'mdi-phone',
  },
  {
    id: 2,
    name: 'Cargo',
    icon: 'mdi-truck-trailer',
  },
  {
    id: 3,
    name: 'PAX',
    icon: 'mdi-seat',
  },
];

const mailType = [
  {
    id: 1,
    name: 'General',
    icon: 'mdi-email',
  },
  {
    id: 2,
    name: 'Cargo',
    icon: 'mdi-truck-trailer',
  },
  {
    id: 3,
    name: 'PAX',
    icon: 'mdi-seat',
  },
];

const regions = [
  {
    id: 0,
    name: 'Africa',
    value: 'Africa'
  },
  {
    id: 1,
    name: 'America',
    value: 'America'
  },
  {
    id: 2,
    name: 'Asia',
    value: 'Asia'
  },
  {
    id: 3,
    name: 'Europe',
    value: 'Europe'
  },
  {
    id: 4,
    name: 'Middle East',
    value: 'Middle East'
  },
  {
    id: 5,
    name: 'Oceania',
    value: 'Oceania'
  },
  {
    id: 6,
    name: 'No region',
    value: null
  }
];

const flightType = [
  {
    id: 0,
    name: 'All',
    value: null
  },
  {
    id: 1,
    name: 'Charter',
    value: 0
  },
  {
    id: 2,
    name: 'Regular',
    value: 1
  }
];

const flightAvailability = {
  notAvailable: {
    status: 0,
    color: '#ff5252'
  },
  available: {
    status: 1,
    color: '#3ebc43'
  },
  aog: {
    status: 2,
    color: '#9e9e9e'
  },
  calculated: {
    status: 3,
    color: '#ff9800'
  },
  availableAtHomebase: {
    status: 4,
    color: '#ff5252',
  },
  availableSoon: {
    status: 5,
    color: '#ee7001',
  },
};

const legFlightType = [
  {
    id: 0,
    name: 'All',
    value: null
  },
  {
    id: 1,
    name: 'Commercial',
    value: 1
  },
  {
    id: 2,
    name: 'Technical',
    value: 2
  },
  {
    id: 3,
    name: 'Optional',
    value: 3
  },
  {
    id: 4,
    name: 'Cancelled',
    value: 4
  }
];

const getNameById = (id) => {
  return Object.keys(engine).find(key => engine[key] === id);
};

export {
  engine,
  phoneType,
  mailType,
  regions,
  flightType,
  flightAvailability,
  legFlightType,
  getNameById
};
