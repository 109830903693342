<template>
  <v-card-text>
    <v-row justify="space-around">
      <v-col
        sm="2"
        cols="4"
      >
        <v-checkbox
          v-model="model.status"
          :disabled="loading"
          :true-value="1"
          :false-value="null"
          label="Active"
        />
      </v-col>
      <v-col
        sm="3"
        cols="8"
      >
        <v-select
          v-model="model.engine"
          :items="enginesList"
          item-text="name"
          item-value="value"
          name="engine"
          menu-props="auto"
          label="Engine"
          :disabled="loading"
          :error-messages="validationErrors.engine"
          single-line
          @input="clearValidation('engine')"
        />
      </v-col>
      <v-col
        sm="2"
        cols="4"
      >
        <v-checkbox
          v-model="model.is_cargo"
          :disabled="loading"
          :true-value="1"
          :false-value="null"
          label="Cargo"
        />
      </v-col>
      <v-col
        sm="2"
        cols="4"
      >
        <v-checkbox
          v-model="model.is_pax"
          :disabled="loading"
          :true-value="1"
          :false-value="null"
          label="PAX"
        />
      </v-col>
      <v-col
        sm="2"
        cols="4"
      >
        <v-checkbox
          v-model="model.is_business"
          :disabled="loading"
          :true-value="1"
          :false-value="null"
          label="Business"
        />
      </v-col>
    </v-row>
    <v-row v-if="model.is_cargo">
      <v-col cols="3">
        <v-text-field
          v-if="model.is_cargo"
          v-model="model.payload_from"
          name="payload"
          label="Payload - from"
          type="number"
          :error-messages="validationErrors.payload_from"
          :disabled="loading"
          @input="clearValidation('payload_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_cargo"
          v-model="model.payload_to"
          name="payload"
          label="Payload - to"
          type="number"
          :error-messages="validationErrors.payload_to"
          :disabled="loading"
          @input="clearValidation('payload_to')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_cargo"
          v-model="model.volume_from"
          name="volume"
          label="Volume - from"
          type="number"
          :error-messages="validationErrors.volume_from"
          :disabled="loading"
          @input="clearValidation('volume_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_cargo"
          v-model="model.volume_to"
          name="volume"
          label="Volume - to"
          type="number"
          :error-messages="validationErrors.volume_to"
          :disabled="loading"
          @input="clearValidation('volume_to')"
        />
      </v-col>
    </v-row>
    <v-row v-if="model.is_pax || model.is_business">
      <v-col cols="6">
        <v-text-field
          v-if="model.is_pax || model.is_business"
          v-model="model.seat_from"
          name="seat"
          label="Seats capacity - from"
          type="number"
          :error-messages="validationErrors.seat_from"
          :disabled="loading"
          @input="clearValidation('seat_from')"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-if="model.is_pax || model.is_business"
          v-model="model.seat_to"
          name="seat"
          label="Seats capacity - to"
          type="number"
          :error-messages="validationErrors.seat_to"
          :disabled="loading"
          @input="clearValidation('seat_to')"
        />
      </v-col>
    </v-row>
    <v-row v-if="model.is_pax">
      <v-col cols="3">
        <v-text-field
          v-if="model.is_pax"
          v-model="model.luggage_payload_from"
          name="luggage_payload"
          label="Luggage Payload - from"
          type="number"
          :error-messages="validationErrors.luggage_payload_from"
          :disabled="loading"
          @input="clearValidation('luggage_payload_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_pax"
          v-model="model.luggage_payload_to"
          name="luggage_payload"
          label="Luggage Payload - to"
          type="number"
          :error-messages="validationErrors.luggage_payload_to"
          :disabled="loading"
          @input="clearValidation('luggage_payload_to')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_pax"
          v-model="model.luggage_volume_from"
          name="luggage_volume"
          label="Luggage Volume - from"
          type="number"
          :error-messages="validationErrors.luggage_volume_from"
          :disabled="loading"
          @input="clearValidation('luggage_volume_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="model.is_pax"
          v-model="model.luggage_volume_to"
          name="luggage_volume"
          label="Luggage Volume - to"
          type="number"
          :error-messages="validationErrors.luggage_volume_to"
          :disabled="loading"
          @input="clearValidation('luggage_volume_to')"
        />
      </v-col>
    </v-row>

    <v-row v-if="model.is_cargo">
      <v-col cols="3">
        <v-text-field
          v-model="model.door_height_from"
          name="door_height"
          label="Door height - from"
          :error-messages="validationErrors.door_height"
          :disabled="loading"
          @input="clearValidation('door_height_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="model.door_height_to"
          name="door_height"
          label="Door height - to"
          :error-messages="validationErrors.door_height"
          :disabled="loading"
          @input="clearValidation('door_height_to')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="model.door_width_from"
          name="door_width"
          label="Door width - from"
          :error-messages="validationErrors.door_width"
          :disabled="loading"
          @input="clearValidation('door_width_from')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="model.door_width_to"
          name="door_width"
          label="Door width - to"
          :error-messages="validationErrors.door_width"
          :disabled="loading"
          @input="clearValidation('door_width_to')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="model.runway_from"
          name="runway"
          label="Runway - from"
          type="number"
          :error-messages="validationErrors.runway_from"
          :disabled="loading"
          @input="clearValidation('runway_from')"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="model.runway_to"
          name="runway"
          label="Runway - to"
          type="number"
          :error-messages="validationErrors.runway_to"
          :disabled="loading"
          @input="clearValidation('runway_to')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-autocomplete
          v-model="model.aircraft_type_ids"
          :items="aircraftTypes"
          item-text="name"
          item-value="id"
          chips
          small-chips
          label="Aircraft types"
          multiple
        >
          <template #selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              small
              @click:close="removeTypeChip(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="model.regions"
          :items="regionsList"
          item-text="name"
          item-value="value"
          chips
          small-chips
          label="Regions"
          multiple
        >
          <template #selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              small
              @click:close="removeRegionChip(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="model.countryIds"
          :items="countries"
          item-text="name"
          item-value="id"
          chips
          small-chips
          label="Countries"
          multiple
          autocomplete="off"
        >
          <template #selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              small
              @click:close="removeCountryChip(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn
        type="button"
        color="primary"
        :disabled="loading"
        @click="applyFilters"
      >
        Apply
      </v-btn>
      <v-btn
        type="button"
        color="primary"
        text
        :disabled="loading"
        @click="onClose"
      >
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
  import ErrorService from '../services/ErrorService';
  import HTTPService from '../services/HTTPService';
  import { fieldValidation } from '../mixins/fieldValidation';
  import { engine, regions } from '../constants';

  export default {
    mixins: [fieldValidation],

    props: {
      onFilter: {
        type: Function
      },
      submit: {
        type: Function
      },
      prevFilters: {
        type: Object
      }
    },

    data: () => ({
      loading: false,
      model: {},
      aircraftTypes: [],
      countries: []
    }),

    computed: {
      enginesList: function () {
        let entries = Object.keys(engine).map((key) => {
          return { name: key, value: engine[key] };
        });
        entries = [{ name: 'ANY', value: null }, ...entries];
        return entries;
      },
      regionsList: function () {
        return regions;
      }
    },

    created: function () {
      let aircraftTypes = HTTPService.getAircraftTypes();
      let countries = HTTPService.getCountries();
      this.model = { ...this.prevFilters };

      Promise.all([aircraftTypes, countries])
        .then(res => {
          this.aircraftTypes = res[0].data;
          this.countries = res[1].data;
        })
        .catch(e => {
          ErrorService.handleException(e);
        });
    },

    methods: {
      onClose () {
        this.model = {};
        this.applyFilters();
        this.onFilter();
      },
      applyFilters () {
        this.submit(this.model);
      },
      removeTypeChip (item) {
        const index = this.model.aircraft_type_ids.indexOf(item.id);
        if (index >= 0) this.model.aircraft_type_ids.splice(index, 1);
      },
      removeRegionChip (item) {
        const index = this.model.regions.indexOf(item.value);
        if (index >= 0) this.model.regions.splice(index, 1);
      },
      removeCountryChip (item) {
        const index = this.model.countryIds.indexOf(item.id);
        if (index >= 0) this.model.countryIds.splice(index, 1);
      }
    }
  };
</script>
