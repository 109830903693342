<template>
  <v-card-text>
    <v-row class="d-flex flex-wrap">
      <v-col
        cols="12"
        sm="3"
        md="2"
        class="py-0"
      >
        <v-select
          v-model="model.is_regular"
          :items="flightTypeList"
          label="Type"
          item-value="value"
          menu-props="auto"
        >
          <template #selection="{item}">
            {{ item.name }}
          </template>
          <template #item="{item}">
            {{ item.name }}
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="2"
        class="py-0"
      >
        <v-checkbox
          v-model="model.available"
          :true-value="true"
          :false-value="null"
          label="Available"
        />
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="2"
        class="py-0"
      >
        <v-checkbox
          v-model="model.notAvailable"
          :true-value="true"
          :false-value="null"
          label="Not available"
        />
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="2"
        class="py-0"
      >
        <v-checkbox
          v-model="model.aog"
          :true-value="true"
          :false-value="null"
          label="AOG"
        />
      </v-col>
    </v-row>
    <v-row v-if="showPayloadFilters">
      <v-col>
        <v-row>
          <v-col>
            <h3>Payload</h3>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            cols="12"
            class="py-0"
          >
            <v-range-slider
              v-model="payloadRange"
              :max="maxPayload"
              :min="minPayload"
              :step="rangeStep"
              hide-details
              thumb-label
              thumb-size="48"
              class="align-center"
            >
              <template #prepend>
                <v-text-field
                  :value="payloadRange[0]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 80px"
                  @change="$set(payloadRange, 0, $event)"
                />
              </template>
              <template #append>
                <v-text-field
                  :value="payloadRange[1]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 80px"
                  @change="$set(payloadRange, 1, $event)"
                />
              </template>
            </v-range-slider>
          </v-col>
        </v-row>
        <v-row
          v-if="showAirportFilters"
          class="mb-2"
        >
          <v-col>
            <v-row no-gutters>
              <v-col>
                <h3>Estimate competitors charter</h3>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                cols="12"
                sm="6"
                class="py-0"
              >
                <AirportSearch
                  label="Departure airport"
                  :airport="model.departureAirport"
                  :error-message="validationErrors.departure_airport_id"
                  @clear-error="$emit('clearValidation', 'departure_airport_id')"
                  @on-submit="setDeparture"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="py-0"
              >
                <AirportSearch
                  label="Arrival airport"
                  :airport="model.arrivalAirport"
                  :error-message="validationErrors.arrival_airport_id"
                  @clear-error="$emit('clearValidation', 'arrival_airport_id')"
                  @on-submit="setArrival"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card-actions class="pa-0">
      <v-btn
        type="button"
        color="primary"
        @click="applyFilters"
      >
        Apply
      </v-btn>
      <v-btn
        type="button"
        color="primary"
        text
        @click="onClose"
      >
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
  import { flightType } from '../constants';
  import AirportSearch from './AirportSearch.vue';

  export default {
    components: { AirportSearch },

    props: {
      showPayloadFilters: {
        type: Boolean,
        default: false
      },
      showAirportFilters: {
        type: Boolean,
        default: false
      },
      prevFilters: {
        type: Object
      },
      validationErrors: {
        type: Object
      },
      resetFilters: {
        type: Function
      }
    },

    data: () => ({
      model: {},
      rangeStep: 100,
      minPayload: 0,
      maxPayload: 15000,
    }),

    computed: {
      flightTypeList () {
        return flightType;
      }
    },

    created () {
      this.model = { ...this.prevFilters };
      this.payloadRange = this.preparePayloadFilter(this.prevFilters);
    },

    methods: {
      setDeparture (airport) {
        this.model.departureAirport = airport;
        this.model.departure_airport_id = airport ? airport.id : null;
      },

      setArrival (airport) {
        this.model.arrivalAirport = airport;
        this.model.arrival_airport_id = airport ? airport.id : null;
      },

      preparePayloadFilter (prevFilters) {
        if (parseInt(prevFilters.payloadFrom) >= 0 && parseInt(prevFilters.payloadTo) >= 0) {
          return [prevFilters.payloadFrom, prevFilters.payloadTo];
        } else {
          return [this.minPayload, this.maxPayload];
        }
      },

      parsePayloadFilter (payload) {
        if (payload[0] === this.minPayload && payload[1] === this.minPayload) {
          return null;
        } else {
          return {
            payloadFrom: payload[0],
            payloadTo: payload[1]
          };
        }
      },

      onClose () {
        this.model = this.resetFilters ? this.resetFilters() : {};
        this.$emit('on-filter', this.model);
        this.$emit('toggle-filters');
      },

      applyFilters () {
        const filters = {
          ...this.model,
          ...this.parsePayloadFilter(this.payloadRange)
        };
        this.$emit('on-filter', filters);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .v-input--checkbox::v-deep {
    .v-label, .v-icon {
      word-break: break-word
    }
  }
</style>
