import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","px-3":"","py-3":""}},[(!_vm.loading)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":"-"}}),_c(VRow,{staticClass:"mx-3 my-3",attrs:{"justify":"space-between","align":"center"}},[_c('h1',{staticClass:"primary--text mx-0 my-0"},[_vm._v(" Aircraft Types ")]),_c(VBtn,{attrs:{"type":"button","color":"primary"},on:{"click":_vm.openAddModal}},[_vm._v(" Add type ")])],1),_c(VCard,[_c('AircraftTypesList',{attrs:{"types":_vm.types,"on-edit":_vm.openEditTypeModal,"on-delete":_vm.openDeleteModal,"filter-types":_vm.filterAircraftTypes,"prev-filters":_vm.prevFilters}}),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.isAddModalOpened),callback:function ($$v) {_vm.isAddModalOpened=$$v},expression:"isAddModalOpened"}},[_c('AircraftTypeEdit',{key:_vm.addDialogKey,attrs:{"on-close":_vm.closeAddModal,"on-submit":_vm.addType}})],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.isEditModalOpened),callback:function ($$v) {_vm.isEditModalOpened=$$v},expression:"isEditModalOpened"}},[_c('AircraftTypeEdit',{attrs:{"on-close":_vm.closeEditTypeModal,"on-submit":_vm.editType,"aircraft-type":_vm.typeToEdit}})],1),_c('DeleteDialog',{attrs:{"title":"aircraft type","name":_vm.typeToDelete.name,"loading":_vm.deleting,"on-submit":_vm.deleteType},model:{value:(_vm.isDeleteModalOpened),callback:function ($$v) {_vm.isDeleteModalOpened=$$v},expression:"isDeleteModalOpened"}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }