<template>
  <v-main>
    <v-container
      fluid
      fill-height
    >
      <v-row
        align-content="center"
        justify="center"
      >
        <v-col
          xs="12"
          sm="8"
          md="4"
        >
          <v-form @submit.prevent="doLogin">
            <v-card class="elevation-12">
              <v-toolbar
                dark
                color="primary"
              >
                <v-toolbar-title>AVEM AERO Track</v-toolbar-title>
              </v-toolbar>

              <v-card-text>
                <v-text-field
                  v-model="user.email"
                  prepend-icon="person"
                  name="login"
                  label="Login"
                  type="email"
                  :error-messages="validationErrors.email"
                  outlined
                  @input="clearValidation('email')"
                />

                <v-text-field
                  id="password"
                  v-model="user.password"
                  prepend-icon="lock"
                  name="password"
                  label="Password"
                  type="password"
                  :error-messages="validationErrors.password"
                  outlined
                  @input="clearValidation('password')"
                />
              </v-card-text>

              <v-card-actions>
                <v-btn
                  type="submit"
                  color="primary"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-footer app>
      <v-spacer />
      <a
        href="https://galsd.com/"
        target="_blank"
        class="font-weight-light caption mr-4"
      >
        galsd
      </a>
    </v-footer>
  </v-main>
</template>

<script>
  import { fieldValidation } from '../../mixins/fieldValidation';
  import ErrorService from '../../services/ErrorService';
  import HTTPService from '../../services/HTTPService';

  export default {
    name: 'LoginForm',

    mixins: [fieldValidation],

    data: () => ({
      user: {
        email: '',
        password: ''
      }
    }),

    methods: {
      async doLogin () {
        try {
          const { data } = await HTTPService.login({
            email: this.user.email,
            password: this.user.password
          });

          this.$store.dispatch('login', data);
        } catch ({ response }) {
          if (response.data.errors) {
            this.setValidation(ErrorService.handleError(response));
          } else {
            ErrorService.handleError();
          }
        }
      }
    }
  };
</script>
