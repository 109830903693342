import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","px-3":"","py-3":""}},[(!_vm.loading)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VRow,{staticClass:"mx-3 my-3",attrs:{"justify":"space-between","align":"center"}},[_c('h1',{staticClass:"primary--text mx-0 my-0"},[_vm._v(" Rules ")]),_c(VSpacer),_c(VBtn,{attrs:{"type":"button","color":"primary"}},[_vm._v(" Add Rule ")])],1),_c(VCard,[_c(VCardTitle,[_c(VTextField,{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"search":_vm.search,"headers":_vm.headers,"no-data-text":"No aircrafts yet","items-per-page":50,"footer-props":{ itemsPerPageOptions: [50, 100, 150, -1] }}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }