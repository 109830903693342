import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c(VCardText,[_c(VRow,{staticClass:"py-0 d-flex align-end"},[_c(VCol,{attrs:{"cols":"2"}},[_c(VSelect,{attrs:{"items":_vm.flightTypeList,"label":"Type of flights","item-value":"value","menu-props":"auto"},scopedSlots:_vm._u([{key:"selection",fn:function({item}){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function({item}){return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,1532367900),model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"items":_vm.airlines,"item-text":"name","item-value":"id","label":"Airline","chips":"","small-chips":"","deletable-chips":"","multiple":""},model:{value:(_vm.model.airline_ids),callback:function ($$v) {_vm.$set(_vm.model, "airline_ids", $$v)},expression:"model.airline_ids"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"items":_vm.aircraftTypes,"item-text":"name","item-value":"id","label":"Aircraft types","chips":"","small-chips":"","deletable-chips":"","multiple":""},model:{value:(_vm.model.aircraft_type_ids),callback:function ($$v) {_vm.$set(_vm.model, "aircraft_type_ids", $$v)},expression:"model.aircraft_type_ids"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VCombobox,{attrs:{"label":"Registrations","clearable":"","multiple":"","small-chips":"","deletable-chips":""},model:{value:(_vm.model.registrations),callback:function ($$v) {_vm.$set(_vm.model, "registrations", $$v)},expression:"model.registrations"}})],1)],1),_c(VCardActions,{staticClass:"pa-0"},[_c(VBtn,{attrs:{"type":"button","color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" Apply ")]),_c(VBtn,{attrs:{"type":"button","color":"primary","text":""},on:{"click":_vm.onClose}},[_vm._v(" Cancel ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }