import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","px-3":"","py-3":""}},[(!_vm.loading)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VRow,{staticClass:"mx-3 my-3",attrs:{"justify":"space-between","align":"center"}},[_c('h1',{staticClass:"primary--text mx-0 my-0"},[_vm._v(" Flights ")]),_c(VSpacer),_c('VueCtkDateTimePicker',{attrs:{"format":"YYYY-MM-DD","formatted":"YYYY-MM-DD","range":true,"no-clear-button":true,"only-date":true},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c(VRow,{attrs:{"no-gutters":""}},[(!_vm.loading)?_c('FlightsList',{attrs:{"flights":_vm.flights,"on-edit":_vm.openEditModal,"filter-flights":_vm.filterFlights,"prev-filters":_vm.prevFilters,"reset-filters":_vm.resetFilters},on:{"on-type-change":_vm.onTypeChange}}):_vm._e(),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.isEditModalOpened),callback:function ($$v) {_vm.isEditModalOpened=$$v},expression:"isEditModalOpened"}},[(_vm.isEditModalOpened)?_c('FlightEdit',{attrs:{"flight":_vm.flightToEdit},on:{"on-close":_vm.closeEditModal,"on-submit":_vm.editFlight}}):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":true,"absolute":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }