<template>
  <v-snackbar
    :top="true"
    :value="message"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
  import { eventBus } from '../main';

  export default {
    data: () => ({
      message: ''
    }),

    created () {
      eventBus.$on('notify', message => {
        this.message = message;

        setTimeout(() => {
          this.message = '';
        }, 2000);
      });
    }
  };
</script>
