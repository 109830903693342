<template>
  <v-app>
    <ErrorHandler />
    <NotificationHandler />
    <router-view />
  </v-app>
</template>

<script>
  import ErrorHandler from './components/ErrorHandler';
  import NotificationHandler from './components/NotificationHandler';

  export default {
    name: 'App',
    components: {
      ErrorHandler,
      NotificationHandler
    }
  };
</script>

<style>
  h1 {
    margin: 30px 0;
    text-align: center;
  }

  td {
    text-align: left;
  }

  td.id {
    width: 130px;
  }

  .loader-container {
    height: 10px;
    width: 100%;
  }

  .loader {
    margin: 0;
  }

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
